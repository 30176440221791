import React, { Component } from "react";
import TextField from "../../../common/TextField";
import { withStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Button from "../../../common/Button";
import "./index.scss";
import { SketchPicker } from "react-color";
import { updateAccount } from "../../../../Actions/Accounts";
import { checkPrivileges } from "../../../../Helpers";
import {
  notifyError,
  notifySuccess,
} from "../../../../Utils/CustomNotifcations";
import withTranslationWrapper from "../../../../HOC/HocTranslate";

export const fonts = {
  "ff-1": "Poppins",
  "ff-2": "PT Sans",
  "ff-3": "Raleway",
  "ff-4": "Roboto",
  "ff-5": "Nunito",
};
// userStatus: [
//   { id: '1', label: 'Online' },
//   { id: '2', label: 'Offline' },
//   { id: '3', label: 'Sleep' }
// ],

const fontIds = Object.keys(fonts);
const fontFamilyArr = [];
fontIds.map((k) => fontFamilyArr.push({ value: k, label: fonts[k] }));
export const fontFamilies = fontFamilyArr;

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  button: {
    margin: theme.spacing(1),
  },
});

class addAccountModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      attm_isVisable: false,
      customColor: false,
      fontValue: this.props.selectedAccount?.attributes?.fontFamily || "",
      themeValue: this.props.selectedAccount?.attributes?.themeId || "",
      clicked: false,
      currentTheme: {
        themeDarkColor: "",
        themeInverse: "",
        themeLightColor: "",
        themeLightInverse: "",
        themeListingColor: "",
        themeListingInverse: "",
        dashboardBackgroundColor: "",
        backgroundColor: "",
        textColor: "",
        menuActiveColor: "",
        menuActiveBackground: "",
        menuBackgroundColor: "",
        menuTextColor: "",
      },
      isEnable: false,
      themes: [
        {
          id: 1,
          name: "Theme 1",
          themeDarkColor: "#161f35",
          themeInverse: "#ffffff",
          themeLightColor: "#00cbd7",
          themeLightInverse: "#222222",
          themeListingColor: "#384360",
          themeListingInverse: "#ffffff",
          dashboardBackgroundColor: "#0a83a1",
          backgroundColor: "#161f35",
          textColor: "#ffffff",
          menuActiveColor: "222222",
          menuActiveBackground: "#00cbd7",
          menuBackgroundColor: "#161f35",
          menuTextColor: "#ffffff",
          InputBorderColor: "#222C3C88",
          themeListingActiveInverse: "#ffffff",
          themeListingActiveColor: "#409CF7",
          tableBorderColor: "#D4D4D6",
          tabBgColor: "#ffffff",
          tabTextColor: "#204B70",
          error: "#ff424c",
          success: "#4BB543",
        },
        {
          id: 2,
          name: "Theme 2",
          themeDarkColor: "#045266",
          themeInverse: "#ffffff",
          themeLightColor: "#0a83a1",
          themeLightInverse: "#ffffff",
          themeListingColor: "#0a83a1",
          themeListingInverse: "#ffffff",
          backgroundColor: "#045266",
          dashboardBackgroundColor: "#0a83a1",
          textColor: "#ffffff",
          menuActiveColor: "#ffffff",
          menuActiveBackground: "#0a83a1",
          menuBackgroundColor: "#045266",
          menuTextColor: "#ffffff",
          InputBorderColor: "#222C3C88",
          themeListingActiveInverse: "#ffffff",
          themeListingActiveColor: "#409CF7",
          tableBorderColor: "#D4D4D6",
          tabBgColor: "#ffffff",
          tabTextColor: "#204B70",
          error: "#ff424c",
          success: "#4BB543",
        },
        {
          id: 3,
          name: "Theme 3",
          themeDarkColor: "#ee4b49",
          themeInverse: "#ffffff",
          themeLightColor: "#ed5650",
          themeLightInverse: "#ffffff",
          themeListingColor: "#171c4f",
          themeListingInverse: "#ffffff",
          backgroundColor: "#26295e",
          dashboardBackgroundColor: "#0a83a1",
          textColor: "#ffffff",
          menuActiveColor: "#ffffff",
          menuActiveBackground: "#ed5650",
          menuBackgroundColor: "#26295e",
          menuTextColor: "#ffffff",
          InputBorderColor: "#222C3C88",
          themeListingActiveInverse: "#ffffff",
          themeListingActiveColor: "#409CF7",
          tableBorderColor: "#D4D4D6",
          tabBgColor: "#ffffff",
          tabTextColor: "#204B70",
          error: "#ff424c",
          success: "#4BB543",
        },
        {
          id: 4,
          name: "Theme 4",
          themeDarkColor: "#ee4b49",
          themeInverse: "#ffffff",
          themeLightColor: "#f7625e",
          themeLightInverse: "#ffffff",
          themeListingColor: "#ebecf1",
          themeListingInverse: "#000000",
          backgroundColor: "#ffffff",
          dashboardBackgroundColor: "#0a83a1",
          textColor: "#000000",
          menuActiveColor: "#ffffff",
          menuActiveBackground: "#f7625e",
          menuBackgroundColor: "#ffffff",
          menuTextColor: "#000000",
          InputBorderColor: "#222C3C88",
          themeListingActiveInverse: "#ffffff",
          themeListingActiveColor: "#409CF7",
          tableBorderColor: "#D4D4D6",
          tabBgColor: "#ffffff",
          tabTextColor: "#204B70",
          error: "#ff424c",
          success: "#4BB543",
        },
        {
          id: 5,
          name: "Theme 5",
          themeDarkColor: "#5c45db",
          themeInverse: "#ffffff",
          themeLightColor: "#6e59e2",
          themeLightInverse: "#ffffff",
          themeListingColor: "#efeffb",
          themeListingInverse: "#000000",
          backgroundColor: "#ffffff",
          dashboardBackgroundColor: "#0a83a1",
          textColor: "#000000",
          menuActiveColor: "#ffffff",
          menuActiveBackground: "#6e59e2",
          menuBackgroundColor: "#ffffff",
          menuTextColor: "#000000",
          InputBorderColor: "#222C3C88",
          themeListingActiveInverse: "#ffffff",
          themeListingActiveColor: "#409CF7",
          tableBorderColor: "#D4D4D6",
          tabBgColor: "#ffffff",
          tabTextColor: "#204B70",
          error: "#ff424c",
          success: "#4BB543",
        },
        {
          id: 6,
          name: "Theme 6",
          themeDarkColor: "#5c45db",
          themeInverse: "#ffffff",
          themeLightColor: "#6e59e2",
          themeLightInverse: "#ffffff",
          themeListingColor: "#2f2f2f",
          themeListingInverse: "#ffffff",
          backgroundColor: "#111111",
          dashboardBackgroundColor: "#0a83a1",
          textColor: "#ffffff",
          menuActiveColor: "#ffffff",
          menuActiveBackground: "#6e59e2",
          menuBackgroundColor: "#111111",
          menuTextColor: "#ffffff",
          InputBorderColor: "#222C3C88",
          themeListingActiveInverse: "#ffffff",
          themeListingActiveColor: "#409CF7",
          tableBorderColor: "#D4D4D6",
          tabBgColor: "#ffffff",
          tabTextColor: "#204B70",
          error: "#ff424c",
          success: "#4BB543",
        },
        {
          id: 7,
          name: "Theme 7",
          themeDarkColor: "#045266",
          themeInverse: "#ffffff",
          themeLightColor: "#0a83a1",
          themeLightInverse: "#ffffff",
          themeListingColor: "#0a83a1",
          themeListingInverse: "#ffffff",
          backgroundColor: "#ffffff",
          dashboardBackgroundColor: "#0a83a1",
          textColor: "#222222",
          menuActiveColor: "#ffffff",
          menuActiveBackground: "#0a83a1",
          menuBackgroundColor: "#ffffff",
          menuTextColor: "#222222",
          InputBorderColor: "#222C3C88",
          themeListingActiveInverse: "#ffffff",
          themeListingActiveColor: "#409CF7",
          tableBorderColor: "#D4D4D6",
          tabBgColor: "#ffffff",
          tabTextColor: "#204B70",
          error: "#ff424c",
          success: "#4BB543",
        },
        {
          id: 8,
          name: "Theme 8",
          themeDarkColor: "#007549",
          themeInverse: "#ffffff",
          themeLightColor: "#00c179",
          themeLightInverse: "#ffffff",
          themeListingColor: "#00c179",
          themeListingInverse: "#ffffff",
          backgroundColor: "#ffffff",
          dashboardBackgroundColor: "#0a83a1",
          textColor: "#222222",
          menuActiveColor: "#ffffff",
          menuActiveBackground: "#00c179",
          menuBackgroundColor: "#ffffff",
          menuTextColor: "#222222",
          InputBorderColor: "#222C3C88",
          themeListingActiveInverse: "#ffffff",
          themeListingActiveColor: "#409CF7",
          tableBorderColor: "#D4D4D6",
          tabBgColor: "#ffffff",
          tabTextColor: "#204B70",
          error: "#ff424c",
          success: "#4BB543",
        },
        {
          id: 9,
          name: "Theme 9",
          themeDarkColor: "#2a5fc1",
          themeInverse: "#ffffff",
          themeLightColor: "#377dff",
          themeLightInverse: "#ffffff",
          themeListingColor: "#377dff",
          themeListingInverse: "#ffffff",
          backgroundColor: "#ffffff",
          dashboardBackgroundColor: "#0a83a1",
          textColor: "#222222",
          menuActiveColor: "#ffffff",
          menuActiveBackground: "#377dff",
          menuBackgroundColor: "#ffffff",
          menuTextColor: "#222222",
          InputBorderColor: "#222C3C88",
          themeListingActiveInverse: "#ffffff",
          themeListingActiveColor: "#409CF7",
          tableBorderColor: "#D4D4D6",
          tabBgColor: "#ffffff",
          tabTextColor: "#204B70",
          error: "#ff424c",
          success: "#4BB543",
        },
        {
          id: 10,
          name: "Theme 10",
          themeDarkColor: "#d2170e",
          themeInverse: "#ffffff",
          themeLightColor: "#ff1d13",
          themeLightInverse: "#ffffff",
          themeListingColor: "#ff1d13",
          themeListingInverse: "#ffffff",
          backgroundColor: "#ffffff",
          dashboardBackgroundColor: "#0a83a1",
          textColor: "#222222",
          menuActiveColor: "#ffffff",
          menuActiveBackground: "#ff1d13",
          menuBackgroundColor: "#ffffff",
          menuTextColor: "#222222",
          InputBorderColor: "#222C3C88",
          themeListingActiveInverse: "#ffffff",
          themeListingActiveColor: "#409CF7",
          tableBorderColor: "#D4D4D6",
          tabBgColor: "#ffffff",
          tabTextColor: "#204B70",
          error: "#ff424c",
          success: "#4BB543",
        },
        {
          id: 11,
          name: "Theme 11",
          themeDarkColor: "#00bac6",
          themeInverse: "#000000",
          themeLightColor: "#00c0ce",
          themeLightInverse: "#000000",
          themeListingColor: "#e9ebf0",
          themeListingInverse: "#000000",
          backgroundColor: "#ffffff",
          dashboardBackgroundColor: "#0a83a1",
          textColor: "#000000",
          menuActiveColor: "000000",
          menuActiveBackground: "#00c0ce",
          menuBackgroundColor: "#ffffff",
          menuTextColor: "#000000",
          InputBorderColor: "#222C3C88",
          themeListingActiveInverse: "#ffffff",
          themeListingActiveColor: "#409CF7",
          tableBorderColor: "#D4D4D6",
          tabBgColor: "#ffffff",
          tabTextColor: "#204B70",
          error: "#ff424c",
          success: "#4BB543",
        },
        {
          id: "lite_1",
          name: "Theme lite_1",
          themeDarkColor: "#9597B2",
          themeInverse: "#ffffff",
          themeLightColor: "#409CF7",
          themeLightInverse: "#ffffff",
          themeListingColor: "#ffffff",
          themeListingInverse: "#242B46",
          themeListingActiveColor: "#409CF7",
          themeListingActiveInverse: "#ffffff",
          backgroundColor: "#F6F6F6",
          textColor: "#204B70",
          dashboardBackgroundColor: "transparent",
          tableBorderColor: "#D4D4D6",
          InputBorderColor: "#222C3C88",
          menuActiveColor: "#ffffff",
          menuActiveBackground: "#409CF7",
          menuBackgroundColor: "#F6F6F6",
          menuTextColor: "#204B70",
          tabBgColor: "#ffffff",
          tabTextColor: "#204B70",
          error: "#ff424c",
          success: "#4BB543",
        },
        {
          id: "lite_2",
          name: "Theme lite_2",
          themeDarkColor: "#9597B2",
          themeInverse: "#ffffff",
          themeLightColor: "#409CF7",
          themeLightInverse: "#ffffff",
          themeListingColor: "#1A374D",
          themeListingInverse: "#ffffff",
          themeListingActiveColor: "#2F455C",
          themeListingActiveInverse: "#ffffff",
          backgroundColor: "#2F455C",
          textColor: "#ffffff",
          dashboardBackgroundColor: "transparent",
          tableBorderColor: "#D4D4D6",
          InputBorderColor: "#222C3C88",
          menuActiveColor: "#ffffff",
          menuActiveBackground: "#409CF7",
          menuBackgroundColor: "#1A374D",
          menuTextColor: "#ffffff",
          tabBgColor: "#2F455C",
          tabTextColor: "#ffffff",
          error: "#ff424c",
          success: "#4BB543",
        },
      ],
      fontFamily:
        (props.selectedAccount &&
          props.selectedAccount.attributes &&
          props.selectedAccount.attributes.fontFamily) ||
        "ff-4",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillMount() {
    this.currentThemeValue();
  }

  componentWillReceiveProps(n) {
    // console.log('wiil receive =')
  }
  UpdateSelectedItem = (item) => {
    this.setState({
      selectedAccount: item,
    });
  };
  themeChange(themeValue, fontValue) {
    const obj = {
      id: this.props.selectedAccount && this.props.selectedAccount.id,
      parent: this.props.selectedAccount && this.props.selectedAccount.parent,
      name: this.props.selectedAccount && this.props.selectedAccount.name,
      description:
        this.props.selectedAccount && this.props.selectedAccount.description,
      email: this.props.selectedAccount && this.props.selectedAccount.email,
      contact: this.props.selectedAccount && this.props.selectedAccount.contact,
      attributes: {
        ...(this.props.selectedAccount &&
          this.props.selectedAccount.attributes),
        themeId: themeValue,
        fontFamily: fontValue,
        customTheme: null,
      },
    };
    if (themeValue === "custom") {
      obj.attributes.customTheme =
        this.props.selectedAccount &&
        this.props.selectedAccount.attributes &&
        this.props.selectedAccount.attributes.customTheme
          ? {
              ...this.props.selectedAccount.attributes.customTheme,
            }
          : this.state.currentTheme;
    }
    this.setState({ isEnable: false }, () => {
      fetch(
        `/api/accounts/${
          this.props.selectedAccount && this.props.selectedAccount.id
        }`,
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...obj,
          }),
        }
      )
        .then((response) => {
          response.json().then((res) => {
            if (res.status === "success") {
              let account = res.data;
              this.props.UpdateSelectedItem(account);
              this.props.dispatch(updateAccount(account));
              this.props.dispatch(
                notifySuccess(this.props.translate("themeUpdated"))
              );
              // this.setState({isEnable:false})
            } else if (res?.statusCode === "440") {
              window.location.replace("/login");
            } else if (res.statusCode) {
              var err = res?.message.split(":");
              err[1] = err[1].replace(")", "");
              this.props.dispatch(notifyError(this.props.translate(err[1])));
            } else {
              throw response;
            }

            this.currentThemeValue();
            // this.setState({
            //   currentTheme: {
            //     themeDarkColor: '#5c45db',
            //     themeInverse: '#ffffff',
            //     themeLightColor: '#6e59e2',
            //     themeLightInverse: '#ffffff',
            //     themeListingColor: '#efeffb',
            //     themeListingInverse: '#000000',
            //     backgroundColor: '#ffffff',
            //     textColor: '#000000'
            //   }
            // })
          });
        })
        .catch((e) => {
          this.props.dispatch(
            notifyError(this.props.translate("somethingWentWrong"))
          );
        });
    });
  }

  applyTheme = () => {
    var obj = {
      id: this.props.selectedAccount && this.props.selectedAccount.id,
      parent: this.props.selectedAccount && this.props.selectedAccount.parent,
      name: this.props.selectedAccount && this.props.selectedAccount.name,
      description:
        this.props.selectedAccount && this.props.selectedAccount.description,
      email: this.props.selectedAccount && this.props.selectedAccount.email,
      contact: this.props.selectedAccount && this.props.selectedAccount.contact,
      attributes: {
        ...(this.props.selectedAccount &&
          this.props.selectedAccount.attributes),
        themeId: this.state.themeValue,
        fontFamily: this.state.fontValue,
        customTheme: {
          themeDarkColor: this.state.currentTheme.themeDarkColor,
          themeInverse: this.state.currentTheme.themeInverse,
          themeLightColor: this.state.currentTheme.themeLightColor,
          themeLightInverse: this.state.currentTheme.themeLightInverse,
          themeListingColor: this.state.currentTheme.themeListingColor,
          themeListingInverse: this.state.currentTheme.themeListingInverse,
          backgroundColor: this.state.currentTheme.backgroundColor,
          dashboardBackgroundColor:
            this.state.currentTheme.dashboardBackgroundColor ||
            this.state.currentTheme.themeLightColor,
          textColor: this.state.currentTheme.textColor,
          menuActiveColor: this.state.currentTheme.menuActiveColor,
          menuActiveBackground: this.state.currentTheme.menuActiveBackground,
          menuBackgroundColor: this.state.currentTheme.menuBackgroundColor,
          menuTextColor: this.state.currentTheme.menuTextColor,
          InputBorderColor: "#222C3C88",
          themeListingActiveInverse: "#ffffff",
          themeListingActiveColor: "#409CF7",
          tableBorderColor: "#D4D4D6",
          tabBgColor: "#ffffff",
          tabTextColor: "#204B70",
          error: "#ff424c",
          success: "#4BB543",
        },
      },
    };
    fetch(
      `/api/accounts/${
        this.props.selectedAccount && this.props.selectedAccount.id
      }`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...obj,
        }),
      }
    )
      .then((response) => {
        if (response.ok) {
          response.json().then((res) => {
            if (res.status === "success") {
              let account = res.data;
              this.props.UpdateSelectedItem(account);
              this.props.dispatch(updateAccount(account));
              this.props.dispatch(
                notifySuccess(this.props.translate("themeUpdated"))
              );
              this.setState({
                currentTheme: account.attributes.customTheme,
              });
            } else if (res?.statusCode === "440") {
              window.location.replace("/login");
            } else if (res.statusCode) {
              var err = res?.message.split(":");
              err[1] = err[1].replace(")", "");
              this.props.dispatch(notifyError(this.props.translate(err[1])));
            } else {
              throw response;
            }
          });
        }
      })
      .catch((e) => {
        this.props.dispatch(
          notifyError(this.props.translate("somethingWentWrong"))
        );
      });
  };
  selectFamily = (event) => {
    let fontFamily = event.target.value;
    this.setState({ fontFamily: event.target.value }, () => {
      var obj = {
        id: this.props.selectedAccount && this.props.selectedAccount.id,
        parent: this.props.selectedAccount && this.props.selectedAccount.parent,
        name: this.props.selectedAccount && this.props.selectedAccount.name,
        description:
          this.props.selectedAccount && this.props.selectedAccount.description,
        email: this.props.selectedAccount && this.props.selectedAccount.email,
        contact:
          this.props.selectedAccount && this.props.selectedAccount.contact,
        attributes: {
          ...(this.props.selectedAccount &&
            this.props.selectedAccount.attributes),
          fontFamily: fontFamily,
        },
      };
      fetch(
        `/api/accounts/${
          this.props.selectedAccount && this.props.selectedAccount.id
        }`,
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...obj,
          }),
        }
      )
        .then((response) => {
          response.json().then((res) => {
            if (res.status === "success") {
              this.props.dispatch(
                notifySuccess(this.props.translate("themeUpdated"))
              );
            } else if (res?.statusCode === "440") {
              window.location.replace("/login");
            } else if (res.statusCode) {
              var err = res?.message.split(":");
              err[1] = err[1].replace(")", "");
              this.props.dispatch(notifyError(this.props.translate(err[1])));
            } else {
              throw response;
            }
          });
        })
        .catch((e) => {
          console.log(e);
          this.props.dispatch(
            notifyError(this.props.translate("somethingWentWrong"))
          );
        });
    });
  };
  handleChange = (name, e) => {
    if (name === "theme") {
      if (e.target.value === "custom") {
        this.setState(
          { themeValue: e.target.value, customColor: true, open: false },
          () => {
            this.currentThemeValue();
            this.setState({ isEnable: true });
          }
        );
      } else {
        this.setState(
          {
            themeValue: e.target.value,
            open: false,
          },
          () => {
            this.currentThemeValue();
            this.setState({ isEnable: true });
          }
        );
      }
    } else {
      this.setState(
        {
          fontValue: e.target.value,
          open: false,
        },
        () => {
          this.setState({ isEnable: true });
        }
      );
    }
  };
  handleSubmit = (e) => {
    e.preventDefault();
    this.themeChange(this.state.themeValue, this.state.fontValue);
  };
  openPicker = (open) => {
    if (this.state.themeValue === "custom") this.setState({ open });
  };
  hideColorPicker = (open) => {
    this.setState({ open: false });
  };
  onColorUpdate = (key, event) => {
    this.setState({
      currentTheme: { ...this.state.currentTheme, [key]: event.hex },
      // open: ''
    });
  };
  currentThemeValue = () => {
    this.state.themes.map((item) => {
      if (
        item.id == this.state.themeValue &&
        this.state.themeValue !== "custom"
      ) {
        this.setState({
          currentTheme: {
            ...this.state.currentTheme,
            ...item,
          },
        });
      } else if (this.state.themeValue === "custom") {
        {
          if (
            this.props.selectedAccount &&
            this.props.selectedAccount.attributes &&
            this.props.selectedAccount.attributes.customTheme
          ) {
            this.props.selectedAccount.attributes &&
              this.props.selectedAccount.attributes.customTheme &&
              this.setState({
                currentTheme:
                  this.props.selectedAccount &&
                  this.props.selectedAccount.attributes &&
                  this.props.selectedAccount.attributes.customTheme,
              });
          } else {
            this.setState({
              currentTheme: {
                themeDarkColor: "#5c45db",
                themeInverse: "#ffffff",
                themeLightColor: "#6e59e2",
                themeLightInverse: "#ffffff",
                themeListingColor: "#efeffb",
                themeListingInverse: "#000000",
                backgroundColor: "#ffffff",
                dashboardBackgroundColor: "#6e59e2",
                textColor: "#000000",
                menuActiveColor: "#ffffff",
                menuActiveBackground: "#5c45db",
                menuBackgroundColor: "#6e59e2",
                menuTextColor: "#ffffff",
                InputBorderColor: "#222C3C88",
                themeListingActiveInverse: "#ffffff",
                themeListingActiveColor: "#409CF7",
                tableBorderColor: "#D4D4D6",
                tabBgColor: "#ffffff",
                tabTextColor: "#204B70",
                error: "#ff424c",
                success: "#4BB543",
              },
            });
          }
        }
      }
    });
  };
  render() {
    const { classes } = this.props;
    let userTypeArray = [];
    if (this.props.userType) {
      let { userType } = this.props;
    }
    const Content = (
      <>
        <Grid container spacing={2} onClick={this.hideColorPicker}>
          <Grid item md={3} sm={6} xs={12}>
            <h3 style={{ margin: "5px", marginBottom: "15px" }}>
              {this.props.translate("themeSettings")}
            </h3>
          </Grid>
        </Grid>
        <form onSubmit={this.handleSubmit}>
          <Grid container spacing={2} onClick={this.hideColorPicker}>
            <Grid item md={3} sm={6} xs={12}>
              <TextField
                id="changeTheme"
                select
                label={this.props.translate("changeTheme")}
                value={this.state.themeValue || 2}
                onChange={(e) => this.handleChange("theme", e)}
                margin="dense"
                fullWidth
                InputProps={{
                  readOnly: checkPrivileges("userUpdate") ? false : true,
                }}
              >
                <MenuItem value="1">Theme 1</MenuItem>
                <MenuItem value="2">Theme 2 (Default Theme)</MenuItem>
                <MenuItem value="3">Theme 3</MenuItem>
                <MenuItem value="4">Theme 4</MenuItem>
                <MenuItem value="5">Theme 5</MenuItem>
                <MenuItem value="6">Theme 6</MenuItem>
                <MenuItem value="7">Theme 7</MenuItem>
                <MenuItem value="8">Theme 8</MenuItem>
                <MenuItem value="9">Theme 9</MenuItem>
                <MenuItem value="10">Theme 10</MenuItem>
                <MenuItem value="11">Theme 11</MenuItem>
                <MenuItem value={"lite_1"}>
                  Light Theme (For lite version)
                </MenuItem>
                <MenuItem value={"lite_2"}>
                  Dark Theme (For lite version)
                </MenuItem>
                <MenuItem value="custom">Custom Theme</MenuItem>
              </TextField>
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <TextField
                id="defaultTheme"
                select
                label={this.props.translate("fontFamily")}
                value={this.state.fontValue || "ff-2"}
                onChange={(e) => this.handleChange("font", e)}
                margin="dense"
                fullWidth
                InputProps={{
                  readOnly: checkPrivileges("userUpdate") ? false : true,
                }}
              >
                <MenuItem value="ff-1">Poppins</MenuItem>
                <MenuItem value="ff-2">PT Sans</MenuItem>
                <MenuItem value="ff-3">Raleway</MenuItem>
                <MenuItem value="ff-4">Roboto</MenuItem>
                <MenuItem value="ff-5">Nunito</MenuItem>
              </TextField>
            </Grid>
            {/* )} */}
          </Grid>

          {/* {this.state.themeValue && this.state.themeValue === 'custom' ? ( */}
          {this.state.themeValue ? (
            <>
              {" "}
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <h4>{this.props.translate("primaryColor")}</h4>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      {this.props.translate("backgroundColor")} {"  "}
                      <span
                        style={{
                          display: "inline-block",
                          position: "relative",
                        }}
                      >
                        <a
                          href={null}
                          onClick={(e) => {
                            this.openPicker("themeDarkColor");
                          }}
                          style={{
                            color: this.state.currentTheme.themeDarkColor,
                            background: this.state.currentTheme.themeDarkColor,
                            display: "inline-block",
                            width: 40,
                            height: 40,
                            border: "solid 1px rgba(0,0,0,0.5)",
                            verticalAlign: "middle",
                          }}
                        ></a>
                        {this.state.open === "themeDarkColor" ? (
                          <div
                            onMouseLeave={this.hideColorPicker}
                            style={{
                              position: "absolute",
                              top: 0,
                              zIndex: 3,
                              left: "-50%",
                            }}
                          >
                            <SketchPicker
                              // color={this.state[this.state.open]}
                              color={this.state.currentTheme.themeDarkColor}
                              onChangeComplete={(e) =>
                                this.onColorUpdate(this.state.open, e)
                              }
                            ></SketchPicker>
                          </div>
                        ) : null}
                      </span>
                    </Grid>
                    <Grid item xs={6}>
                      {this.props.translate("textColor")}
                      {"  "}
                      <span
                        style={{
                          display: "inline-block",
                          position: "relative",
                        }}
                      >
                        <a
                          href={null}
                          onClick={(e) => this.openPicker("themeInverse")}
                          style={{
                            color: this.state.currentTheme.themeInverse,
                            background: this.state.currentTheme.themeInverse,
                            display: "inline-block",
                            width: 40,
                            height: 40,
                            border: "solid 1px rgba(0,0,0,0.5)",
                            verticalAlign: "middle",
                          }}
                        ></a>
                        {this.state.open === "themeInverse" ? (
                          <div
                            onMouseLeave={this.hideColorPicker}
                            style={{
                              position: "absolute",
                              top: 0,
                              zIndex: 3,
                              left: "-50%",
                            }}
                          >
                            <SketchPicker
                              color={this.state.currentTheme.themeInverse}
                              onChangeComplete={(e) =>
                                this.onColorUpdate(this.state.open, e)
                              }
                            ></SketchPicker>
                          </div>
                        ) : null}
                      </span>
                    </Grid>
                  </Grid>
                  <h4>{this.props.translate("secondaryColor")}</h4>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      {this.props.translate("backgroundColor")} {"  "}
                      <span
                        style={{
                          display: "inline-block",
                          position: "relative",
                        }}
                      >
                        <a
                          href={null}
                          onClick={(e) => this.openPicker("themeLightColor")}
                          style={{
                            color: this.state.currentTheme.themeLightColor,
                            background: this.state.currentTheme.themeLightColor,
                            display: "inline-block",
                            width: 40,
                            height: 40,
                            border: "solid 1px rgba(0,0,0,0.5)",
                            verticalAlign: "middle",
                          }}
                        ></a>
                        {this.state.open === "themeLightColor" ? (
                          <div
                            onMouseLeave={this.hideColorPicker}
                            style={{
                              position: "absolute",
                              top: 0,
                              zIndex: 3,
                              left: "-50%",
                            }}
                          >
                            <SketchPicker
                              color={this.state.currentTheme.themeLightColor}
                              onChangeComplete={(e) =>
                                this.onColorUpdate(this.state.open, e)
                              }
                            ></SketchPicker>
                          </div>
                        ) : null}
                      </span>
                    </Grid>
                    <Grid item xs={6}>
                      {this.props.translate("textColor")} {"  "}
                      <span
                        style={{
                          display: "inline-block",
                          position: "relative",
                        }}
                      >
                        <a
                          href={null}
                          onClick={(e) => this.openPicker("themeLightInverse")}
                          style={{
                            color: this.state.currentTheme.themeLightInverse,
                            background:
                              this.state.currentTheme.themeLightInverse,
                            display: "inline-block",
                            width: 40,
                            height: 40,
                            border: "solid 1px rgba(0,0,0,0.5)",
                            verticalAlign: "middle",
                          }}
                        ></a>
                        {this.state.open === "themeLightInverse" ? (
                          <div
                            onMouseLeave={this.hideColorPicker}
                            style={{
                              position: "absolute",
                              top: 0,
                              zIndex: 3,
                              left: "-50%",
                            }}
                          >
                            <SketchPicker
                              color={this.state.currentTheme.themeLightInverse}
                              onChangeComplete={(e) =>
                                this.onColorUpdate(this.state.open, e)
                              }
                            ></SketchPicker>
                          </div>
                        ) : null}
                      </span>
                    </Grid>
                  </Grid>
                  <h4>{this.props.translate("listingColor")}</h4>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      {this.props.translate("backgroundColor")} {"  "}
                      <span
                        style={{
                          display: "inline-block",
                          position: "relative",
                        }}
                      >
                        <a
                          href={null}
                          onClick={(e) => this.openPicker("themeListingColor")}
                          style={{
                            color: this.state.currentTheme.themeListingColor,
                            background:
                              this.state.currentTheme.themeListingColor,
                            display: "inline-block",
                            width: 40,
                            height: 40,
                            border: "solid 1px rgba(0,0,0,0.5)",
                            verticalAlign: "middle",
                          }}
                        ></a>
                        {this.state.open === "themeListingColor" ? (
                          <div
                            onMouseLeave={this.hideColorPicker}
                            style={{
                              position: "absolute",
                              top: 0,
                              zIndex: 3,
                              left: "-50%",
                            }}
                          >
                            <SketchPicker
                              color={this.state.currentTheme.themeListingColor}
                              onChangeComplete={(e) =>
                                this.onColorUpdate(this.state.open, e)
                              }
                            ></SketchPicker>
                          </div>
                        ) : null}
                      </span>
                    </Grid>
                    <Grid item xs={6}>
                      {this.props.translate("textColor")} {"  "}
                      <span
                        style={{
                          display: "inline-block",
                          position: "relative",
                        }}
                      >
                        <a
                          href={null}
                          onClick={(e) =>
                            this.openPicker("themeListingInverse")
                          }
                          style={{
                            color: this.state.currentTheme.themeListingInverse,
                            background:
                              this.state.currentTheme.themeListingInverse,
                            display: "inline-block",
                            width: 40,
                            height: 40,
                            border: "solid 1px rgba(0,0,0,0.5)",
                            verticalAlign: "middle",
                          }}
                        ></a>
                        {this.state.open === "themeListingInverse" ? (
                          <div
                            onMouseLeave={this.hideColorPicker}
                            style={{
                              position: "absolute",
                              top: 0,
                              zIndex: 3,
                              left: "-50%",
                            }}
                          >
                            <SketchPicker
                              color={
                                this.state.currentTheme.themeListingInverse
                              }
                              onChangeComplete={(e) =>
                                this.onColorUpdate(this.state.open, e)
                              }
                            ></SketchPicker>
                          </div>
                        ) : null}
                      </span>
                    </Grid>
                  </Grid>
                  <h4>{this.props.translate("defaultColor")}</h4>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      {this.props.translate("backgroundColor")} {"  "}
                      <span
                        style={{
                          display: "inline-block",
                          position: "relative",
                        }}
                      >
                        <a
                          href={null}
                          onClick={(e) => this.openPicker("backgroundColor")}
                          style={{
                            color: this.state.currentTheme.backgroundColor,
                            background: this.state.currentTheme.backgroundColor,
                            display: "inline-block",
                            width: 40,
                            height: 40,
                            border: "solid 1px rgba(0,0,0,0.5)",
                            verticalAlign: "middle",
                          }}
                        ></a>
                        {this.state.open === "backgroundColor" ? (
                          <div
                            onMouseLeave={this.hideColorPicker}
                            style={{
                              position: "absolute",
                              top: 0,
                              zIndex: 3,
                              left: "-50%",
                            }}
                          >
                            <SketchPicker
                              color={this.state.currentTheme.backgroundColor}
                              onChangeComplete={(e) =>
                                this.onColorUpdate(this.state.open, e)
                              }
                            ></SketchPicker>
                          </div>
                        ) : null}
                      </span>
                    </Grid>
                    <Grid item xs={6}>
                      {this.props.translate("textColor")} {"  "}
                      <span
                        style={{
                          display: "inline-block",
                          position: "relative",
                        }}
                      >
                        <a
                          href={null}
                          onClick={(e) => this.openPicker("textColor")}
                          style={{
                            color: this.state.currentTheme.textColor,
                            background: this.state.currentTheme.textColor,
                            display: "inline-block",
                            width: 40,
                            height: 40,
                            border: "solid 1px rgba(0,0,0,0.5)",
                            verticalAlign: "middle",
                          }}
                        ></a>
                        {this.state.open === "textColor" ? (
                          <div
                            onMouseLeave={this.hideColorPicker}
                            style={{
                              position: "absolute",
                              top: 0,
                              zIndex: 3,
                              left: "-50%",
                            }}
                          >
                            <SketchPicker
                              color={this.state.currentTheme.textColor}
                              onChangeComplete={(e) =>
                                this.onColorUpdate(this.state.open, e)
                              }
                            ></SketchPicker>
                          </div>
                        ) : null}
                      </span>
                    </Grid>
                  </Grid>
                  <h4>{this.props.translate("menuColor")}</h4>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      {this.props.translate("backgroundColor")} {"  "}
                      <span
                        style={{
                          display: "inline-block",
                          position: "relative",
                        }}
                      >
                        <a
                          href={null}
                          onClick={(e) =>
                            this.openPicker("menuBackgroundColor")
                          }
                          style={{
                            color: this.state.currentTheme.menuBackgroundColor,
                            background:
                              this.state.currentTheme.menuBackgroundColor,
                            display: "inline-block",
                            width: 40,
                            height: 40,
                            border: "solid 1px rgba(0,0,0,0.5)",
                            verticalAlign: "middle",
                          }}
                        ></a>
                        {this.state.open === "menuBackgroundColor" ? (
                          <div
                            onMouseLeave={this.hideColorPicker}
                            style={{
                              position: "absolute",
                              top: 0,
                              zIndex: 3,
                              left: "-50%",
                            }}
                          >
                            <SketchPicker
                              color={
                                this.state.currentTheme.menuBackgroundColor
                              }
                              onChangeComplete={(e) =>
                                this.onColorUpdate(this.state.open, e)
                              }
                            ></SketchPicker>
                          </div>
                        ) : null}
                      </span>
                    </Grid>
                    <Grid item xs={6}>
                      {this.props.translate("textColor")} {"  "}
                      <span
                        style={{
                          display: "inline-block",
                          position: "relative",
                        }}
                      >
                        <a
                          href={null}
                          onClick={(e) => this.openPicker("menuTextColor")}
                          style={{
                            color: this.state.currentTheme.menuTextColor,
                            background: this.state.currentTheme.menuTextColor,
                            display: "inline-block",
                            width: 40,
                            height: 40,
                            border: "solid 1px rgba(0,0,0,0.5)",
                            verticalAlign: "middle",
                          }}
                        ></a>
                        {this.state.open === "menuTextColor" ? (
                          <div
                            onMouseLeave={this.hideColorPicker}
                            style={{
                              position: "absolute",
                              top: 0,
                              zIndex: 3,
                              left: "-50%",
                            }}
                          >
                            <SketchPicker
                              color={this.state.currentTheme.menuTextColor}
                              onChangeComplete={(e) =>
                                this.onColorUpdate(this.state.open, e)
                              }
                            ></SketchPicker>
                          </div>
                        ) : null}
                      </span>
                    </Grid>
                  </Grid>
                  <h4>{this.props.translate("linkColor")}</h4>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      {this.props.translate("backgroundColor")} {"  "}
                      <span
                        style={{
                          display: "inline-block",
                          position: "relative",
                        }}
                      >
                        <a
                          href={null}
                          onClick={(e) =>
                            this.openPicker("menuActiveBackground")
                          }
                          style={{
                            color: this.state.currentTheme.menuActiveBackground,
                            background:
                              this.state.currentTheme.menuActiveBackground,
                            display: "inline-block",
                            width: 40,
                            height: 40,
                            border: "solid 1px rgba(0,0,0,0.5)",
                            verticalAlign: "middle",
                          }}
                        ></a>
                        {this.state.open === "menuActiveBackground" ? (
                          <div
                            onMouseLeave={this.hideColorPicker}
                            style={{
                              position: "absolute",
                              top: 0,
                              zIndex: 3,
                              left: "-50%",
                            }}
                          >
                            <SketchPicker
                              color={
                                this.state.currentTheme.menuActiveBackground
                              }
                              onChangeComplete={(e) =>
                                this.onColorUpdate(this.state.open, e)
                              }
                            ></SketchPicker>
                          </div>
                        ) : null}
                      </span>
                    </Grid>
                    <Grid item xs={6}>
                      {this.props.translate("textColor")} {"  "}
                      <span
                        style={{
                          display: "inline-block",
                          position: "relative",
                        }}
                      >
                        <a
                          href={null}
                          onClick={(e) => this.openPicker("menuActiveColor")}
                          style={{
                            color: this.state.currentTheme.menuActiveColor,
                            background: this.state.currentTheme.menuActiveColor,
                            display: "inline-block",
                            width: 40,
                            height: 40,
                            border: "solid 1px rgba(0,0,0,0.5)",
                            verticalAlign: "middle",
                          }}
                        ></a>
                        {this.state.open === "menuActiveColor" ? (
                          <div
                            onMouseLeave={this.hideColorPicker}
                            style={{
                              position: "absolute",
                              top: 0,
                              zIndex: 3,
                              left: "-50%",
                            }}
                          >
                            <SketchPicker
                              color={this.state.currentTheme.menuActiveColor}
                              onChangeComplete={(e) =>
                                this.onColorUpdate(this.state.open, e)
                              }
                            ></SketchPicker>
                          </div>
                        ) : null}
                      </span>
                    </Grid>
                  </Grid>

                  <h4>{this.props.translate("dashboardBackgroundColor")}</h4>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      {this.props.translate("backgroundColor")} {"  "}
                      <span
                        style={{
                          display: "inline-block",
                          position: "relative",
                        }}
                      >
                        <a
                          href={null}
                          onClick={(e) =>
                            this.openPicker("dashboardBackgroundColor")
                          }
                          style={{
                            color:
                              this.state.currentTheme.dashboardBackgroundColor,
                            background:
                              this.state.currentTheme.dashboardBackgroundColor,
                            display: "inline-block",
                            width: 40,
                            height: 40,
                            border: "solid 1px rgba(0,0,0,0.5)",
                            verticalAlign: "middle",
                          }}
                        ></a>
                        {this.state.open === "dashboardBackgroundColor" ? (
                          <div
                            onMouseLeave={this.hideColorPicker}
                            style={{
                              position: "absolute",
                              top: 0,
                              zIndex: 3,
                              left: "-50%",
                            }}
                          >
                            <SketchPicker
                              color={
                                this.state.currentTheme.dashboardBackgroundColor
                              }
                              onChangeComplete={(e) =>
                                this.onColorUpdate(this.state.open, e)
                              }
                            ></SketchPicker>
                          </div>
                        ) : null}
                      </span>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <div>
                    <div
                      style={{
                        //  background: this.state.currentTheme.themeDarkColor,
                        background: this.state.currentTheme.themeDarkColor,
                        padding: 20,
                        marginBottom: 20,
                        borderRadius: 10,
                      }}
                    >
                      <small>{this.props.translate("fillPrimaryColor")}</small>
                      <h3
                        style={{
                          marginBottom: 0,
                          marginTop: 5,
                          color: this.state.currentTheme.themeInverse,
                        }}
                      >
                        {this.props.translate("themeTextColor")}
                      </h3>
                    </div>

                    <div
                      style={{
                        background: this.state.currentTheme.themeLightColor,
                        padding: 20,
                        marginBottom: 20,
                        borderRadius: 10,
                      }}
                    >
                      <small>
                        {this.props.translate("fillThemeSecondaryColor")}
                      </small>
                      <h3
                        style={{
                          marginBottom: 0,
                          marginTop: 5,
                          color: this.state.currentTheme.themeLightInverse,
                        }}
                      >
                        {this.props.translate("themeSecondaryTextColor")}
                      </h3>
                    </div>

                    <div
                      style={{
                        background: this.state.currentTheme.themeListingColor,
                        padding: 20,
                        marginBottom: 20,
                        borderRadius: 10,
                      }}
                    >
                      <small>
                        {this.props.translate("fillThemeListingColor")}
                      </small>
                      <h3
                        style={{
                          marginBottom: 0,
                          marginTop: 5,
                          color: this.state.currentTheme.themeListingInverse,
                        }}
                      >
                        {this.props.translate("themeListingTextColor")}
                      </h3>
                    </div>

                    <div
                      style={{
                        background: this.state.currentTheme.backgroundColor,
                        padding: 20,
                        marginBottom: 20,
                        borderRadius: 10,
                      }}
                    >
                      <small>
                        {this.props.translate("fillDefaultBackgroundColor")}
                      </small>
                      <h3
                        style={{
                          marginBottom: 0,
                          marginTop: 5,
                          color: this.state.currentTheme.textColor,
                        }}
                      >
                        {this.props.translate("defaultTextColor")}
                      </h3>
                    </div>

                    <div
                      style={{
                        background: this.state.currentTheme.menuBackgroundColor,
                        padding: 20,
                        marginBottom: 20,
                        borderRadius: 10,
                      }}
                    >
                      <small>
                        {this.props.translate("fillMenuBackgroundColor")}
                      </small>
                      <h3
                        style={{
                          marginBottom: 0,
                          marginTop: 5,
                          color: this.state.currentTheme.menuTextColor,
                        }}
                      >
                        {this.props.translate("menuTextColor")}
                      </h3>
                    </div>

                    <div
                      style={{
                        background:
                          this.state.currentTheme.menuActiveBackground,
                        padding: 20,
                        marginBottom: 20,
                        borderRadius: 10,
                      }}
                    >
                      <small>
                        {this.props.translate("fillMenuActiveBackground")}
                      </small>
                      <h3
                        style={{
                          marginBottom: 0,
                          marginTop: 5,
                          color: this.state.currentTheme.menuActiveColor,
                        }}
                      >
                        {this.props.translate("menuActiveColor")}
                      </h3>
                    </div>

                    <div
                      style={{
                        background:
                          this.state.currentTheme.dashboardBackgroundColor,
                        padding: 20,
                        marginBottom: 20,
                        borderRadius: 10,
                      }}
                    >
                      <small>
                        {this.props.translate("fillDashboardBackground")}
                      </small>
                    </div>
                  </div>
                </Grid>
              </Grid>
              {checkPrivileges("userUpdate") &&
                this.state.themeValue === "custom" && (
                  <Button onClick={this.applyTheme}>
                    {this.props.translate("update")}
                  </Button>
                )}
            </>
          ) : (
            ""
          )}
          {checkPrivileges("userUpdate") &&
            this.state.themeValue !== "custom" && (
              <Button type="submit" disabled={!this.state.isEnable}>
                {this.props.translate("update")}
              </Button>
            )}
        </form>
      </>
    );
    return <div>{Content}</div>;
  }
}
const AddAccountModal = withStyles(styles);
export default AddAccountModal(withTranslationWrapper(addAccountModal));

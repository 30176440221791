import React, { Component, Fragment } from 'react'
import { withStyles } from '@mui/styles'

import { checkPrivileges } from '../../../../Helpers'
import { updateAccount } from '../../../../Actions/Accounts'
import { validEmailPattern } from '../../../../Helpers'
import AddAccountModal from '../../addAccount'
import isEqual from 'react-fast-compare'
import { ServerInfo } from '../../../../Actions/serverSetting'
import withTranslationWrapper from '../../../../HOC/HocTranslate'
import { notifyError, notifySuccess } from '../../../../Utils/CustomNotifcations'

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  dense: {
    marginTop: 19
  },
  menu: {
    width: 200
  },
  button: {
    margin: theme.spacing(1)
  }
})

class updateAccountDetail extends Component {
  constructor (props) {
    super(props)
    this.state = {
      updateOption: true,
      isVisableBtn: false,
      isAttChanged: false,
      emailPattern: false,
      notVisableBackbtn: true,
      selectedAccount: '',
      form: {
        name: '',
        email: '',
        contact: '',
        description: ''
      }
    }
    this.checkRequiredFields = this.checkRequiredFields.bind(this)
    this.submit = this.submit.bind(this)
    // this.changeAttribute = this.changeAttribute.bind(this)
    // this.checkForAttrubutesChanged = this.checkForAttrubutesChanged.bind(this)
  }
  componentWillMount () {
    this.setState({
      selectedAccount: this.props.selectedAccount,
      form: { ...this.props.selectedAccount },
      itemAttributes: { ...this.props.selectedAccount.attributes }
    })
  }

  handleChange = name => event => {
    const { target } = event
    if (name === 'email') {
      if (!validEmailPattern.test(target.value)) {
        this.setState({
          emailPattern: true
        })
      } else {
        this.setState({
          emailPattern: false
        })
      }
    }
    this.setState(
      preState => ({
        ...preState,
        form: {
          ...preState.form,
          [name]: target.value
        }
      }),
      () => this.checkRequiredFields()
    )
  }
  changeAttribute2 = (name, event) => {
    let value =
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value
    if (!value) {
      if (this.state.form.attributes[name]) {
        delete this.state.form.attributes[name]
      }
      this.setState(
        {
          form: {
            ...this.state.form,
            attributes: {
              ...this.state.form.attributes
            }
          }
        },
        () => this.checkRequiredFields()
      )
    } else {
      this.setState(
        {
          form: {
            ...this.state.form,
            attributes: {
              ...this.state.form.attributes,
              [name]: event.target.type === 'number' ? parseInt(value) : value
            }
          }
        },
        () => this.checkRequiredFields()
      )
    }
  }
  checkRequiredFields () {
    let { form } = this.state
    let valid = true
    let value = !isEqual(this.state.form, this.state.selectedAccount)
    if (!validEmailPattern.test(form.email)) {
      valid = false
    }
    if (form.name && form.email && value) {
      this.setState({
        isVisableBtn: valid
      })
    } else {
      this.setState({
        isVisableBtn: this.state.isAttChanged
      })
    }
  }
  submit () {
    let { form } = this.state
      fetch(`/api/accounts/${form.id}`, {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          ...form
        })
      })
        .then(response => {
          if (response.ok) {
            response.json().then(res => {
              let account = res.data
              if(res.status === 'success'){
                this.props.UpdateSelectedItem(account)
                this.props.dispatch(updateAccount(account))
                this.props.dispatch(
                  notifySuccess(this.props.translate('accountUpdated'))
                )
                this.setState({
                  form: { ...account }
                })
                if(this.props.selectedAccount.id === 1){
                  this.props.dispatch(ServerInfo(account))
                }
              }
              else if(res?.statusCode === '440'){
                window.location.replace('/login')
              }
              else if(res.statusCode){
                var err = res?.message.split(':')
                err[1] =err[1].replace(')', "")
                this.props.dispatch(
                  notifyError(this.props.translate(err[1]))
                )
              }
            })
          } 
        })
        .catch(e => {
          this.props.dispatch(
            notifyError(this.props.translate('somethingWentWrong'))
          )
          // if(e && e.text){
          //   e.text().then(err => {
          //     if (err.includes('Duplicate entry')) {
          //       this.setState({
          //         duplicateEntry: true,
          //         isVisableUserBtn: false
          //       })
          //       this.props.dispatch(
          //         notifyError({
          //           message: this.props.translate('userEmailIsAllreadyInUsed'),
          //           autoDismiss: 5,
          //           location: this.props.location
          //         })
          //       )
          //     } else {
          //       this.props.dispatch(
          //         notifyError({
          //           message: 'someThing went wrong',
          //           autoDismiss: 5,
          //           location: this.props.location
          //         })
          //       )
          //     }
          //   })
          // }
        })
  }
  render () {
    return (
      <Fragment>
        <AddAccountModal
          {...this.state}
          roleTypes={this.props.roles}
          formSubmit={this.submit}
          padding={'0px'}
          elevation={0}
          userType={this.props.logInUser.userType}
          changeAttribute2={this.changeAttribute2}
          handleChange={this.handleChange}
          form={this.state.form}
          buttonText={this.props.translate('update')}
          isVisableBtn={this.state.isVisableBtn}
          itemAttributes={this.state.itemAttributes}
          attributeChangesMessage={this.state.attributeChangesMessage}
          hasAccessOfUpdate={checkPrivileges('accountUpdate')}
          hasAccessOfDelete={checkPrivileges('accountDelete')}
          hasAccessOfCreate={checkPrivileges('accountCreate')}
        />
      </Fragment>
    )
  }
}

const UpdateAccountDetail = withStyles(styles)
export default UpdateAccountDetail(withTranslationWrapper(updateAccountDetail))

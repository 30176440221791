import React, { Component } from 'react';
import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { connect } from 'react-redux';

let themecolors;
const mapStateToProps = (state, ownProps) => {
  themecolors = state.themecolors;
  return {
    themecolors: state.themecolors,
  };
};

// Styled Checkbox using MUI v5 `styled` API
const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
  color: themecolors[600],
  '&.Mui-checked': {
    color: themecolors[500],
  },
}));

class CheckboxLabels extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: props.checked,
    };
  }

  handleChange = (name) => (event) => {
    this.setState({ checked: event.target.checked });
    if (this.props.onChange) {
      this.props.onChange(event, name);
    }
  };

  render() {
    const { label, id, ...others } = this.props;

    return (
      <FormControlLabel
        control={
          <CustomCheckbox
            checked={this.state.checked}
            onChange={this.handleChange(id)}
            {...others}
          />
        }
        label={label || ''}
      />
    );
  }
}

export default connect(mapStateToProps)(CheckboxLabels);

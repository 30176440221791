import React, { Component } from 'react'
import { connect } from 'react-redux'
 import { compose } from 'redux'
import TextField from '../common/TextField'
import { withStyles } from '@mui/styles'
import Checkbox from '@mui/material/Checkbox'
import Grid from '@mui/material/Grid'
import EditIcon from '@mui/icons-material/Place'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import MenuItem from '@mui/material/MenuItem'
import MarkerMap from '../common/Maps'
import { notifyError, notifySuccess } from '../../Utils/CustomNotifcations'
import FormControlLabel from '@mui/material/FormControlLabel'
import Button from '../common/Button'
import { ServerInfo } from '../../Actions/serverSetting'
import CustomDialog from '../common/Dialog'
import isEqual from 'react-fast-compare'

const mapLayerTypes = [
  {
    key: 'carto',
    name: 'mapCarto'
  },
  {
    key: 'osm',
    name: 'mapOsm'
  },
  {
    key: 'bingRoad',
    name: 'mapBingRoad'
  },
  {
    key: 'bingAerial',
    name: 'mapBingAerial'
  },
  {
    key: 'bingHybrid',
    name: 'mapBingHybrid'
  },
  {
    key: 'baidu',
    name: 'mapBaidu'
  },
  {
    key: 'yandexMap',
    name: 'mapYandexMap'
  },
  {
    key: 'yandexSat',
    name: 'mapYandexSat'
  },
  {
    key: 'wikimedia',
    name: 'mapWikimedia'
  },
  {
    key: 'custom',
    name: 'mapCustom'
  }
]
const coordinateFormatTypes = [
  {
    key: 'dd',
    name: 'sharedDecimalDegrees'
  },
  {
    key: 'ddm',
    name: 'sharedDegreesDecimalMinutes'
  },
  {
    key: 'dms',
    name: 'sharedDegreesMinutesSeconds'
  }
]

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  dense: {
    marginTop: 19
  },
  menu: {
    width: 200
  },
  button: {
    margin: theme.spacing(1)
  }
})

class Preferences extends Component {
  constructor () {
    super()
    this.state = {
      form: {
        map: '',
        latitude: '',
        longitude: '',
        zoom: '',
        twelveHourFormat: false,
        forceSettings: false,
        coordinateFormat: '',
        poiLayer: '',
        mapUrl: '',
        bingKey: '',
        // attributes:{
        //   attendanceServerUrl:'',
        // }
      },
      isMapModal: false,
      isVisableAddlocBtn: false,
      localPostionObj: '',
      isVisableAddBtn: false
    }
    this.handleChange = this.handleChange.bind(this)
    this.formSubmit = this.formSubmit.bind(this)
    this.formReset = this.formReset.bind(this)
  }
  componentWillMount () {
    this.setState({
      form: { ...this.props.ServerSetting }
    })
  }
  handleClickShowPostion = () => {
    this.setState({
      isMapModal: true,
      isVisableAddlocBtn: false
    })
  }
  getPostion = (latlng, zoom) => {
    if (latlng.lat && latlng.lng && zoom) {
      this.setState({
        localPostionObj: { latlng, zoom },
        isVisableAddlocBtn: true
      })
    }
  }
  setSelectedLocation = () => {
    const { localPostionObj } = this.state
    if (localPostionObj) {
      this.setState(
        {
          form: {
            ...this.state.form,
            latitude: localPostionObj.latlng.lat,
            longitude: localPostionObj.latlng.lng,
            zoom: localPostionObj.zoom
          },
          isMapModal: false
        },
        () => this.checkRequiredFields()
      )
    }
  }
  checkRequiredFields = () => {
    let value = !isEqual(this.state.form, this.props.ServerSetting)
    this.setState({
      isVisableAddBtn: value
    })
  }
  formReset () {
    this.setState({
      form: { ...this.props.ServerSetting },
      isVisableAddBtn: false
    })
  }
  formSubmit () {
    let { ServerSetting } = this.props
    const { form } = this.state
    ServerSetting = { ...form }
    fetch(`api/server/`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        ...ServerSetting
      })
    })
      .then(response => {
        if (response.ok) {
          response.json().then(server => {
            if(server.status ==='success'){
              // this.props.dispatch(ServerInfo(server.data))
              this.props.handleChangeServerSettings(server.data)
              this.props.dispatch(
                notifySuccess('perferenceInfoIsUpdated')
              )
            }
            else if(server?.statusCode === '440'){
              window.location.replace('/login')
            }
            else if(server.statusCode){
              var err = server?.message.split(':')
              err[1] =err[1].replace(')', "")
              this.props.dispatch(
                notifyError(err[1])
              )
            }
          })
          this.setState({
            isVisableAddBtn: false
          })
        } else {
          throw response
        }
      })
      .catch(e => {
        this.props.dispatch(
          notifyError('somthingWentWrongMessage')
        )
      })
  }
  handleChange = name => event => {
    if(name === 'attendanceServerUrl'){
        this.setState({
            form:{
              ...this.state.form,
              attributes:{
                ...this.state.form.attributes,
                [name]: event.target.value
              }
            },
            isVisableAddBtn:true
        })
    }
    else{
      const { target } = event
      let value = target.type === 'checkbox' ? target.checked : target.value
      if (target.type === 'number') {
        value = parseFloat(value)
      }
      this.setState(
        {
          form: {
            ...this.state.form,
            [name]: value
          }
        },
        () => this.checkRequiredFields()
      )
    }
    
  }
  onCloseModal = () => {
    this.setState({
      isMapModal: false
    })
  }

  render () {
    const { classes } = this.props
    return (
      <div>
        {this.state.isMapModal && (
          <CustomDialog
            title={this.props.translate('setLocation')}
            themeColors={this.props.themeColors}
            visable={true}
            onClose={this.onCloseModal}
            isButtonVisable={true}
            draggable={true}
            fullWidth
            CancelText={this.props.translate('sharedBack')}
            headerActions={
              <Button
                size='small'
                variant='contained'
                color='secondary'
                disabled={!this.state.isVisableAddlocBtn}
                onClick={() => this.setSelectedLocation()}
              >
                {' '}
                {this.props.translate('sharedAdd')}
              </Button>
            }
            bodyPadding={0}
          >
            <MarkerMap
              getPostion={this.getPostion}
              minHeight={500}
              zoom={this.state.form.zoom || 0}
              lat={this.state.form.latitude || 0}
              lng={this.state.form.longitude || 0}
              height={'100%'}
            />
          </CustomDialog>
        )}
        <Grid container spacing={2}>
          <Grid item xs={12} md={3} sm={6}>
            <TextField
              id='map'
              select
              label={this.props.translate('mapTitle')}
              value={this.state.form.map}
              onChange={this.handleChange('map')}
              SelectProps={{
                MenuProps: {
                  className: classes.menu
                }
              }}
              margin='dense'
              fullWidth
            >
              {mapLayerTypes.map(option => (
                <MenuItem key={option.key} value={option.key}>
                  {this.props.translate(option.name)}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <TextField
              id='coordinateFormat'
              select
              label={this.props.translate('CoordinateFormat')}
              value={this.state.form.coordinateFormat}
              onChange={this.handleChange('coordinateFormat')}
              SelectProps={{
                MenuProps: {
                  className: classes.menu
                }
              }}
              margin='dense'
              fullWidth
            >
              <MenuItem value=''>
                <em>None</em>
              </MenuItem>
              {coordinateFormatTypes.map(option => (
                <MenuItem key={option.key} value={option.key}>
                  {this.props.translate(option.name)}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <TextField
              id='attendanceServerUrl '
              label={this.props.translate('attendanceServerUrl')}
              type='text'
              placeholder=''
              value={this.state.form.attributes && this.state.form.attributes.attendanceServerUrl  || ''}
              onChange={this.handleChange('attendanceServerUrl')}
              variant='outlined'
              margin='dense'
              fullWidth
            />
          </Grid>
          {/* <Grid item xs={12} md={3} sm={6}>
            <TextField
              id='mapUrl'
              label={this.props.translate('customMap')}
              type='text'
              placeholder=''
              value={this.state.form.mapUrl || ''}
              onChange={this.handleChange('mapUrl')}
              variant='outlined'
              margin='dense'
              fullWidth
            />
          </Grid> */}
          <Grid item xs={12} md={3} sm={6}>
            <TextField
              id='poiLayer'
              label={this.props.translate('mapPoiLayer')}
              type='text'
              placeholder=''
              value={this.state.form.poiLayer || ''}
              onChange={this.handleChange('poiLayer')}
              variant='outlined'
              margin='dense'
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.form.forceSettings || false}
                  onChange={this.handleChange('forceSettings')}
                  value='forceSettings'
                  color='primary'
                />
              }
              label={this.props.translate('forceSettings')}
            />
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.form.twelveHourFormat || false}
                  onChange={this.handleChange('twelveHourFormat')}
                  value='twelveHourFormat'
                  color='primary'
                />
              }
              label={this.props.translate('settingsTwelveHourFormat')}
            />
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <TextField
              id='latitude'
              type='number'
              label={this.props.translate('positionLatitude')}
              placeholder=''
              value={this.state.form.latitude || ''}
              onChange={this.handleChange('latitude')}
              variant='outlined'
              margin='dense'
              fullWidth
            />
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <TextField
              id='longitude'
              type='number'
              label={this.props.translate('positionLongitude')}
              value={this.state.form.longitude || ''}
              onChange={this.handleChange('longitude')}
              variant='outlined'
              margin='dense'
              fullWidth
            />
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <TextField
              id='zoom'
              label={this.props.translate('serverZoom')}
              type='number'
              placeholder=''
              value={this.state.form.zoom || ''}
              onChange={this.handleChange('zoom')}
              variant='outlined'
              margin='dense'
              fullWidth
            />
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Tooltip title='Add Location'>
              <IconButton onClick={this.handleClickShowPostion}>
                {<EditIcon />}
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>

        <br />
        <Button
          variant='contained'
          onClick={this.formReset}
          style={{ marginLeft: 8, marginRight: 8 }}
        >
          {' '}
          {this.props.translate('resetButton')}
        </Button>
        <Button
          variant='contained'
          disabled={!this.state.isVisableAddBtn}
          onClick={() => this.formSubmit()}
        >
          {' '}
          {this.props.translate('sharedSave')}
        </Button>
      </div>
    )
  }
}

const enhance = compose(withStyles(styles), connect())

export default enhance(Preferences)

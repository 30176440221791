import React, { Component } from "react"
// import { Translate } from 'react-localize-redux'
import axios from 'axios'
import parse from 'html-react-parser';
import { notifyError } from "../Utils/CustomNotifcations";
export const validEmailPattern = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,30}(?:\.[a-z]{2})?)$/i
export function checkPrivileges (type) {
  let privileges = localStorage.getItem('privileges')
  let userInfo = localStorage.getItem('userInfo')
  if (privileges && userInfo) {
    let userPrivileges = JSON.parse(privileges)
    const info = JSON.parse(userInfo)
    if (userPrivileges && info && info.id) {
      return Object.values(userPrivileges).indexOf(type) > -1
    } else {
      window.location.assign('/logout')
      return false
    }
  } else {
    window.location.assign('/logout')
  }
}

export function displayErrorMessages (err) {
  console.log('err =', err)
  let errormgs = ''
  if (err.includes('Admin')) {
    errormgs = 'youRequiredAdministrationAccess'
  } else if (err.includes('Unauthorized')) {
    errormgs = 'yourEmailOrPasswordIsWrong'
  } else if (err.includes('Account has expired')) {
    errormgs = 'yourAccountHasExpired'
  } else if (err.includes('Account is disabled')) {
    errormgs = 'accountIsDisabled'
  } else if (err.includes('Invalid Current Password')) {
    errormgs = 'wrongCurrentPassword'
  } else if (err.includes('Invalid User. Please try again with other user')) {
    errormgs = 'invalidUserPleaseTryAgainWithOtherUser'
  } else if (err.includes('UnAuthorize User')) {
    errormgs = 'UnAuthorizeUserPleaseTryAgainWithOtherUser'
  } else if (err.includes('Not enough privileges')) {
    errormgs = 'somthingWentWrongMessage1'
  } else {
    errormgs = 'somthingWentWrongMessage1'
  }
  return errormgs
}

export function isOwnerOrDealer (type) {
  if (type === -1 || type === 5) {
    return true
  } else {
    return false
  }
}

export function isAuthenticated (props) {
  const info = JSON.parse(localStorage.getItem('userInfo'))
  if (info && info.id) {
    return info.id
  } else {
    if (props && props.history) {
      props.history.push('/logout')
    }
  }
}

export function prepareUsers (users) {
  const userArray = []
  if (users) {
    users.map(item => {
      let singleUser = {}
      const parent = {
        parentId: item.parent.id || '',
        parentName: item.parent.name || '',
        accountName: item.extraInfo.accountName || ''
      }
      const extraInfo = { ...item.extraInfo }
      singleUser = { ...item.entity, ...parent, extraInfo }
      const keys = Object.keys(singleUser)
      const final = {}
      keys.map(key => {
        if (typeof singleUser[key] === 'string') {
          final[key] = parse(singleUser[key])
        } else final[key] = singleUser[key]
      })

      userArray.push(final)
    })
  }
  return userArray
}
export function prepareUserForLogin (users) {
  if (users) {
    users.map(item => {
      item.logInAsUserState = ''
    })
  }
  return users
}

export function checkUserRoles (users, roles) {
  if (users && roles) {
    users.map(value => {
      for (let item of roles) {
        if (value.roleId === item.id) {
          value['roleName'] = item.name
        }
      }
    })
  }
  return users
}
export function checkUserAcount (users, account) {
  if (users && account) {
    users.map(value => {
      for (let item of account) {
        if (value.accountId === item.id) {
          value['accountName'] = item.name || item.label
        }
      }
    })
  }
  return users
}

export function prepareUnits (units) {
  let unitsArray = [],
    singleUnit = {}
  if (units) {
    units.map(item => {
      let parent = {
        parentId: item.parent.id,
        parentName: item.parent.name
      }
      singleUnit = { ...item.entity, ...parent }
      unitsArray.push(singleUnit)
    })
  }
  return unitsArray
}

export function checkRolesOfParent (units, users, roles) {
  if (units && users && roles) {
    units.map(value => {
      for (let item of users) {
        if (value.parentId === item.id) {
          for (let role of roles) {
            if (role.id === item.roleId) {
              value['ownerRole'] = role.name
            }
          }
        }
      }
    })
  }
  return units
}

export function checkUsersOfUnits (unitUser, users) {
  let usersArray = [],
    singleUser = {}
  if (users && unitUser) {
    users.map(value => {
      singleUser = JSON.parse(JSON.stringify(value))
      singleUser['check'] = false
      singleUser['indeterminate'] = false
      singleUser['readonly'] = false
      for (let item of unitUser) {
        if (value.id === item.userId) {
          //   singleUser.check=true
          if (item.parent) {
            item.check = true
            singleUser.check = true
            item.readonly = true
            singleUser.readonly = true
          } else {
            if (item.directAccess) {
              item.readonly = false
              singleUser.readonly = false
              item.check = true
              singleUser.check = true
            } else {
              item.readonly = false
              singleUser.readonly = false
              item.check = false
              singleUser.check = false
              singleUser.indeterminate = true
            }
          }
        }
      }
      usersArray.push(singleUser)
    })
  }
  return usersArray
}

export function checkUsersOfUnits1 (users) {
  if (users) {
    users.map(item => {
      item['check'] = false
      item['indeterminate'] = false
      item['readonly'] = false
      if (item.directAccess) {
        item.check = true
        item.readonly = true
        item.readonly = true
      } else {
        if (item.directAccess === false) {
          item.readonly = true
          item.check = false
          item.indeterminate = true
        } else {
          item.readonly = true
          item.check = false
          item.indeterminate = false
        }
      }
    })
  }
  return users
}

export const PaginationConfig = {
  page: 1,
  pageSize: 20,
  itemSearch: ''
}

export function getCookie (cname) {
  var name = cname + '='
  var decodedCookie = decodeURIComponent(document.cookie)
  var ca = decodedCookie.split(';')
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}
export function setCookie (cname, exdays) {
  var d = new Date()
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
  var expires = 'expires=' + d.toUTCString()
  document.cookie = cname + '= ;' + expires + ';path=/'
}
export function checkedDevelopmentType () {
  let result = `&host=${window.location.host}`
  let serverType = window.location.host.includes('localhost')
  if (serverType) {
    result = ''
  }
  return result
}

export const selectStyles = themecolors => ({
  option: base => ({
    ...base,
    padding: '10px 14px',
    color: themecolors['themeInverse']
  }),
  noOptionsMessage: base => ({
    ...base,
    padding: '10px 14px'
  }),
  multiValue: base => ({
    ...base,
    margin: '2px',
    background: themecolors[600],
    color: themecolors['themeInverse']
  }),
  multiValueLabel: base => ({
    ...base,
    color: themecolors['themeInverse']
  }),
  placeholder: base => ({
    ...base,
    color: themecolors['themeInverse'],
    opacity: 0.5
  }),
  dropdownIndicator: base => ({
    ...base,
    color: themecolors['themeInverse'],
    padding: 4
  }),
  clearIndicator: base => ({
    ...base,
    color: themecolors['themeInverse'],
    padding: 4
  }),
  indicatorsContainer: base => ({
    ...base,
    color: themecolors['themeInverse']
  }),
  input: base => ({
    ...base,
    color: themecolors['themeInverse']
  }),
  container: base => ({
    ...base,
    margin: '8px 0 4px'
  }),
  control: base => ({
    ...base,
    boxShadow:
      '0px 2px 4px 0px rgba(20, 20, 20, 0.15), 0px 2px 4px 0px rgba(20, 20, 20, 0.15) inset',
    background: 'none',
    borderColor: themecolors['600'],
    minHeight: 26,
    '&:hover': {
      borderColor: themecolors['600']
    }
  }),
  menuList: base => ({
    ...base,
    background: themecolors['500'],
    color: themecolors['themeInverse']
  }),
  singleValue: base => ({
    ...base,
    color: themecolors['themeInverse']
  })
})

export const errorHandler = (error, dispatch, callback) => {
  console.log('err in helper =', error)
  if(error?.message?.includes("HINT")){
    let message = error.message.split(":")[1]
    if(message.includes("Duplicate entry")){
      dispatch(
        notifyError('Duplicate entry')
      )
    }else{
      dispatch(
        notifyError(message.replace(')',''))
      )
    }
}else if(error?.statusCode === '440'){
  dispatch(
    notifyError(error?.message)
  )
  window.location.replace('/login')
}
else {
  if(!axios.isCancel(error)){
    dispatch(
      notifyError('somthingWentWrongMessage')
    )
  }
}
  callback && typeof callback === 'function' && callback(error)
}

export const Api = 'http://185.230.210.44:3003/'

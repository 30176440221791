import React, { Component } from 'react'
import { connect } from 'react-redux'
import AppBar from '@mui/material/AppBar'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Paper from '@mui/material/Paper'
import ChangedPasword from './changedPassword'
import ServicePreferences from './servicePerferences'
import UserDefault from './userDefault'
import Permissions from './Permissions'
import Style from 'style-it'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { withStyles } from '@mui/styles'
import Breadcrumb from '../common/Breadcrumb'
import Button from '../common/Button'
import withTranslationWrapper from '../../HOC/HocTranslate'
import { checkPrivileges } from '../../Helpers'

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    position: 'relative',
    padding: theme.spacing(1) * 2,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1) * 2
  },
  nested: {
    paddingLeft: theme.spacing(1) * 4
  }
})


class accountManagements extends Component {
  constructor () {
    super()
    this.state = {
      value: 0
    }
  }
  handleChange = (event, value) => {
    this.setState({ value })
  }
  render () {
    const { classes, logInUser } = this.props
    const { value } = this.state
    const orientation = window.innerWidth <= 959 ? 'horizontal' : 'vertical'
    let tabName = 'Edit'
    if(value ===0){tabName=this.props.translate('servicePreferences')}
    else if(value===1){tabName=this.props.translate('permissions')}
    else if(value===2){tabName=this.props.translate('userDefault')}
    else if(value===3){tabName=this.props.translate('changePassword')}

    return (
      <div className={classes.root}>
            <Breadcrumb
              tabName={tabName}
              onClose={this.props.onClose}
              moduleName={this.props.translate('UserSettings')}
            />
           <Style>{`
          .tab-vertial-wrapper > .custom-tabs {
            background: ${this.props.themecolors.backgroundColor};
          }
          .custom-tab-button2 .MuiTab-wrapper {
            align-items: normal;
            flex-direction: row;
            justify-content: unset;

          }
          .custom-tab-button2 {
            color: ${this.props.themecolors.textColor};
            text-align: left;
            text-transform: inherit;
            border-bottom: 1px solid rgba(0,0,0,0.05)
          }
          .custom-tab-button {
            min-height: 29px;
            border-radius: 6px 6px 0 0;
            margin: 0 2px;
            background: ${this.props.themecolors['600']};
            color: ${this.props.themecolors['themeInverse']};
          }
          .custom-tabs {
            min-height: 30px;
            margin: 10px 6px 0;
          }
 .Mui-selected  {  
            color: ${this.props.themecolors["500"]}  !important;
          }
          .custom-tab-button-selected2 {
            color: ${this.props.themecolors['500']};
          }`}</Style>
          <div className="v-tabs">
           <AppBar elevation={0} position="static" style={{background: 'transparent'}} className="tab-vertial-wrapper">
              <Tabs
                value={value}
                onChange={this.handleChange}
                orientation={orientation}
                classes={{ root: 'custom-tabs' }}
                TabIndicatorProps={{
                  style: { background: 'transparent' }
                }}
                  style={{alignItems:"flex-start", }}

              >
            <Tab
              classes={{
                root: 'custom-tab-button2',
                selected: 'custom-tab-button-selected2'
              }}
                  style={{alignItems:"flex-start", }}

              label={<div style={{ display: "flex", alignItems: "center" }}><KeyboardArrowRightIcon color='currentColor' />{this.props.translate('servicePreferences')}</div>}
            />
            <Tab
              classes={{
                root: 'custom-tab-button2',
                selected: 'custom-tab-button-selected2'
              }}
                  style={{alignItems:"flex-start", }}

              label={<div style={{ display: "flex", alignItems: "center" }}><KeyboardArrowRightIcon color='currentColor' />{this.props.translate('permissions')}</div>}
            />
            <Tab
              classes={{
                root: 'custom-tab-button2',
                selected: 'custom-tab-button-selected2'
              }}
                  style={{alignItems:"flex-start", }}

              label={<div style={{ display: "flex", alignItems: "center" }}><KeyboardArrowRightIcon color='currentColor' />{this.props.translate('userDefault')}</div>}
            />
            {checkPrivileges("userChangePassword") &&  (
              <Tab
              classes={{
                root: 'custom-tab-button2',
                selected: 'custom-tab-button-selected2'
              }}
              style={{alignItems:"flex-start", }}

              label={<div style={{ display: "flex", alignItems: "center" }}><KeyboardArrowRightIcon color='currentColor' />{this.props.translate('changePassword')}</div>}
            />)}
          </Tabs>
        
        <Paper style={{ padding: 16, minHeight: 270, flex:1 }} square={true}>
          {value === 0 && (
            <div>
              <ServicePreferences {...this.props} />
            </div>
          )}
          {value === 1 && (
            <div>
              <Permissions {...this.props} />
            </div>
          )}
          {value === 2 && (
            <div>
              <UserDefault {...this.props} />
            </div>
          )}
          {value === 3 && (
            <div>
              <ChangedPasword {...this.props} />
            </div>
          )}
        </Paper>
        </AppBar>
      </div>
      </div>
    )
  }
}

const mapState = state => ({
  users: state.users,
  devices: state.devices,
  groups: state.groups,
  logInUser: state.logInUsers,
  themecolors: state.themecolors,
  parentUserLimits: state.parentUserLimits,
  parentUnitLimits: state.parentUnitLimits
})
const mapStateToProps = connect(mapState)
export const AcManagements = mapStateToProps(
  withTranslationWrapper(withStyles(styles)(accountManagements))
)
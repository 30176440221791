import React, { Component } from 'react'
// import { withLocalize } from 'react-localize-redux'
import SimpleModalWrapped from './../common/Modal'
import TextField from './../common/TextField'
// import { withStyles } from '@material-ui/core/styles'
import { withStyles } from '@mui/styles'

// import Grid from '@material-ui/core/Grid'
// import Checkbox from '../common/Checkbox'
// import MenuItem from '@material-ui/core/MenuItem'
// import InputAdornment from '@material-ui/core/InputAdornment'
// import RefreshIcon from '@material-ui/icons/Refresh'
// import CrossIcons from '@material-ui/icons/Remove'
import RefreshIcon from '@mui/icons-material/Refresh'
import IconButton from '@mui/material/IconButton'

// import IconButton from '@material-ui/core/IconButton'
// import Tooltip from '@material-ui/core/Tooltip'
import Attributes from '../Attributes/attributesModal'
// import DateFnsUtils from '@date-io/date-fns'
// import Divider from '@material-ui/core/Divider'
// import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers'
import {
  mapLayerTypes,
  coordinateFormatTypes,
  ACownerUserType,
  ACdistributorUserType,
  ACResllerUserType,
  ACSPUserType,
  // checkUnitsWithStandardType,
  // measurementStandard
} from '../Users/userDataTypes'
import { checkPrivileges } from '../../Helpers'
import withTranslationWrapper from '../../HOC/HocTranslate'
import { Divider, Grid, InputAdornment, Tooltip } from '@mui/material'
import MenuItem from '../common/MenuItem'

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  dense: {
    marginTop: 19
  },
  menu: {
    width: 200
  },
  button: {
    margin: theme.spacing(1)
  }
})
class addAccountModal extends Component {
  constructor () {
    super()
    this.state = {
      attm_isVisable: false,
      showButton:false
    }
    this.addAttributes = this.addAttributes.bind(this)
  }

  addAttributes () {
    this.setState({
      attm_isVisable: !this.state.attm_isVisable
    })
  }

  toggleRoles = (e) =>{
    this.setState({
      showButton:!this.state.showButton
    }) 
    return this.props.handleChangeForSP('roleId')(e)
  }


  render () {
    const { classes } = this.props
    let userTypeArray = []
    if (this.props.userType) {
      let { userType } = this.props
      if (userType === -1) {
        userTypeArray = ACownerUserType
      }
      else if (userType === 4) {
        userTypeArray = ACSPUserType
      } 
      else if (userType === 5) {
        userTypeArray = ACResllerUserType
      }
      else if (userType === 6) {
        userTypeArray = ACdistributorUserType
      } 
      else {
        userTypeArray = []
      }
    }
    const Content = (
      <>
        <Grid container spacing={2}>
          <Grid item md={3} sm={6} xs={12}>
            <TextField
              id='name'
              label={this.props.translate('accountTitle')}
              type='text'
              placeholder=''
              value={this.props.form.name}
              onChange={this.props.handleChange('name')}
              variant='outlined'
              margin='dense'
              fullWidth
              required
              InputProps={{
                readOnly:
                  !this.props.updateOption || checkPrivileges('userUpdate')
                    ? false
                    : true
              }}
            />
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <TextField
              id='description'
              label={this.props.translate('sharedDescription')}
              type='text'
              placeholder=''
              value={this.props.form.description}
              onChange={this.props.handleChange('description')}
              variant='outlined'
              margin='dense'
              fullWidth
              InputProps={{
                readOnly:
                  !this.props.updateOption || checkPrivileges('userUpdate')
                    ? false
                    : true
              }}
            />
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <TextField
              id='email'
              error={this.props.emailPattern}
              label={this.props.translate('accountEmail')}
              type='email'
              placeholder=''
              value={this.props.form.email}
              onChange={this.props.handleChange('email')}
              variant='outlined'
              margin='dense'
              fullWidth
              required
              InputProps={{
                readOnly:
                  !this.props.updateOption || checkPrivileges('userUpdate')
                    ? false
                    : true
              }}
            />
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <TextField
              id='contact'
              label={this.props.translate('contact')}
              type='text'
              placeholder=''
              value={this.props.form.contact}
              onChange={this.props.handleChange('contact')}
              variant='outlined'
              margin='dense'
              fullWidth
              InputProps={{
                readOnly:
                  !this.props.updateOption || checkPrivileges('userUpdate')
                    ? false
                    : true
              }}
            />
          </Grid>


       


          {/* <Grid item md={3} sm={6} xs={12}>
            <TextField
              id='measurementStandard'
              select
              label={this.props.translate('measurementStandard')}
              value={
                (this.props.form.attributes &&
                  this.props.form.attributes.measurementStandard) ||
                ''
              }
              onChange={e =>
                this.props.changeAttribute2('measurementStandard', e)
              }
              SelectProps={{
                MenuProps: {
                  className: classes.menu
                }
              }}
              margin='dense'
              fullWidth
              InputProps={{
                readOnly:
                  !this.props.updateOption || checkPrivileges('userUpdate')
                    ? false
                    : true
              }}
            >
              {measurementStandard.map(option => (
                <MenuItem key={option.key} value={option.key}>
                  {this.props.translate(option.value)}
                </MenuItem>
              ))}
            </TextField>
          </Grid> */}
        </Grid>
        {/* {(this.props.addAccount && this.props.logInUser.userType === -1) &&
          <> <h4>{this.props.translate('account') + ' ' + this.props.translate('sharedAttributes')}</h4>
            <Grid container spacing={2}>
              <Grid
                item md={3} sm={6} xs={12}
              >
                <TextField
                  id='serviceTitle'
                  label={this.props.translate('serviceTitle')}
                  type='text'
                  placeholder=''
                  value={this.props.form.attributes.serviceTitle || ''}
                  onChange={e => this.props.changeAttribute2('serviceTitle', e)}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                  InputProps={{
                    readOnly: this.props.logInUser.userType === -1 ? false : true
                  }}
                />
              </Grid>
              <Grid
                item md={3} sm={6} xs={12}
              >
                <TextField
                  id='footerText'
                  label={this.props.translate('footerText')}
                  type='text'
                  placeholder=''
                  value={this.props.form.attributes.footerText || ''}
                  onChange={e => this.props.changeAttribute2('footerText', e)}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                  InputProps={{
                    readOnly: this.props.logInUser.userType === -1 ? false : true
                  }}
                />
              </Grid>
              <Grid
                item md={3} sm={6} xs={12}
              >
                <TextField
                  id='privacyPolicyLink'
                  label={this.props.translate('privacyPolicyLink')}
                  type='text'
                  placeholder=''
                  value={this.props.form.attributes.privacyPolicyLink || ''}
                  onChange={e => this.props.changeAttribute2('privacyPolicyLink', e)}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                  InputProps={{
                    readOnly: this.props.logInUser.userType === -1 ? false : true
                  }}
                />
              </Grid>
              <Grid
                item md={3} sm={6} xs={12}
              >
                <TextField
                  id='termsLink'
                  label={this.props.translate('termsLink')}
                  type='text'
                  placeholder=''
                  value={this.props.form.attributes.termsLink || ''}
                  onChange={e => this.props.changeAttribute2('termsLink', e)}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                  InputProps={{
                    readOnly: this.props.logInUser.userType === -1 ? false : true
                  }}
                />
              </Grid>
              <Grid
                item md={3} sm={6} xs={12}
              >
                <TextField
                  id='hostUrl'
                  label={this.props.translate('hostUrl')}
                  type='text'
                  placeholder=''
                  value={this.props.form.attributes.hostUrl || ''}
                  onChange={e => this.props.changeAttribute2('hostUrl', e)}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                  InputProps={{
                    readOnly: this.props.logInUser.userType === -1 ? false : true
                  }}
                />
              </Grid>
              <Grid
                item md={3} sm={6} xs={12}
              >
                <TextField
                  id='adminUrl'
                  label={this.props.translate('adminUrl')}
                  type='text'
                  placeholder=''
                  value={this.props.form.attributes.adminUrl || ''}
                  onChange={e => this.props.changeAttribute2('adminUrl', e)}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                  InputProps={{
                    readOnly: this.props.logInUser.userType === -1 ? false : true
                  }}
                />
              </Grid>
              <Grid
                item md={3} sm={6} xs={12}
              >
                <TextField
                  id='serverIp'
                  label={this.props.translate('serverIp')}
                  type='text'
                  placeholder=''
                  value={this.props.form.attributes.serverIp || ''}
                  onChange={e => this.props.changeAttribute2('serverIp', e)}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                  InputProps={{
                    readOnly: this.props.logInUser.userType === -1 ? false : true
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid
                item md={3} sm={6} xs={12}
              >
                <input
                  type='file'
                  name='pic'
                  alt='logo'
                  accept='image/*'
                  onChange={e => this.props.uploadImage('logo', e)}
                ></input>
                <TextField
                  type='text'
                  helperText={this.props.translate('logoSuggestion')}
                  //placeholder=""
                  value={this.props.form.attributes.logo && this.props.form.attributes.logo.name || ''}
                  //error={this.props.vaildelogoFile}
                  // onChange={(e) => this.handleChange('logo', e)}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                  InputProps={{
                    readOnly: this.props.logInUser.userType === -1 ? false : true
                  }}
                />
              </Grid>
              <Grid
                item md={3} sm={6} xs={12}
              >
                <input
                  type='file'
                  name='pic'
                  alt='favIcon'
                  accept='image/*'
                  onChange={e => this.props.uploadImage('favIcon', e)}
                ></input>
                <TextField
                  helperText={this.props.translate('favIconSuggestion')}
                  type='text'
                  // error={this.props.vaildefavIconFile}
                  value={this.props.form.attributes.favIcon && this.props.form.attributes.favIcon.name || ''}
                  // onChange={(e) => this.handleChange('favIcon', e)}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                  InputProps={{
                    readOnly: this.props.logInUser.userType === -1 ? false : true
                  }}
                />
              </Grid>
              <Grid
                item md={3} sm={6} xs={12}
              >
                <input
                  type='file'
                  name='pic'
                  alt='logInPageImage'
                  accept='image/*'
                  onChange={e => this.props.uploadImage('logInPageImage', e)}
                ></input>
                <TextField
                  helperText={this.props.translate('logInPageImageSuggestion')}
                  type='text'
                  //error={this.state.vaildeLogInPageImageFile}
                  value={this.props.form.attributes.logInPageImage && this.props.form.attributes.logInPageImage.name || ''}
                  // onChange={(e) => this.handleChange('logInPageImage', e)}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                  InputProps={{
                    readOnly: this.props.logInUser.userType === -1 ? false : true
                  }}
                />
              </Grid>
            </Grid></>} */}
        {!this.props.updateOption && (
          <>
            <Divider style={{ marginTop: 20 }} />
            <h4>{this.props.translate('usersDetails')}</h4>
            {userSPsection(this.props, userTypeArray, this.state, this.toggleRoles)}
          </>
        )}
      </>
    )
    return (
      <div>
        <SimpleModalWrapped
          {...this.props}
          visable={true}
          isButtonVisable={true}
          title={this.props.translate('account')}
          formSubmit={this.props.formSubmit}
          isNotShowAttributesBtn={true}
          addAttributes={this.addAttributes}
          notVisableBackbtn={this.props.notVisableBackbtn}
          notVisableEnternalAddBtn={this.props.notVisableEnternalAddBtn}
          modalControle={this.props.modalControle}
          btnclosetext={this.props.translate('sharedBack')}
          content={Content}
          buttonText={
            !this.props.updateOption || checkPrivileges('userUpdate')
              ? this.props.buttonText
              : ''
          }
          isVisableBtn={this.props.isVisableBtn}
          attributeChangesMessage={this.props.attributeChangesMessage}
        />

        {this.state.attm_isVisable ? (
          <Attributes
            onCloseModal={this.addAttributes}
            itemAttributes={this.props.itemAttributes}
            changeAttribute={this.props.changeAttribute}
            formSubmit={this.ud_submit}
            componentType='account'
            hasAccessOfUpdate={this.props.hasAccessOfUpdate}
            hasAccessOfDelete={this.props.hasAccessOfDelete}
            hasAccessOfCreate={this.props.hasAccessOfCreate}
          />
        ) : null}
      </div>
    )
  }
}
const AddAccountModal = withStyles(styles)
export default AddAccountModal(withTranslationWrapper(addAccountModal))

const userSPsection = (props, userTypeArray, state,toggleRoles) => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={3} sm={6} xs={12}>
          <TextField
            id='displayName'
            label={props.translate('displayName')}
            type='text'
            placeholder=''
            value={props.spForm.name}
            onChange={props.handleChangeForSP('name')}
            variant='outlined'
            margin='dense'
            fullWidth
            required
            InputProps={{
              readOnly: (props && props.readOnly) || false
            }}
          />
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <TextField
            id='userEmail'
            error={props.duplicateEntry || props.spEmailPattern}
            label={props.translate('userEmail')}
            type='text'
            placeholder=''
            value={props.spForm.email}
            onChange={props.handleChangeForSP('email')}
            variant='outlined'
            margin='dense'
            fullWidth
            required
            InputProps={{
              readOnly: (props && props.readOnly) || false
            }}
          />
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <TextField
            id='userPassword'
            label={props.translate('userPassword')}
            type='password'
            placeholder=''
            value={props.spForm.password}
            onChange={props.handleChangeForSP('password')}
            variant='outlined'
            margin='dense'
            fullWidth
            required
            InputProps={{
              readOnly: (props && props.readOnly) || false
            }}
          />
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <TextField
            id='confirmPassword'
            label={props.translate('confirmPassword')}
            type='password'
            error={
              props.spForm.password === props.spForm.confirmPassword
                ? false
                : true
            }
            placeholder=''
            value={props.spForm.confirmPassword}
            onChange={props.handleChangeForSP('confirmPassword')}
            variant='outlined'
            margin='dense'
            fullWidth
            required
          />
        </Grid>
        {(
          <Grid item md={3} sm={6} xs={12}>
            <TextField
              id='userType'
              select
              label={props.translate('userType')}
              value={props.spForm.userType || ''}
              onChange={props.handleChangeForSP('userType')}
              margin='dense'
              fullWidth
              required
              InputProps={{
                readOnly: (props && props.readOnly) || false
              }}
            >
              {userTypeArray.length &&
                userTypeArray.map(option => (
                  <MenuItem key={option.key} value={option.key}>
                    {props.translate(option.name)}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>
        )}
           {!state.showButton && props.roleTypes.length ?  (
                  <Grid item md={3} sm={6} xs={12} style={{marginTop:'13px'}}>
                      <span onClick={(e)=>toggleRoles(e)} style={{cursor: 'pointer'}}>
                       <b>Select Custom Role</b></span>
                  </Grid>
            ): ''}
            
          {checkPrivileges('role') && state.showButton && (
            <Grid item md={3} sm={6} xs={12}>
                <TextField
                    id='role'
                    select
                    label={props.translate('Role')}
                    value={props.spForm.roleId || ''}
                    onChange={props.handleChangeForSP('roleId')}
                    margin='dense'
                    fullWidth
                  >
                  <MenuItem key={'defaultRole'} value={'defaultRole'}> <em>{props.translate('default')}</em></MenuItem>
                    <Divider style={{background:'black'}}/>
                    {props.roleTypes.length ?
                      props.roleTypes.map(option => (
                        <MenuItem key={option.id} value={option.id}>
                          {props.translate(option.name)}
                        </MenuItem>
                      )) : <MenuItem value=''>
                      <em>{props.translate('none')}</em>
                    </MenuItem>}
                </TextField>
                <span onClick={(e)=>toggleRoles(e) } style={{cursor: 'pointer'}}><b>Use Default Role</b></span>

            </Grid>
             )}
      </Grid>
      <h4>{props.translate('sharedPreferences')}</h4>
      <Grid container spacing={2}>
        <Grid item md={3} sm={6} xs={12}>
          <TextField
            id='phone'
            label={props.translate('sharedPhone')}
            type='text'
            placeholder=''
            value={props.spForm.phone}
            onChange={props.handleChangeForSP('phone')}
            variant='outlined'
            margin='dense'
            fullWidth
          />
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <TextField
            id='map'
            select
            label={props.translate('mapTitle')}
            value={props.spForm.map || ''}
            onChange={props.handleChangeForSP('map')}
            SelectProps={{
              MenuProps: {
                className: props.classes.menu
              }
            }}
            margin='dense'
            fullWidth
          >
            <MenuItem value=''>
              <em>{props.translate('none')}</em>
            </MenuItem>
            {mapLayerTypes.map(option => (
              <MenuItem key={option.key} value={option.key}>
                {props.translate(option.name)}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <TextField
            id='coordinateFormat'
            select
            label={props.translate('CoordinateFormat')}
            value={props.spForm.coordinateFormat || ''}
            onChange={props.handleChangeForSP('coordinateFormat')}
            SelectProps={{
              MenuProps: {
                className: props.classes.menu
              }
            }}
            margin='dense'
            fullWidth
          >
            <MenuItem value=''>
              <em>{props.translate('none')}</em>
            </MenuItem>
            {coordinateFormatTypes.map(option => (
              <MenuItem key={option.key} value={option.key}>
                {props.translate(option.name)}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
      <h4>{props.translate('sharedPermissions')}</h4>
      <Grid container spacing={2}>
        {/* <Grid item md={3} sm={6} xs={12}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              error={false}
              margin='dense'
              label={props.translate('userExpirationTime')}
              variant='outlined'
              fullWidth
              minDate={new Date()}
              value={
                props.spForm.expirationTime
                  ? new Date(`${props.spForm.expirationTime}`).toString()
                  : null
              }
              onChange={props.handleChangeForSP('expirationTime')}
              classes={props.classes}
              InputProps={{
                classes: {
                  root: props.classes.cssOutlinedInput,
                  input: props.classes.input,
                  focused: props.classes.cssFocused,
                  notchedOutline: props.classes.notchedOutline
                },
                endAdornment: (
                  <InputAdornment position='end'>
                    <Tooltip title='Set No Limit'>
                      <IconButton onClick={e => props.setExpirationTime(e)}>
                        {<CrossIcons />}
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                )
              }}
              InputLabelProps={{
                classes: {
                  root: props.classes.label,
                  focused: props.classes.focused,
                  shrink: props.classes.shrink
                }
              }}
            />
          </MuiPickersUtilsProvider>
          {props.spForm.expirationTime && (
            <Tooltip title='Set No Limit'>
              <a
                href='javascript:void(0)'
                onClick={e => props.setExpirationTime(e)}
              >
                no limit
              </a>
            </Tooltip>
          )}
        </Grid> */}
        <Grid item md={3} sm={6} xs={12}>
          <TextField
            error={props.deviceLimitError}
            id='deviceLimit'
            label={props.translate('userDeviceLimit')}
            type='number'
            placeholder=''
            value={props.spForm.deviceLimit}
            onChange={props.handleChangeForSP('deviceLimit')}
            variant='outlined'
            margin='dense'
            fullWidth
            helperText={
              props.deviceLimitError
                ? props.translate(props.unitsLimitsMeaasge)
                : ''
            }
          />
        </Grid>
        {props.spForm.userType !== 1 && (
          <Grid item md={3} sm={6} xs={12}>
            <TextField
              id='userLimit'
              error={props.userLimitError}
              label={props.translate('userUserLimit')}
              type='number'
              placeholder=''
              value={props.spForm.userLimit}
              onChange={props.handleChangeForSP('userLimit')}
              variant='outlined'
              margin='dense'
              fullWidth
              helperText={
                props.userLimitError
                  ? props.translate(props.usersLimitsMeaasge)
                  : ''
              }
            />
          </Grid>
        )}
        <Grid item md={3} sm={6} xs={12}>
          <TextField
            label={props.translate('userToken')}
            value={props.spForm.token || ''}
            onChange={props.handleChangeForSP('Token')}
            variant='outlined'
            margin='dense'
            fullWidth
            InputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment position='end'>
                  <Tooltip title={props.translate('getToken')}>
                    <IconButton onClick={props.generateToken}>
                      {<RefreshIcon />}
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              )
            }}
          />
          {props.spForm.token && (
            <Tooltip title={props.translate('setNoToken')}>
              <a onClick={e => props.setNoToken(e)}>
                {props.translate('noToken')}
              </a>
            </Tooltip>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        {/* <Grid item md={3} sm={6} xs={12}>
          <Checkbox
            checked={props.spForm.disabled}
            onChange={props.handleChangeForSP('disabled')}
            value='disabled'
            color='primary'
            label={props.translate('sharedDisabled')}
          />
        </Grid> */}

                              {/* --------------temprary comment ------------- */}
        {/* {props.spForm.userType === 4 && (
          <Grid item md={3} sm={6} xs={12}>
            <Checkbox
              checked={
                (props.spForm.attributes &&
                  props.spForm.attributes.hideModal) ||
                false
              }
              onChange={e => props.changeAttribute2ForSP('hideModal', e)}
              value='hideModal'
              color='primary'
              label={props.translate('hideModal')}
            />
          </Grid>
        )} */}


        {/* {isOwnerOrDealer(props.userType) &&
                <Grid item md={3} sm={6} xs={12}>
                    <Checkbox
                        checked={props.spForm.administrator}
                        onChange={props.handleChangeForSP('administrator')}
                        value="administrator"
                        color="primary"
                        label={props.translate('userAdmin')}
                    />
                </Grid>} */}
        {/* <Grid item md={3} sm={6} xs={12}>
                <Checkbox
                    checked={props.spForm.readonly}
                    onChange={props.handleChangeForSP('readonly')}
                    value="readonly"
                    color="primary"
                    label={props.translate('serverReadonly')}
                />
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
                <Checkbox
                    checked={props.spForm.deviceReadonly}
                    onChange={props.handleChangeForSP('deviceReadonly')}
                    value="deviceReadonly"
                    color="primary"
                    label={props.translate('userDeviceReadonly')}
                />
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
                <Checkbox
                    checked={props.spForm.limitCommands}
                    onChange={props.handleChangeForSP('limitCommands')}
                    value="limitCommands"
                    color="primary"
                    label={props.translate('userLimitCommands')}
                />
            </Grid> */}
      </Grid>
    </>
  )
}

import React, { Component } from 'react'
import { connect } from 'react-redux'
import withTranslationWrapper from '../../HOC/HocTranslate'
import { notifyError, notifySuccess } from '../../Utils/CustomNotifcations'
import Layout from './../../wrapper'
import { logInUserInfo } from '../../Actions/Users'
import { checkPrivileges } from '../../Helpers'
import { ViewAccountDetail } from '../../Components/Accounts/accountDetail/viewAccountDetail'
import withRoutes from '../../HOC/HocRoutes'

class AccountSettings extends Component {
  constructor (props) {
    super(props)
    this.state = {
      sessionCallTrack: true
    }
  }
  componentWillMount () {
        // this.props.dispatch(Notifications.removeAll())

  }
  controleSession = () => {
    if (localStorage.getItem('userInfo')) {
      fetch('/api/session', {
        method: 'DELETE'
      }).then(res => {
        let response = res.data
        if (response.ok) {
          this.props.navigate('/logout')
        }else{
          throw response
        }
      }).catch(e => {})
    }
  }

  render () {
    // if (checkPrivileges('account')) {
    //     if (this.props.logInUser.userType === -1) {
      if (this.props.logInUser ) {
        if (this.props.logInUser.userType === -1) {
          if (checkPrivileges('account')) {
          return (
             <Layout {...this.props}>
                <ViewAccountDetail
                    selectItemName={this.props.logInUser && this.props.logInUser.name}
                    selectItemId={this.props.logInUser && this.props.logInUser.accountId}
                    getMoreAccounts={this.getMoreAccounts}
                    AccountSettings
                    onClose={this.onCloseC_Modal}
                    roles={this.props.roles}
                />
            </Layout>
          )}}else{
                  this.props.navigate('/users')
               return null
          }
        } else {
          fetch('/api/session?app=2bd5bd10345cd1555b14c221ec6e8647c46ee21e8ee45312', {
            headers: { Accept: 'application/json; odata=verbose' }
          }).then(response => {
            if (response.ok) {
              response.json().then(res => {
                if(res.status ==='success'){ 
                  let userInfo = res.data
                  this.props.setActiveLanguage(userInfo?.attributes?.lang || 'en')
                  this.props.dispatch(logInUserInfo(userInfo))
                }
                else if(res?.statusCode === '440'){
                  window.location.replace('/login')
                }
                else if(res.statusCode){
                  var err = res?.message.split(':')
                  err[1] =err[1].replace(')', "")
                  this.props.dispatch(
                    notifyError(err[1])
                  )
                   this.props.navigate('/logout')
                }
              })
            } else {
              this.props.navigate('/logout')
                throw response
              }
            })
            .catch(e => {
              this.props.dispatch(notifyError('somethingWentWrong'))
            })
          return null
        }
    //          } else {
    //             this.props.navigate('/users')
    //                 return null
    //             }
  
    // } 
  }
}
const mapStateToProps = state => ({
  ServerSetting: state.ServerSetting,
  logInUser: state.logInUsers,
  roles: state.roles,
})

export default connect(mapStateToProps)(withRoutes(withTranslationWrapper(AccountSettings)))

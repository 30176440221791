import Grid from '@mui/material/Grid'
import React from 'react'
// import { Translate, withLocalize } from 'react-localize-redux'
import Button from '../common/Button'
import TextField from '../common/TextField'
import 'moment/moment.js'
import { updateAccount } from '../../Actions/Accounts'
import { ServerInfo } from '../../Actions/serverSetting'
// import Notifications, { success } from 'react-notification-system-redux'
import axios from 'axios'
import Switch from '../common/Switch'
import { FormControlLabel } from '@mui/material'
import Wasl from './wasl'
import { Api } from '../../Helpers'
import MenuItem from '@mui/material/MenuItem'
import withTranslationWrapper from '../../HOC/HocTranslate'
import { notifyError, notifySuccess } from '../../Utils/CustomNotifcations'
import { Trans as Translate} from 'react-i18next';

class WaslComp extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            waslEnabled: props?.selectedItem?.attributes?.waslEnabled || false,
            waslApiKey: "",
            waslApiKeyError: false,
            registrationType:'company'
        }
    }
    onChange = (event, name) => {
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value
        this.setState({
            [name]: value
        })
    }

    submit = (type, data) => {
        if(this.props.selectedItem){
            let obj = {
                ...this.props.selectedItem,
              attributes: {
                  ...this.props.selectedItem.attributes,
                  ...data
              }
            }
        
        if(type === 'key') {
            obj.attributes.waslApiKey = true;
            axios.post(`${Api}updateWaslApiKey`, { waslApiKey: data.waslApiKey, account_id: this.props.selectedItem.id }).then(response => {
                console.log(response);
                this.setState({ waslApiKey: '' })
            }).catch(error => {
                console.log(error);
            })
        }

        // delete obj.attributes.waslApiKey
        // delete obj.attributes.waslEnabled
        // delete obj.attributes.waslCompanyRefKey



        fetch(`/api/accounts/${this.props.selectedItem.id}`, {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                ...obj
            })
        })
        .then(response => {
            if (response.ok) {
                response.json().then(res => {
                    let account = res.data
                    if(res.status === 'success'){
                        this.props.UpdateSelectedItem(account)
                        this.props.dispatch(updateAccount(account))
                        this.props.dispatch(
                            notifySuccess('updatedSuccessfully')
                        )
                        this.setState({
                            form: { ...account }
                        })
                        if(this.props.selectedItem.id === 1){
                            this.props.dispatch(ServerInfo(account))
                        }
                    }
                    else if(res?.statusCode === '440'){
                        window.location.replace('/login')
                    }
                    else if(res.statusCode){
                        var err = res?.message.split(':')
                        err[1] =err[1].replace(')', "")
                        this.props.dispatch(
                          notifyError(this.props.translate(err[1]))
                        )
                      }
                })
           
            } else {
                throw response
            }

        })
        .catch(e => {
            if(e && e.text){
            e.text().then(err => {
                if (err.includes('Duplicate entry')) {
                this.setState({
                    duplicateEntry: true,
                    isVisableUserBtn: false
                })
                this.props.dispatch(
                    notifyError('userEmailIsAllreadyInUsed')
                )
                } else {
                this.props.dispatch(
                    notifyError('someThing went wrong')
                )
                }
            })
            }
        })
    }
}
      

    
    checkValid = (a,b) => {
        this.setState({
            [a.target.id+'Error']: !a.target.value
        })
    }
    
    submitCompany = () => {

        const { waslApiKey } = this.state

        if(waslApiKey) {
            this.submit('key', {
                waslApiKey: waslApiKey
            })
        }
        else {
            this.setState({
                waslApiKeyError: !waslApiKey
            })
        }
    }

    submitIntegration = () => {
        if(this.props.selectedItem){
        const { waslEnabled } = this.state

        this.submit('integration',{
            waslEnabled: waslEnabled
        })
        axios.post(`${Api}register`, { activate: waslEnabled, accounts: [this.props.selectedItem.id] }).then(response => {
            console.log(response);
        }).catch(error => {
            console.log(error);
        })
        axios.get(`${Api}refreshCache`).then(response => {
            console.log(response);
        }).catch(error => {
            console.log(error);
        })
    }
}

    render() {
        const { waslApiKeyError } = this.state
        return <div>
            <h4 className="mt-0">Wasl Integration</h4>
            <FormControlLabel
                style={{ marginLeft: 'auto' }}
                control={
                <Switch
                    checked={this.state.waslEnabled}
                    onChange={(e) => this.onChange(e, 'waslEnabled')}
                />
                }
                label={<div><span style={{ marginRight: 16 }}>Integration Status:</span>  
                    <strong>{this.state.waslEnabled
                        ? <Translate i18nKey='commandEnable' />
                        : <Translate i18nKey='disabled' />}</strong>
                        </div>
                }
                labelPlacement='start'
                classes={{ label: 'color-inherit' }}
            />
            <p>
                <Button onClick={this.submitIntegration}>Save</Button>
            </p>
            {this.props?.selectedItem?.attributes?.waslEnabled ? <>
            <hr />
            <h4 className="mt-0">Wasl API Key</h4>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3}>
                    <TextField onBlur={this.checkValid} fullWidth  error={waslApiKeyError} id="waslApiKey" value={this.state.waslApiKey} label={<Translate i18nKey="waslApiKey" />} onChange={e => this.onChange(e, 'waslApiKey')} />
                </Grid>
                <Grid item xs={12}>
                    <Button onClick={this.submitCompany}>Save</Button>
                </Grid>
            </Grid>
            <hr />
            <h4 className="mt-0">Registration Type</h4>
           
            <Grid item md={3} sm={6} xs={12}>
              <TextField
                id='registration'
                select
                label={'Registration'}
                value={this.state.registrationType}
                onChange={(e) => this.onChange(e, 'registrationType')}   
                margin='dense'
                fullWidth
              >
                <MenuItem value='company'>Company</MenuItem>
                <MenuItem value='individual'>Individual</MenuItem>
              </TextField>
            </Grid>
            <hr />
            <Wasl {...this.props} registrationType={this.state.registrationType}/>
            </> : null}
        </div>
    }
}
export const WaslApi = withTranslationWrapper(WaslComp)
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Layout from '../Layout'
import MainSideBar from './MainSideBar'
import './index.scss'
import {sideBar} from '../Actions/SideBar'
import { logInUserInfo } from '../Actions/Users'
// import { notifyError, notifySuccess } from '../../Utils/CustomNotifcations'

class Wrapper extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: true,
      open: this.props.sideBar || false
    }
  }

  // UNSAFE_componentWillMount () {
  //   if (!this.props.logInUser) {
  //     fetch('/api/session', {
  //       headers: { Accept: 'application/json; odata=verbose' }
  //     }).then(response => {
  //       if (response.ok) {
  //         response.json().then(res => {
  //           if(res.status ==='success'){ 
  //             let userInfo = res.data
  //             this.props.setActiveLanguage(userInfo?.attributes?.lang || 'en')
  //             this.props.dispatch(logInUserInfo(userInfo))
  //           }
  // else if(res?.statusCode === '440'){
  //   window.location.replace('/login')
  // }
  //           else if(res.statusCode){
  //             var err = res?.message.split(':')
  //             err[1] =err[1].replace(')', "")
  //             this.props.dispatch(
  //               notifyError({
  //                 message: this.props.translate(err[1]),
  //                 autoDismiss: 5,
  //                 location: this.props.location
  //               })
  //             )
  //             window.location.replace('/login')
  //           }
  //         })
  //       } else {
  //         window.location.replace('/login')
  //           throw response
  //         }
  //       })
  //       .catch(e => {
  //         this.props.dispatch(notifyError({
  //           message: 'somethingWentWrong',
  //           autoDismiss: 5
  //         }))
  //       })
  //   }
  //   else {
  //     this.setState({ loading: false })
  //   }
  
  // }

  handleDrawer= () =>{
    this.setState({
      open: !this.state.open
    }, ()=>{
      this.props.dispatch(sideBar(this.state.open))
    })
  }
   render () {
    return (<>
      <MainSideBar {...this.props} userInfo={this.props.logInUser} sidebarToggle={this.state.open} />
      <Layout {...this.props} {...this.state} small sidebarToggle={this.state.open} handleDrawer={this.handleDrawer}/>
    </>)
  }
}
const mapState = state => ({
  logInUser: state.logInUsers,
  deviceLimit: state.deviceLimit,
  sideBar: state.sideBar
})

const mapStateToProps = connect(mapState)

export default mapStateToProps(Wrapper)

import React, { Component } from 'react'
import withTranslationWrapper from '../../HOC/HocTranslate'
import SimpleModalWrapped from '../common/Modal'
import TextField from '../common/TextField'
import { withStyles } from '@mui/styles'
import Grid from '@mui/material/Grid'
import MenuItem from '@mui/material/MenuItem'
import Attributes from '../Attributes/attributesModal'

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  dense: {
    marginTop: 19
  },
  menu: {
    width: 200
  },
  button: {
    margin: theme.spacing(1)
  }
})

class addResourceModal extends Component {
  constructor () {
    super()
    this.state = {
      attm_isVisable: false
    }
    this.addAttributes = this.addAttributes.bind(this)
  }
  addAttributes () {
    this.setState({
      attm_isVisable: !this.state.attm_isVisable
    })
  }

  render () {
    const Content = (
      <div>
        <Grid container spacing={4}>
          <Grid item md={3} sm={6} xs={12}>
            <TextField
              id='name'
              label={this.props.translate('sharedName')}
              type='text'
              placeholder=''
              value={this.props.form.name}
              onChange={this.props.handleChange('name')}
              variant='outlined'
              margin='dense'
              fullWidth
              required
            />
          </Grid>
          {/* <Grid item md={3} sm={6} xs={12}>
            <TextField
              id='groupId'
              select
              label={this.props.translate('groupDialog')}
              value={this.props.form.groupId}
              onChange={this.props.handleChange('groupId')}
              SelectProps={{
                MenuProps: {
                  className: classes.menu
                }
              }}
              margin='dense'
              fullWidth
            >
              <MenuItem value={0}>
                <em>{this.props.translate('none')}</em>
              </MenuItem>
              {group.map(option =>
                form.id !== option.id ? (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ) : null
              )}
            </TextField>
          </Grid> */}
        </Grid>
        <br />
      </div>
    )
    return (
      <div>
        <SimpleModalWrapped
          visable={true}
          isButtonVisable={true}
          title={this.props.translate('resource')}
          formSubmit={this.props.formSubmit}
          addAttributes={this.addAttributes}
          modalControle={this.props.onClose}
          btnclosetext={this.props.translate('sharedBack')}
          content={Content}
          buttonText={this.props.buttonText}
          // notVisableBackbtn={true}
          isVisableBtn={this.props.isVisableGroupBtn}
          attributeChangesMessage={this.props.attributeChangesMessage}
          notVisableBackbtn={this.props.notVisableBackbtn}
        />

      
      </div>
    )
  }
}
const AddResourceModal = withStyles(styles)
export default AddResourceModal(withTranslationWrapper(addResourceModal))

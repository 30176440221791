import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { notifyError, notifySuccess } from '../../Utils/CustomNotifcations'
 import { compose } from 'redux'
import TextField from './../common/TextField'
import { withStyles } from '@mui/styles'
import Checkbox from './../common/Checkbox'
import Grid from '@mui/material/Grid'
import Button from '../common/Button'
import isEqual from 'react-fast-compare'
import { ServerInfo } from '../../Actions/serverSetting'
import Strings from './../../lang/en.json'
import { checkPrivileges } from '../../Helpers'
import Loader from './../../Layout/Loader'
import withTranslationWrapper from '../../HOC/HocTranslate'

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },

  dense: {
    marginTop: 19
  },
  menu: {
    width: 200
  },
  button: {
    margin: theme.spacing(1)
  }
})

class EmailGatway extends Component {
  constructor () {
    super()
    this.state = {
      selectedType: '',
      selectTagData: '',
      htmlTag: '',
      isVisableAddBtn: false,
      form: {},
      loading: false,
      isTested: false
    }
    this.handleChange = this.handleChange.bind(this)
    this.formSubmit = this.formSubmit.bind(this)
    this.formReset = this.formReset.bind(this)
  }
  componentWillMount () {
    if(this.props.ServerSetting){
      this.setState({
        form: { ...this.props.ServerSetting.attributes }
      })
    }
  }
  formReset () {
    this.setState(
      {
        form: {}
      },
      () => {
        let allAttributes = this.props.ServerSetting.attributes
        let value = Object.keys(allAttributes).reduce((array, key) => {
          return [...array, [key, allAttributes[key]]]
        }, [])
        value.map(item => {
          userAttributesTypes.filter(gr => {
            if (gr.key === item[0]) {
              this.setState(preState => ({
                ...preState,
                form: {
                  ...preState.form,
                  [item[0]]: item[1]
                },
                isVisableAddBtn: false
              }))
            }
            return null
          })
          return null
        })
      }
    )
  }
  formSubmit () {
    if(this.props.ServerSetting?.attributes){
    let allAttributes = this.props.ServerSetting.attributes
    let value = Object.keys(allAttributes).reduce((array, key) => {
      return [...array, [key, allAttributes[key]]]
    }, [])
    value.map((item, index) => {
      userAttributesTypes.map(gr => {
        if (gr.key === item[0]) delete value[index]
        return null
      })
      return null
    })

    let combineAtt = this.state.form

    value.map(att => {
      combineAtt = { ...combineAtt, [att[0]]: att[1] }
      return null
    })

    let { ServerSetting } = this.props
    ServerSetting.attributes = { ...combineAtt }
    fetch(`api/accounts/${ServerSetting.id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        ...ServerSetting
      })
    })
      .then(response => {
        if (response.ok) {
          response.json().then(res => {
            if(res.status ==='success'){
              let server = res.data
              if (this.props.updateOtherServerSetting) {
                // this.props.updateSelectedAccount(ServerSetting)
                this.props.updateSelectedAccount(server)
              } else {
                // this.props.dispatch(ServerInfo(ServerSetting))
                this.props.dispatch(ServerInfo(server))
              }
              this.props.dispatch(
                notifySuccess('defaultInfoIsUpdated'),
              )
            }
            else if(res?.statusCode === '440'){
              window.location.replace('/login')
            }
            else if(res.statusCode){
              var err = res?.message.split(':')
              err[1] =err[1].replace(')', "")
              this.props.dispatch(
                notifyError(err[1])
              )
            }
          })
          this.setState({
            isVisableAddBtn: false
          })
        } else {
          throw response
        }
      })
      .catch(e => {
        this.props.dispatch(
          notifyError('somthingWentWrongMessage')
        )
      })
  }
}

  testNotification = () => {
    let allAttributes = this.props.ServerSetting.attributes
    let value = Object.keys(allAttributes).reduce((array, key) => {
      return [...array, [key, allAttributes[key]]]
    }, [])
    value.map((item, index) => {
      userAttributesTypes.map(gr => {
        if (gr.key === item[0]) delete value[index]
      })
      return null
    })

    let combineAtt = this.state.form

    value.map(att => {
      combineAtt = { ...combineAtt, [att[0]]: att[1] }
      return null
    })
    this.setState(
      {
        loading: true
      },
      () => {
        fetch(`api/notifications/test/smtp`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            ...combineAtt
          })
        })
          .then(response => {
            if (response.ok) {
              response.json().then(data => {
                if (data.status === 'success') {
                  this.props.dispatch(
                    notifySuccess(
                        'notificationSuccessfullySend'
                      )
                  )
                  this.setState(
                    {
                      isTested: true
                    },
                    () => this.checkRequiredFields()
                  )
                } 
                else if(data?.statusCode === '440'){
                  window.location.replace('/login')
                }  
                else if(data.statusCode){
                  var err = data?.message.split(':')
                  err[1] =err[1].replace(')', "")
                  this.props.dispatch(
                    notifyError(this.props.translate(err[1]))
                  )
                }
              })
              this.setState({
                loading: false
              })
            } else {
              throw response
            }
          })
          .catch(e => {
            this.setState({
              loading: false
            })
            this.props.dispatch(
              notifyError('somthingWentWrongMessage')
            )
          })
      }
    )
  }
  handleChange (key, event) {
    if (event) {
      const { target } = event
      if (target) {
        this.setState({ isTested: true })
        let value = target.type === 'checkbox' ? target.checked : target.value
        if (value === false) {
          value = ''
        }
        if (target.type === 'number') {
          value = parseInt(value)
          if (value === 0 || isNaN(value)) {
            value = ''
          }
        }
        if (value === '') {
          if (this.state.form[key]) {
            delete this.state.form[key]
          }
          this.setState(
            {
              form: {
                ...this.state.form
              }
            },
            () => this.checkRequiredFields()
          )
        } else {
          this.setState(
            {
              form: {
                ...this.state.form,
                [key]: value
              }
            },
            () => this.checkRequiredFields()
          )
        }
      }
    }
  }
  checkRequiredFields = () => {
    let value = !isEqual(this.state.form, this.props.ServerSetting?.attributes)
    if (this.state.isTested && value) {
      this.setState({
        isVisableAddBtn: true
      })
    } else {
      this.setState({
        isVisableAddBtn: false
      })
    }
  }

  render () {
    const { form, loading } = this.state
    const renderFields = () => {
      let fieldArray = []
      userAttributesTypes.map(field => {
        if (field.htmlTag === 'input') {
          if (field.valueType === 'text' || field.valueType === 'string') {
            fieldArray.push(
              <Grid item md={this.props.updateOtherServerSetting ? 4 : 3}>
                <TextField
                  id={field.key}
                  label={this.props.translate(field.name)}
                  type='text'
                  placeholder=''
                  value={form[field.key] || ''}
                  onChange={this.handleChange.bind(this, `${field.key}`)}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                  InputProps={{
                    readOnly:
                      this.props.logInUser.userType === -1 ? false : true
                  }}
                />
              </Grid>
            )
          } else if (field.valueType === 'number') {
            fieldArray.push(
              <Grid item md={this.props.updateOtherServerSetting ? 4 : 3}>
                <TextField
                  id={field.key}
                  label={this.props.translate(field.name)}
                  type='number'
                  placeholder=''
                  value={form[field.key] || ''}
                  onChange={this.handleChange.bind(this, `${field.key}`)}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                  InputProps={{
                    readOnly:
                      this.props.logInUser.userType === -1 ? false : true
                  }}
                />
              </Grid>
            )
          } else if (field.valueType === 'boolean') {
            fieldArray.push(
              <Grid item md={this.props.updateOtherServerSetting ? 4 : 3}>
                <Checkbox
                  label={this.props.translate(field.name)}
                  checked={form[field.key] || false}
                  onChange={this.handleChange.bind(this, `${field.key}`)}
                  color='primary'
                  InputProps={{
                    readOnly:
                      this.props.logInUser.userType === -1 ? false : true
                  }}
                />
              </Grid>
            )
          }
        }
        return null
      })
      return (
        <Grid container spacing={2}>
          {fieldArray}
        </Grid>
      )
    }
    if (loading) {
      return <Loader defaultStyle />
    } else {
      return (
        <div>
          {renderFields(this.state.form)}
          <br />
          {checkPrivileges('userUpdate') &&
            this.props.logInUser.userType === -1 && (
              <Fragment>
                <Button
                  variant='contained'
                  onClick={this.formReset}
                  style={{ marginLeft: 8, marginRight: 8 }}
                >
                  {' '}
                  {this.props.translate('resetButton')}
                </Button>
                <Button
                  variant='contained'
                  disabled={!this.state.isVisableAddBtn}
                  onClick={() => this.formSubmit()}
                >
                  {' '}
                  {this.props.translate('sharedSave')}
                </Button>
                <Button
                  variant='contained'
                  onClick={this.testNotification}
                  style={{ float: 'right', marginLeft: 8, marginRight: 8 }}
                >
                  {' '}
                  {this.props.translate('testNotificationButton')}
                </Button>
              </Fragment>
            )}
        </div>
      )
    }
  }
}

const enhance = compose(withStyles(styles), connect())
export default withTranslationWrapper(enhance(EmailGatway))

const userAttributesTypes = [
  {
    key: 'mail.smtp.host',
    name: 'attributeMailSmtpHost',
    valueType: 'string',
    htmlTag: 'input'
  },
  {
    key: 'mail.smtp.port',
    name: 'attributeMailSmtpPort',
    valueType: 'number',
    allowDecimals: false,
    minValue: 1,
    maxValue: 65535,
    htmlTag: 'input'
  },
  {
    key: 'mail.smtp.ssl.trust',
    name: 'attributeMailSmtpSslTrust',
    valueType: 'text',
    htmlTag: 'input'
  },
  {
    key: 'mail.smtp.ssl.protocols',
    name: 'attributeMailSmtpSslProtocols',
    valueType: 'text',
    htmlTag: 'input'
  },
  {
    key: 'mail.smtp.from',
    name: 'attributeMailSmtpFrom',
    valueType: 'text',
    htmlTag: 'input'
  },
  {
    key: 'mail.smtp.username',
    name: 'attributeMailSmtpUsername',
    valueType: 'text',
    htmlTag: 'input'
  },
  {
    key: 'mail.smtp.password',
    name: 'attributeMailSmtpPassword',
    valueType: 'text',
    htmlTag: 'input'
  },
  {
    key: 'mail.smtp.starttls.enable',
    name: 'attributeMailSmtpStarttlsEnable',
    valueType: 'boolean',
    htmlTag: 'input'
  },
  {
    key: 'mail.smtp.starttls.required',
    name: 'attributeMailSmtpStarttlsRequired',
    valueType: 'boolean',
    htmlTag: 'input'
  },
  {
    key: 'mail.smtp.ssl.enable',
    name: 'attributeMailSmtpSslEnable',
    valueType: 'boolean',
    htmlTag: 'input'
  },
  {
    key: 'mail.smtp.auth',
    name: 'attributeMailSmtpAuth',
    valueType: 'boolean',
    htmlTag: 'input'
  }
]

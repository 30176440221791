import React, { Component, Fragment } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
 import { compose } from 'redux'
import { withStyles } from '@mui/styles'
import Table from '../../../common/tableWithBackEndPagination'
import {
  prepareUsers,
  checkUserRoles,
  prepareUserForLogin,
  PaginationConfig
} from '../../../../Helpers'
import LoginAsUser from '../../../../Helpers/loginAsUser'
import Loader from '../../../../Layout/Loader'
import { Grid, Tooltip } from '@mui/material'
import TextField from '../../../common/TextField'
import { notifyError } from '../../../../Utils/CustomNotifcations'


const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  dense: {
    marginTop: 19
  },
  menu: {
    width: 200
  },
  button: {
    margin: theme.spacing(1)
  }
})
let source
class AccountUser extends Component {
  constructor () {
    super()
    this.state = {
      usersReceived: false,
      selectedAccUsersList: [],
      pagination: { ...PaginationConfig, hasNext: '', pageSize: 5 },
      total: '',
      lastPage: '',
      noRecords: false
    }
  }
  componentWillMount () {
    this.getMoreUsers()
  }
  componentWillUnmount () {
    // if (source) {
    //   source.cancel()
    // }
  }
  getMoreUsers = () => {
    let { page, pageSize, itemSearch } = this.state.pagination
    let api = `/api/users/get?accountId=${this.props.selectItemId}&all=true&page=${page}&limit=${pageSize}&search=${itemSearch}`
    if (
      this.props.logInUser.userType === 6 ||
      this.props.logInUser.userType === '6'
    ) {
      api = `/api/users/get?accountId=${this.props.selectItemId}&page=${page}&limit=${pageSize}&search=${itemSearch}`
    }
    source = axios.CancelToken.source()
    axios
      .get(api, {
        cancelToken: source.token
      })                                                                                                                                                                                                       
      .then(res => {
        let response= res.data
        if (response.status === 'success') {
          if (
            response.data &&
            response.data.data &&
            response.data.data.length
          ) {
            let lastPage = response.data.total / pageSize
            let IsFloate = this.checkFloteNumber(lastPage)
            this.setState({
              pagination: {
                ...this.state.pagination,
                total: response.data.total,
                lastPage: IsFloate ? parseInt(lastPage + 1) : lastPage,
                total: response.data.total,
                hasNext: response.data.hasNext
              },
              selectedAccUsersList: prepareUserForLogin(
                checkUserRoles(
                  prepareUsers(response.data.data),
                  this.props.roles
                )
              ),
              usersReceived: true,
              noRecords:false,
            },()=>{
              if(this.props?.selectedUser?.id){
                const found = this.state.selectedAccUsersList.filter(el=>el.id !== this.props.selectedUser.id )
                this.setState({selectedAccUsersList:found})
              }
            })
          } else {
            this.setState({
              selectedAccUsersList: [],
              usersReceived:false,
              noRecords:true,
            })
          }
        }
        else if(response?.statusCode === '440'){
          window.location.replace('/login')
        }
        else if(response.statusCode){
          var err = response?.message.split(':')
          err[1] =err[1].replace(')', "")
          this.props.dispatch(
            notifyError(this.props.translate(err[1]))
          )
        }
      })
      .catch(e => {
        // if (axios.isCancel(e)) {
        //   console.log('Request canceled = ', e);
        // }
      })
  }
  checkFloteNumber (n) {
    return Number(n) === n && n % 1 !== 0
  }
  handleChangeRowsPerPage = value => {
    let { page, pageSize } = this.state.pagination
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          pageSize: value
        }
      },
      () => {
        if (page * pageSize >= value) {
          this.getMoreUsers()
        }
      }
    )
  }
  handleChangePage = value => {
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          page: value
        }
      },
      () => this.getMoreUsers()
    )
  }
  SearchItem = searchValue => {
    source.cancel()
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          page: 1,
          itemSearch: searchValue
        }
      },
      () => this.getMoreUsers()
    )
  }
  loginAsUser = data => {
    if (data[0].logInAsUserState) {
      this.setState(
        {
          userDataForLogin: data[0],
          isUserLogin: false
        },
        () => {
          this.setState({
            isUserLogin: true
          })
        }
      )
    }
  }
  loginAsUserhandleChange = (id, value) => {
    let { selectedAccUsersList } = this.state
    selectedAccUsersList.map(item => {
      if (item.id === id) {
        item.logInAsUserState = value
      }
    })
    this.setState({
      selectedAccUsersList
    })
  }

  render () {
    const { classes } = this.props
    return (
      <Fragment>
          <Grid container className="breadcrumb-row">
            <Grid item xs={12} sm={3}>
                   <h3 style={{margin: 0}}>{this.props.translate('users')}</h3>
                </Grid>
                   <Grid item xs={12} sm={9} style={{justifyContent: 'flex-end'}}>
                     <div style={{ marginRight: '20px', width: "25%" }}>
                       <TextField
                         fullWidth
                         label={this.props.translate('search')}
                        onChange={e => this.SearchItem(e.target.value)}
                      />
                      </div>
                  </Grid>
               </Grid>
      {this.state && this.state.usersReceived && !this.state.noRecords ? (<>
          <Table
              rows={this.state.selectedAccUsersList || []}
              pagination={this.state.pagination}
              handleChangeRowsPerPage={this.handleChangeRowsPerPage}
              handleChangePage={this.handleChangePage}
              loginAsUser={this.loginAsUser}
              loginAsUserhandleChange={this.loginAsUserhandleChange}
              rowsPerPage={10}
              ServerSetting={this.props.ServerSetting}
              isEditable={true}
              themecolors={this.props.themecolors}
              rowDefinition={rowDefinitionR(this.props.translate)}
          /></>
        )  :  
            !this.state.usersReceived && !this.state.noRecords ?
                (<div style={{ textAlign: 'center' }}>
                  <Loader defaultStyle />
              </div>)
           : null
       }
        {this.state.noRecords && (
              <div style={{ textAlign: 'center' }}>
                  <h4> {this.props.translate('noUserFound')}</h4>
              </div>
          )}

        {this.state.isUserLogin && (
          <LoginAsUser selectedUser={this.state.userDataForLogin} />
        )}
      </Fragment>
    )
  }
}

const enhance = compose(withStyles(styles), connect())

export default enhance(AccountUser)

const rowDefinitionR = translate => {
  if (localStorage.getItem('adminToken')) {
    return [
      {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: translate('sharedName')
      },
      {
        id: 'email',
        numeric: false,
        disablePadding: false,
        label: translate('userEmail')
      },
      {
        id: 'roleName',
        numeric: false,
        disablePadding: false,
        label: translate('roleName')
      },
      {
        id: 'parentName',
        numeric: false,
        disablePadding: false,
        label: translate('parent')
      }
    ]
  } else {
    return [
      {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: translate('sharedName')
      },
      {
        id: 'email',
        numeric: false,
        disablePadding: false,
        label: translate('userEmail')
      },
      {
        id: 'roleName',
        numeric: false,
        disablePadding: false,
        label: translate('roleName')
      },
      {
        id: 'parentName',
        numeric: false,
        disablePadding: false,
        label: translate('parent')
      },
      {
        id: 'loginAsUser',
        numeric: false,
        disablePadding: false,
        label: translate('loginAsUser')
      }
    ]
  }
}

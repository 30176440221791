import React, { Component} from 'react'
import { connect } from 'react-redux'
 import { compose } from 'redux'
import { withStyles } from '@mui/styles'
import Grid from '@mui/material/Grid'
import Switch from '@mui/material/Switch'
import Button from '../../../common/Button'
import Autocomplete from '../../../common/Autocomplete'
import Paper from '@mui/material/Paper'
import Loader from '../../../../Layout/Loader'
import { notifyError, notifySuccess } from '../../../../Utils/CustomNotifcations'

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  dense: {
    marginTop: 19
  },
  menu: {
    width: 200
  },
  button: {
    margin: theme.spacing(1)
  }
})

class Attendance extends Component {
  constructor () {
    super()
    this.state = {
      isRecived: false,
      selecteditem: '',
      editOption: false,
      form: {
        attentance: false,
        input: '',
        id: ''
      }
    }
    this.unitAttendancSubmit = this.unitAttendancSubmit.bind(this)
  }

  componentWillMount () {
    fetch(
      `/api/attributes/computed?deviceId=${this.props.selectItemId}&all=true`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }
    ).then(response => {
        response.json().then(res => {
          if(res.status ==='success'){
            let comp = res.data
            if (comp.length) {
              comp.map(att => {
                if (att.category === 3) {
                  this.setState({
                    form: {
                      attentance: true,
                      input: att.attributes.input,
                      id: att.id
                    },
                    editOption: true
                  })
                }
              })
            }
          }
          else if(res?.statusCode === '440'){
            window.location.replace('/login')
          }
          else if(res?.statusCode){
            var err = res?.message.split(':')
            err[1] =err[1].replace(')', "")
            this.props.dispatch(
              notifyError(this.props.translate(err[1]))
            )
          }
          else {
            throw response
          }
        })
        this.setState({
          isRecived: true
        })
      
    }).catch(e => {
          console.log('e =', e)
        //   this.props.dispatch(notifyError({
        //   message: 'somethingWentWrong',
        //   autoDismiss: 5
        // }))
      })
  }

  unitAttendancSubmit () {
    this.setState(
      {
        isRecived: false
      },
      () => {
        let { editOption, form } = this.state
        let option = 'POST'
        if (editOption) {
          option = 'PUT'
        }
        let obj = {
          id: editOption ? form.id : '',
          attributes: { input: form.input || 'iButton2' },
          description: 'Attendance marker',
          attribute: 'passengerId',
          expression: '',
          type: 'string',
          category: 3,
          parentId:this.props.selectItemParentId
        }
        fetch(`/api/sensors/${this.props.selectItemId}`, {
          method: `${option}`,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            ...obj
          })
        })
          .then(response => {
              response.json().then(res => {
                if(res.status === 'success'){
                  let comp = res.data
                  this.setState({
                    form: {
                      attentance: true,
                      input: comp?.attributes?.input,
                      id: comp?.id
                    },
                    editOption: true,
                    isRecived: true
                  })
                  if (option === 'POST') {
                    this.props.dispatch(
                      notifySuccess(this.props.translate('attributeIsCreate'))
                    )
                  } else {
                    this.props.dispatch(
                      notifySuccess(this.props.translate('attributeIsUpdate'))
                    )
                  }
                }
                else if(res?.statusCode === '440'){
                  window.location.replace('/login')
                }
                else if(res.statusCode){
                  var err = res?.message.split(':')
                  err[1] =err[1].replace(')', "")
                  this.props.dispatch(
                    notifyError(this.props.translate(err[1]))
                  )
                  this.setState({editOption:true,isRecived:true})
                }
                else {
                  throw response
                }
              })
             
          })
          .catch(e => {
            this.props.dispatch(notifyError('somethingWentWrong'))

            // if(e && e.text){
            //   e.text().then(err => {
            //     this.props.dispatch(
            //       notifyError({
            //         message: err,
            //         autoDismiss: 5,
            //         location: this.props.location
            //       })
            //     )
            //   })
            // }
          })
      }
    )
  }

  unitAttendanceDelete = () => {
    this.setState(
      {
        isRecived: false
      },
      () => {
        let { form } = this.state
        fetch(`/api/attributes/computed/${form.id}`, {
          method: `DELETE`,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          }
        })
          .then(response => {
            response.json().then(res => {
              if(res.status ==='success'){
                this.setState({
                  form: {
                    attendance: false,
                    input: '',
                    id: ''
                  },
                  editOption: false,
                  isRecived: true
                })
                this.props.dispatch(
                  notifySuccess(this.props.translate('attributeIsDelete'))
                )
              }
              else if(res?.statusCode === '440'){
                window.location.replace('/login')
              }
              else if(res.statusCode){
                var err = res?.message.split(':')
                err[1] =err[1].replace(')', "")
                this.props.dispatch(
                  notifyError(this.props.translate(err[1]))
                )
              }
              else {
                throw response
              }
            })
             
            
          })
          .catch(e => {
            this.props.dispatch(notifyError('somethingWentWrong'))
          // if(e && e.text){
          //   e.text().then(err => {
          //     this.props.dispatch(
          //       notifyError({
          //         message: err,
          //         autoDismiss: 5,
          //         location: this.props.location
          //       })
          //     )
          //   })
          // }
          })
      }
    )
  }

  handleChange = name => event => {
    const { target } = event
    let value = target.type === 'checkbox' ? target.checked : target.value
    if (name === 'attentance' && value === false && this.state.editOption) {
      // call delete
      this.unitAttendanceDelete()
    }
    this.setState({
      form: {
        ...this.state.form,
        [name]: value
      }
    })
  }

  changeAttribute = (name, value) => {
    this.setState({
      form: {
        ...this.state.form,
        [name]: value
      }
    })
  }

  render () {
    const { classes } = this.props
    const { form, isRecived } = this.state

    return (
      <div style={{ textAlign: 'center' }}>
        {!isRecived ? (
          <Loader defaultStyle />
        ) : (
          <Paper className={classes.paper + ' clearfix'}>
            <Grid container spacing={2}>
              <Grid
                item
                md={3}
                sm={6}
                xs={12}
                component='label'
                container
                alignItems='center'
                spacing={1}
              >
                <Grid item>{this.props.translate('off')}</Grid>
                <Grid item>
                  <Switch
                    value='attentance'
                    checked={form.attentance}
                    onChange={this.handleChange('attentance')}
                  />
                </Grid>
                <Grid item>{this.props.translate('on')}</Grid>
              </Grid>
              {form.attentance && (
                <Grid item md={3} sm={6} xs={12}>
                  <Autocomplete
                    translate={this.props.translate}
                    value={form.input || ''}
                    suggestions={this.props.suggestions}
                    handleChange={this.changeAttribute}
                    placeholder='iButton2'
                    fieldName='input'
                    label={this.props.translate('input')}
                    prefix='position'
                  />
                </Grid>
              )}
            </Grid>

            {form.attentance && (
              <>
                <div
                  className='clearfix'
                  style={{
                    borderTop: '2px outset',
                    paddingTop: 16,
                    marginTop: 20
                  }}
                />
                <Button
                  variant='contained'
                  className={classes.button}
                  style={{
                    float: 'right'
                  }}
                  onClick={() => this.unitAttendancSubmit()}
                >
                  {' '}
                  {this.props.translate('update')}
                </Button>
              </>
            )}
          </Paper>
        )}
      </div>
    )
  }
}

const enhance = compose(withStyles(styles), connect())

export default enhance(Attendance)

import React, { Component } from 'react'
import TextField from './TextField'
import Grid from '@mui/material/Grid'
import { CSVLink } from 'react-csv'
import axios from 'axios'
import  Button  from './Button'
import CustomDialog from './Dialog'
import { notifyError, notifySuccess } from '../../Utils/CustomNotifcations'

class ExportFile extends Component {
  state = {
    exportFileName: '',
    exportOption:false,
  }
  
  handleChange = e => {
    this.setState({ exportFileName: e.target.value })
  }
  closeModel = () => {
    this.setState({exportOption:false})
  } 
  downloadExcel = () =>{
    if(this.props.downloadType && this.props.downloadType === 'accounts'){
      this.setState({exportOption:true})
    }
    else {
      let api, Accept;
      api = `/api/${this.props.downloadType}/export?sample=false`;
      Accept = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        axios({
          method: 'GET',
          url: api,
          headers:{
            Accept: Accept
          },
          responseType: 'blob'
        })
        .then(response => {
          const a = document.createElement('a')
          const url = window.URL.createObjectURL(new Blob([response.data]))
          a.setAttribute('download', `${this.props.downloadType}.xlsx`);
          document.body.appendChild(a)
          a.href = url
          a.click()
        }
        )
        .catch(error => {
            console.log("er =",error);
            this.props.dispatch(
              notifyError(this.props.translate('somethingWentWrong'))
            )
        })
          // if(this.props.closedModel()){
          //     this.props.closedModel()
          // }
    }
    
       
    
  }
  render () {
    const {title, downloadType} = this.props
    return (<>
        <Button disabled ={!this.props.dataLength} color='inherit' size='small' style={{ marginRight: '20px' }} onClick={this.downloadExcel}>{title}</Button>  
        {this.state.exportOption && (
          <CustomDialog
            themeColors={this.props.themecolors}
            visable={false}
            maxWidth={'sm'}
            fullWidth
            title={this.props.translate('exportOption')}
            onClose={this.closeModel}
            isButtonVisable={false}
            draggable={true}
            bodyPadding={0}
          >
        <div>
        <Grid container spacing={4}>
          <Grid item md={6} sm={6} xs={12} style={{ marginLeft: 12 }}>
            <TextField
              id='name'
              label={this.props.translate('exportFileName')}
              type='text'
              placeholder=''
              value={this.state.exportFileName}
              onChange={e => this.handleChange(e)}
              variant='outlined'
              margin='dense'
              fullWidth
              required
            />
          </Grid>
        </Grid>
        <CSVLink
          data={this.props.data}
          filename={
            this.state.exportFileName
              ? this.state.exportFileName + '.csv'
              : this.props.downloadType
              ? this.props.downloadType + '.csv'
              : 'my-file.csv'
          }
          className='btn btn-primary'
          style={{
            marginLeft: 20,
            marginRight: '10%',
            textDecoration: 'underline'
          }}
          target='_blank'
          onClick={this.props && this.props.closedModel}
        >
          {this.props.translate('downloadInCsv')}
        </CSVLink>
        </div>    
        </CustomDialog> 
        )}
    </>)
  }
}
export default ExportFile

import React from 'react'
import { connect } from 'react-redux'
import { setCookie } from './index'
import { logout } from '../Actions/Users'
import { notifyError } from '../Utils/CustomNotifcations'
import withTranslationWrapper from '../HOC/HocTranslate'
import withRoutes from '../HOC/HocRoutes'

const userDataFormat = (data, extAttriubtes) => {
  return {
    administrator: data.userType === 1 ? false : true,
    attributes: extAttriubtes ? extAttriubtes : {},
    coordinateFormat: data.coordinateFormat ? data.coordinateFormat : '',
    deviceLimit: data.deviceLimit ? data.deviceLimit : 0,
    deviceReadonly:
      data.deviceReadonly === 'on' || data.deviceReadonly === true
        ? true
        : false,
    disabled: data.disabled === 'on' || data.disabled === true ? true : false,
    email: data.email,
    expirationTime: data.expirationTime ? data.expirationTime : null,
    id: data.id,
    latitude: data.latitude ? data.latitude : 0,
    limitCommands:
      data.limitCommands === 'on' || data.limitCommands === true ? true : false,
    login: data.login,
    longitude: data.longitude ? data.longitude : 0,
    map: data.map,
    name: data.name || data.userName,
    password: data.password,
    phone: data.phone,
    poiLayer: data.poiLayer,
    readonly: data.readonly === 'on' || data.readonly === true ? true : false,
    token: data.token,
    twelveHourFormat:
      data.twelveHourFormat === 'on' || data.twelveHourFormat === true
        ? true
        : false,
    userLimit: data.userLimit ? data.userLimit : 0,
    zoom: data.zoom ? data.zoom : 0,
    roleId: data.roleId || 0,
    userType: parseInt(data.userType),
    parentId: data.parentId || 0
  }
}

class LoginAsUser extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      userToken: '',
      adminToken: '',
      isMount: true,
      selectedUser: '',
      readyForLoginCall: false
    }
    this.checkLogInuserReq = this.checkLogInuserReq.bind(this)
    this.logInAsUser = this.logInAsUser.bind(this)
  }

  componentWillMount () {
    if (this.state.isMount) {
      fetch(`/api/users?id=${this.props.selectedUser.id}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }).then(response => {
        if (response.ok) {
          response.json().then(res => {
            if(res.status ==='success'){ 
              let user = res.data
              this.setState(
                {
                  selectedUser: user[0]
                },
                () => {
                  this.checkLogInuserReq()
                }
              )
            }
            else if(res?.statusCode === '440'){
              window.location.replace('/login')
            }
            else if(res.statusCode){
              var err = res?.message.split(':')
              err[1] =err[1].replace(')', "")
              this.props.dispatch(
                notifyError(err[1])
              )
            }
          })
        }else{
          throw response
        }
      })
       .catch(e => {
        console.log('e =',e)
        // this.props.dispatch(notifyError({
        //   message: 'somethingWentWrong',
        //   autoDismiss: 5
        // }))
      })
    }
  }

  componentWillUnmount () {
    this.setState({
      isMount: false
    })
  }

  checkLogInuserReq () {
    let { token } = this.state.selectedUser
    let adminToken = this.props.logInUser.token
    if (token) {
      this.setState({
        userToken: token
      })
    } else {
      const obj = userDataFormat(
        this.state.selectedUser,
        this.state.selectedUser.attributes
      )
      this.generateTokenForUser(obj, 'userToken')
    }
    if (adminToken) {
      this.setState({
        adminToken: adminToken
      })
    } else {
      this.generateTokenForUser(this.props.logInUser, 'adminToken')
    }
  }
  generateTokenForUser (item, type) {
    let token = ''
    let symbols =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    let i
    for (i = 0; i < 32; i++) {
      token += symbols.charAt(Math.floor(Math.random() * symbols.length))
    }
    item.token = token
    fetch(`/api/users/${item.id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        ...item
      })
    })
      .then(response => {
        if (response.ok) {
          response.json().then(res => {
            if(res.status ==='success'){ 
              this.setState({
                [type]: token
              })
            }
            else if(res?.statusCode === '440'){
              window.location.replace('/login')
            }
            else if(res.statusCode){
              var err = res?.message.split(':')
              err[1] =err[1].replace(')', "")
              this.props.dispatch(
                notifyError(err[1])
              )
              window.location.replace('/login')
            }
          })
        } else {
          throw response
        }
      })
      .catch(e => {
        this.props.dispatch(notifyError('somethingWentWrong'))
      })
    return token
  }
  handleLogout () {
    fetch('/api/session', {
      method: 'DELETE'
    })
  }
  // shouldComponentUpdate () {
  //   return false
  // }

  logInAsUser () {
    let { selectedUser } = this.props
    let { userToken, adminToken } = this.state
    if (userToken && adminToken) {
      this.setState({ readyForLoginCall: true }, () => {
        let tData = btoa(userToken)
        let admin = btoa('admin=true')
        if (this.props.selectedUser.logInAsUserState === 'panel') {
          localStorage.setItem('adminToken', adminToken)
          localStorage.setItem('privileges', '')
          fetch('/api/session', {
            method: 'DELETE'
          }).then(response => {
            if (response.ok) {
              setCookie('JSESSIONID', 0)
              localStorage.setItem('userToken', userToken)
              //window.open(`http://localhost:3001/login`, '_self')
              window.open(
                `${this.props.ServerSetting.attributes.adminUrl}/login?&${tData}&${admin}`,
                '_self'
              )
            }else{
              throw response
            }
          }).catch(e => {
          this.props.navigate('/login')
          localStorage.setItem('privileges', '')
          localStorage.setItem('userInfo', '')
          localStorage.setItem('adminToken', '')
          this.props.dispatch(logout())
          setCookie('JSESSIONID', 0)
          this.setState({
            loading: false
          })})
        } 
        else if (this.props.selectedUser&&this.props.selectedUser.logInAsUserState === 'adminPanel') {          
          window.open(
            `${selectedUser.extraInfo &&
              selectedUser.extraInfo.adminUrl}/login?&${tData}&${admin}&${userToken}`,
              '_blank'
              )
          // localStorage.setItem('adminToken', adminToken)
          // localStorage.setItem('privileges', '')
          // fetch('/api/session', {
          //   method: 'DELETE'
          // }).then(response => {
          //   if (response.ok) {
          //     setCookie('JSESSIONID', 0)
          //     localStorage.setItem('userToken', userToken)
            
          //   }
          // })
        }
        else {
            window.open(
              `${(selectedUser &&
                this.props.selectedUser.logInAsUserState === 'whiteLeabling' &&
                selectedUser.extraInfo &&
                selectedUser.extraInfo.hostUrl) ||
                this.props.ServerSetting.attributes
                  .hostUrl}/loginasuser?&${tData}&${admin}`,
              '_blank'
            )
        }
      })
    }
  }
  render () {
    if (
      this.state.userToken &&
      this.state.adminToken &&
      !this.state.readyForLoginCall
    ) {
      this.logInAsUser()
      return null
    } else {
      return null
    }
  }
}

const mapStateToProps = state => ({
  logInUser: state.logInUsers,
  ServerSetting: state.ServerSetting
})

export default connect(mapStateToProps)(withRoutes(withTranslationWrapper(LoginAsUser)))

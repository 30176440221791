import React, { Component } from 'react'
import withTranslationWrapper from '../../HOC/HocTranslate'
import SimpleModalWrapped from '../common/Modal'
import TextField from '../common/TextField'
import { withStyles } from '@mui/styles'
import Grid from '@mui/material/Grid'
import MenuItem from '@mui/material/MenuItem'
import Attributes from '../Attributes/attributesModal'
// import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers'
// import DateFnsUtils from '@date-io/date-fns'
// import InputAdornment from '@mui/material/InputAdornment'
// import Tooltip from '@mui/material/Tooltip'
// import IconButton from '@mui/material/IconButton'
// import CrossIcons from '@mui/icons-material/Remove'
import AsyncSelect from '../common/asyncSelect'
import SingleSelect from '../common/SingleSelect'
import DeleteIcon from '@mui/icons-material/Delete'
import Button from '../common/Button'
import Divider from '@mui/material/Divider'

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  dense: {
    marginTop: 19
  },
  menu: {
    width: 200
  },
  button: {
    margin: theme.spacing(1)
  }
})
let arr = []
class addItemModal extends Component {
  constructor () {
    super()
    this.state = {
      attm_isVisable: false,
      resultMessage: 'No Items',
      scale:[{x:0, y:0}],
      headers:[{x:0, y:0}],
      ioLength:0,
    }
    this.addAttributes = this.addAttributes.bind(this)
  }
  addAttributes () {
    this.setState({
      attm_isVisable: !this.state.attm_isVisable
    })
  }

  componentWillMount (){
    let ioRows = [] , headersRow =[]
    if(this.props.edit){
      if(this.props.form && this.props.form.io){

      Object.entries(this.props.form.io).map(([key,value])=>{
        if(value){
          ioRows.push({ x: key, y:value })
        }
      })
      // this.setState({scale:ioRows});
      this.props.setScale(ioRows)
    }
      if(this.props.form && this.props.form.headers){

          Object.entries(this.props.form.headers).map(([key,value])=>{
            if(value){
              headersRow.push({ x: key, y:value })
            }
          })
          // this.setState({headers:headersRow});
          this.props.setHeader(headersRow)
      }
    }
  }

  handleChart = (name, event, index, val)=> {
    if (name === 'y'){
      this.props.handleChart2(name, event.target.value, index, val)
    }
    else {
      this.props.handleChart2(name,  event.value, val.index, val.name)
    }
  }

  handleHeaders (name, event, index, val) {
      this.props.handleChart2(name, event.target.value, index, val)
  }

  render () {
    let emptyLink = null
    let defaultMethods = []
    methods.map((item, i) => {
        defaultMethods.push({
        id: item.key,
        key: item.key,
        label: item.label,
        value: item.key
      })
    })
    const { classes, form } = this.props
  
    const Content = (
      <div>
          <div style={{display:'flex', justifyContent:'center'}}>
            <h4> URL : </h4> {' '}
                <h4 style={this.props.error ? {color:'red'} : {color:'inherit'}}> 
                  {form.client_url ? form.client_url + '/' : ''}{form.hook_prefix ? form.hook_prefix + '/' : ''}
                  {(form.forward_hook ? form.forward_hook + '/' : '') || (form.hook ? form.hook + '/' : '')}
                  {form.hook_postfix ? form.hook_postfix + '/': ''}</h4> 
           </div>
    

        <Grid container spacing={4}>
          <Grid item md={3} sm={6} xs={12}>
            <TextField
              id='client_name'
              label={this.props.translate('clientName')}
              type='text'
              pattern="http"
              placeholder=''
              value={this.props.form.client_name || ''}
              onChange={this.props.handleChange('client_name')}
              variant='outlined'
              margin='dense'
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item sm={3} xs={12} style={{ zIndex: 101 }}>
            <SingleSelect
              array={[]}
              async
              selectName='method'
              isClearable
              defaultOptions={defaultMethods}
              label={this.props.translate('method')}
               value={
                      this.props.form &&
                      this.props.form.method
                        ? defaultMethods.find(
                            t => t.key === this.props.form.method
                          )
                        : 'POST'
                    }
              handleChange={this.props.method_handleChange}
              canAssign={true}
              styles={{
                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              }}
            />
        </Grid>
          <Grid item md={8} sm={6} xs={12}>
            <TextField
              id='client_url'
              label={this.props.translate('clientUrl')}
              type='text'
              value={this.props.form.client_url || ''}
              onChange={this.props.handleChange('client_url')}
              variant='outlined'
              margin='dense'
              fullWidth
              required
              helperText= {this.props.error ? 'InValid Format' : '' }
              error= {this.props.error}

            />
          </Grid>
        </Grid>
        <Divider style={{ marginTop: 20 }} />
        <h4>{this.props.translate('Parameters')}</h4>
        <Grid container spacing={2}>
          <Grid item sm={6} md={3} xs={12} style={{ zIndex: 100 }}>
              <AsyncSelect
                isMulti={false}
                value={this.props.selectedEntityType  || ''}
                {...this.props}
                selectName='selectedEntity'
                placeholder={this.props.selectedEntityType || this.props.translate('searchEntity')}
                resultMessage={this.state.resultMessage}
                defaultOptions={this.props.defaultEntityList}
                handleChange={this.props.method_handleChange}
              />
           </Grid>
              <Grid item sm={6} md={3} xs={12} style={{marginTop:10}}>
                  <Button
                    href={emptyLink}
                      onClick={e=> this.props.addMore('scale')}
                      size='small'
                      color='inherit'
                    >
                    {this.props.translate('addMore')}
                  </Button>
              </Grid>
            </Grid>  
            {this.props.scale.map((row, index) => (
                <Grid
                  key={index}
                  container
                  spacing={2}
                >
                  {(<>
                    <Grid item sm={6} md={3} xs={12} style={{zIndex: (99 - index)}} >
                        <AsyncSelect
                          isMulti={false}
                          value={row.x }
                          {...this.props}
                          selectName='x'
                          extras={{index,name:'scale'}}
                          placeholder={row.x || this.props.translate('search')}
                          resultMessage={this.state.resultMessage}
                          defaultOptions={this.props.defaultEntityFields}
                          handleChange={this.handleChart}
                          />
                      </Grid>
                      </>
                      )}
                     {/* {{this.props.edit && (<> */}
                       {/* <Grid item sm={6} md={this.state.scale.length > 1 ? 3 : 3} xs={12}  >
                          <TextField
                            selectName='val'
                            type='text'
                            label={"Input"}
                            value={row.x || ''}
                            onChange={e=> this.handleChart('x', e, index ,'scale')}
                            variant='outlined'
                            margin='dense'
                            fullWidth
                            readOnly
                          />
                        </Grid> */}
                      {/* </>)} } */}
                      <Grid item sm={6} md={this.props.scale.length > 1 ? 3 : 3} xs={12}  >
                        <TextField
                          selectName='val'
                          type='text'
                          label={"Output"}
                          value={row.y || ''}
                          onChange={e=> this.handleChart('y', e, index ,'scale')}
                          variant='outlined'
                          margin='dense'
                          fullWidth
                        />
                      </Grid>
                   
                  {this.props.scale.length > 1 ? (
                    <Grid style={{marginTop:18}}>
                      <DeleteIcon
                        onClick={e => this.props.deleteRow(row, index,'scale')}
                        style={{ cursor: 'pointer' }}
                      />
                      </Grid>
                  ) : null}
                  
                </Grid>
              ))}
           <Divider style={{ marginTop: 20 }} />
           <h4>{this.props.translate('Headers')}</h4>  
          <Grid item sm={6} md={3} xs={12} style={{marginTop:10}}>
                  <Button
                    href={emptyLink}
                      onClick={e => this.props.addMore('Header')}
                      size='small'
                      color='inherit'
                    >
                    {this.props.translate('addMore')}
                  </Button>
              </Grid>
            {this.props.headers.map((row, index) => (
              <Grid
                  key={index}
                  container
                  spacing={2}
                >     
                  <Grid item sm={6} md={3} xs={12}  >
                      <TextField
                        selectName='val'
                        type='text'
                        value={row.x || ''}
                        onChange={e=> this.handleHeaders('x', e, index ,'header')}
                        variant='outlined'
                        margin='dense'
                        fullWidth
                      />
                  </Grid>
                      <Grid item sm={6} md={this.props.headers.length > 1 ? 3 : 3} xs={12}  >
                        <TextField
                          selectName='val'
                          type='text'
                          value={row.y || ''}
                          onChange={e=> this.handleHeaders('y', e, index,'header')}
                          variant='outlined'
                          margin='dense'
                          fullWidth
                        />
                      </Grid>
                   
                  {this.props.headers.length > 1 ? (
                    <Grid style={{marginTop:18}}>
                      <DeleteIcon
                        onClick={e => this.props.deleteRow(row, index, 'header')}
                        style={{ cursor: 'pointer' }}
                      />
                      </Grid>
                  ) : null}
                  
                </Grid>
              ))}


      <Divider style={{ marginTop: 30 }} /> 
        <Grid container spacing={4} style={{ marginTop: 20 }}>
          <Grid item md={3} sm={6} xs={12}>
            <TextField
              id='forward_hook'
              label={this.props.translate('forwardHook')}
              type='text'
              placeholder=''
              value={this.props.form.forward_hook || ''}
              onChange={this.props.handleChange('forward_hook')}
              variant='outlined'
              margin='dense'
              fullWidth
            />
          </Grid>  
          <Grid item md={3} sm={6} xs={12}>
            <TextField
              id='hook'
              label={this.props.translate('hook')}
              type='text'
              placeholder=''
              value={this.props.form.hook || ''}
              onChange={this.props.handleChange('hook')}
              variant='outlined'
              margin='dense'
              fullWidth
              required
            />
          </Grid> 
           <Grid item md={3} sm={6} xs={12}>
            <TextField
              id='hook_postfix'
              label={'hook_postfix'}
              type='text'
              placeholder=''
              value={this.props.form.hook_postfix || ''}
              onChange={this.props.handleChange('hook_postfix')}
              variant='outlined'
              margin='dense'
              fullWidth
            />
          </Grid> 
           <Grid item md={3} sm={6} xs={12}>
            <TextField
              id='hook_prefix'
              label={'hook_prefix'}
              type='text'
              placeholder=''
              value={this.props.form.hook_prefix || ''}
              onChange={this.props.handleChange('hook_prefix')}
              variant='outlined'
              margin='dense'
              fullWidth
            />
          </Grid>  
          {/* <Grid item md={3} sm={6} xs={12}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      error={false}
                      margin='dense'
                      label={this.props.translate('datetime')}
                      variant='outlined'
                      fullWidth
                      minDate={new Date()}
                      value={
                        this.props.form.datetime
                          ? new Date(
                              `${this.props.form.datetime}`
                            ).toString()
                          : null
                      }
                      onChange={this.props.handleChange('datetime')}
                      classes={classes}
                      InputProps={{
                        classes: {
                          root: classes.cssOutlinedInput,
                          input: classes.input,
                          focused: classes.cssFocused,
                          notchedOutline: classes.notchedOutline
                        },
                        endAdornment: (
                          <InputAdornment position='end'>
                            <Tooltip title='Set No Limit'>
                              <IconButton
                                onClick={e => this.props.setDateTime(e)}
                              >
                                {<CrossIcons />}
                              </IconButton>
                            </Tooltip>
                          </InputAdornment>
                        )
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.label,
                          focused: classes.focused,
                          shrink: classes.shrink
                        }
                      }}
                    />
                  </MuiPickersUtilsProvider>
          </Grid> */}
        
       </Grid>
        <br />
      </div>
    )
    return (
      <div>
        <SimpleModalWrapped
          visable={true}
          isButtonVisable={true}
          title={'Hooks'}
          formSubmit={this.props.formSubmit}
          addAttributes={this.addAttributes}
          modalControle={this.props.modalControle}
          btnclosetext={'back'}
          content={Content}
          buttonText={this.props.buttonText}
          isVisableBtn={this.props.isVisableBtn}
          attributeChangesMessage={this.props.attributeChangesMessage}
        />

        {this.state.attm_isVisable ? (
          <Attributes
            onCloseModal={this.addAttributes}
            itemAttributes={this.props.itemAttributes}
            changeAttribute={this.props.changeAttribute}
            formSubmit={this.ud_submit}
            componentType={'coupon'}
            hasAccessOfUpdate={this.props.hasAccessOfUpdate}
            hasAccessOfDelete={this.props.hasAccessOfDelete}
            hasAccessOfCreate={this.props.hasAccessOfCreate}
          />
        ) : null}
      </div>
    )
  }
}
const AddItemModal = withStyles(styles)
export default AddItemModal(withTranslationWrapper(addItemModal))

export const methods = [
  {
    key: 'POST',
    label: 'POST'
  },{
    key: 'GET',
    label: 'GET'
  },{
    key: 'PUT',
    label: 'PUT'
  },{
    key: 'PATCH',
    label: 'PATCH'
  },{
    key: 'DELETE',
    label: 'DELETE'
  },{
    key: 'COPY',
    label: 'COPY'
  },{
    key: 'HEAD',
    label: 'HEAD'
  },{
    key: 'OPTIONS',
    label: 'OPTIONS'
  },{
    key: 'LINK',
    label: 'LINK'
  },{
    key: 'UNLINK',
    label: 'UNLINK'
  },{
    key: 'PURGE',
    label: 'PURGE'
  },{
    key: 'LOCK',
    label: 'LOCK'
  },{
    key: 'UNLOCK',
    label: 'UNLOCK'
  },{
    key: 'PROPFIND',
    label: 'PROPFIND'
  },{
    key: 'VIEW',
    label: 'VIEW'
  },
]
import React, { Component } from 'react'
import TextField from '../common/TextField'
import { withStyles } from '@mui/styles'
import { notifyError, notifySuccess } from '../../Utils/CustomNotifcations'
import Grid from '@mui/material/Grid'
import Button from '../common/Button'
import isEqual from 'react-fast-compare'
import { updateAccount } from '../../Actions/Accounts'
import AddAccountModal from '../Accounts/addAccount'
import { validEmailPattern } from '../../Helpers'

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  dense: {
    marginTop: 19
  },
  menu: {
    width: 200
  },
  button: {
    margin: theme.spacing(1)
  },
  paper: {
    padding: 0
  },
  bigAvatar: {
    margin: 10,
    width: 120,
    height: 100,
    borderRadius: '2%'
  }
})

let DefaultValues = {}

class account extends Component {
  constructor () {
    super()
    this.state = {
      updateOption: true,
      isVisableBtn: false,
      isAttChanged: false,
      emailPattern: false,
      notVisableBackbtn: true,
      notVisableEnternalAddBtn: true,
      readOnly: false,
      form: {
        name: '',
        email: '',
        contact: '',
        description: ''
      }
    }
    this.checkRequiredFields = this.checkRequiredFields.bind(this)
    this.submit = this.submit.bind(this)
    this.formReset = this.formReset.bind(this)
  }
  componentWillMount () {
    this.formReset()
  }
  formReset () {
    this.setState({
      selectedAccount: this.props.ServerSetting,
      form: { ...this.props.ServerSetting },
      isVisableBtn: false,
      readOnly: this.props.readOnly
    })
  }

  handleChange = name => event => {
    const { target } = event
    if (name === 'email') {
      if (!validEmailPattern.test(target.value)) {
        this.setState({
          emailPattern: true
        })
      } else {
        this.setState({
          emailPattern: false
        })
      }
    }
    this.setState(
      preState => ({
        ...preState,
        form: {
          ...preState.form,
          [name]: target.value
        }
      }),
      () => this.checkRequiredFields()
    )
  }
  checkRequiredFields () {
    let { form } = this.state
    let valid = true
    let value = !isEqual(this.state.form, this.state.selectedAccount)
    if (!validEmailPattern.test(form.email)) {
      valid = false
    }
    if (form.name && form.email && value) {
      this.setState({
        isVisableBtn: valid
      })
    } else {
      this.setState({
        isVisableBtn: this.state.isAttChanged
      })
    }
  }
  submit () {
    let { form } = this.state
    if (form.name.trim().length > 0) {
      let obj = {
        id: form.id,
        parent: form.parent,
        name: form.name,
        description: form.description,
        email: form.email,
        contact: form.contact,
        attributes: { ...this.props.ServerSetting.attributes }
      }
      fetch(`/api/accounts/${form.id}`, {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          ...obj
        })
      })
        .then(response => {
          if (response.ok) {
            response.json().then(account => {
              if(account.status ==='success'){
                this.props.dispatch(updateAccount(account.data))
                this.props.handleChangeAccounts(account.data)
                this.props.dispatch(
                  notifySuccess('accountUpdate')
                )
                this.setState({
                  form: { ...account.data },
                  isVisableBtn:false
                })
              }
              else if(account?.statusCode === '440'){
                window.location.replace('/login')
              }
              else if(account?.statusCode){
                var err = account?.message.split(':')
                err[1] =err[1].replace(')', "")
                this.props.dispatch(
                  notifyError(err[1])
                )
              }
            })

          } else {
            throw response
          }
        })
        .catch(e => {
          this.props.dispatch(notifyError('somethingWentWrong'))
        })
        // .catch(e => {
        //   if(e && e.text){
        //   e.text().then(err => {
        //     if (err.includes('Duplicate entry')) {
        //       this.setState({
        //         duplicateEntry: true,
        //         isVisableUserBtn: false
        //       })
        //       this.props.dispatch(
        //         notifyError({
        //           message: this.props.translate('userEmailIsAllreadyInUsed'),
        //           autoDismiss: 5,
        //           location: this.props.location
        //         })
        //       )
        //     }
        //   })
        // }
        // })
    } else {
      this.props.dispatch(
        notifyError('emptyField')
      )
    }
  }
  changeAttribute2 = (name, event) => {
    let value =
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value
    if (!value) {
      if (this.state.form.attributes[name]) {
        delete this.state.form.attributes[name]
      }
      this.setState(
        {
          form: {
            ...this.state.form,
            attributes: {
              ...this.state.form.attributes
            }
          }
        },
        () => this.checkRequiredFields()
      )
    } else {
      this.setState(
        {
          form: {
            ...this.state.form,
            attributes: {
              ...this.state.form.attributes,
              [name]: event.target.type === 'number' ? parseInt(value) : value
            }
          }
        },
        () => this.checkRequiredFields()
      )
    }
  }

  render () {
    const { form } = this.state
    return (
      <>
        <AddAccountModal
          {...this.state}
          changeAttribute2={this.changeAttribute2}
          handleChange={this.handleChange}
          form={this.state.form}
          logInUser={this.props.logInUser}
          userType={this.props.logInUser.userType}
          roleTypes={this.props.roles}
        />
        {this.props.logInUser.userType === -1 && (
          <>
            <Button
              variant='contained'
              onClick={this.formReset}
              style={{ marginLeft: 16, marginRight: 8 }}
            >
              {' '}
              {this.props.translate('resetButton')}
            </Button>
            <Button
              variant='contained'
              disabled={!this.state.isVisableBtn}
              onClick={() => this.submit()}
            >
              {' '}
              {this.props.translate('sharedSave')}
            </Button>
          </>
        )}
      </>
    )
  }
}
const Account = withStyles(styles)
export default Account(account)

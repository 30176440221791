import React, { Component } from 'react'
import withTranslationWrapper from '../../HOC/HocTranslate'
import SimpleModalWrapped from '../common/Modal'
import TextField from '../common/TextField'
import { withStyles } from '@mui/styles'
import Grid from '@mui/material/Grid'
// import MenuItem from '@mui/material/MenuItem'
import Attributes from '../Attributes/attributesModal'
// import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers'
// import DateFnsUtils from '@date-io/date-fns'
// import InputAdornment from '@mui/material/InputAdornment'
// import Tooltip from '@mui/material/Tooltip'
// import IconButton from '@mui/material/IconButton'
// import CrossIcons from '@mui/icons-material/Remove'
import CustomDatePicker from '../common/CustomDatePicker'

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  dense: {
    marginTop: 19
  },
  menu: {
    width: 200
  },
  button: {
    margin: theme.spacing(1)
  }
})

class addGroupModal extends Component {
  constructor () {
    super()
    this.state = {
      attm_isVisable: false
    }
    this.addAttributes = this.addAttributes.bind(this)
  }
  addAttributes () {
    this.setState({
      attm_isVisable: !this.state.attm_isVisable
    })
  }

  render () {
    const { group, classes, selectedGroup } = this.props
    const Content = (
      <div>
        <Grid container spacing={4}>
          <Grid item md={3} sm={6} xs={12}>
            <TextField
              id='couponCode'
              label={this.props.translate('couponCode')}
              type='text'
              placeholder=''
              value={this.props.form.couponCode}
              onChange={this.props.handleChange('couponCode')}
              variant='outlined'
              margin='dense'
              fullWidth
              required
            />
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <TextField
              id='usageLimit'
              label={this.props.translate('limit')}
              type='number'
              placeholder=''
              value={this.props.form.usageLimit}
              onChange={this.props.handleChange('usageLimit')}
              variant='outlined'
              margin='dense'
              fullWidth
              required
            />
          </Grid>

          <Grid item md={3} sm={6} xs={12}>
            <TextField
              id='usageCount'
              label={this.props.translate('count')}
              type='number'
              placeholder=''
              value={this.props.form.usageCount}
              onChange={this.props.handleChange('usageCount')}
              variant='outlined'
              margin='dense'
              fullWidth
              required
            />
          </Grid>

          <Grid item md={3} sm={6} xs={12}>
                  {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      error={false}
                      margin='dense'
                      label={this.props.translate('userExpirationTime')}
                      variant='outlined'
                      fullWidth
                      minDate={new Date()}
                      value={
                        this.props.form.expirationTime
                          ? new Date(
                              `${this.props.form.expirationTime}`
                            ).toString()
                          : null
                      }
                      onChange={this.props.handleChange('expirationTime')}
                      classes={classes}
                      InputProps={{
                        classes: {
                          root: classes.cssOutlinedInput,
                          input: classes.input,
                          focused: classes.cssFocused,
                          notchedOutline: classes.notchedOutline
                        },
                        endAdornment: (
                          <InputAdornment position='end'>
                            <Tooltip title='Set No Limit'>
                              <IconButton
                                onClick={e => this.props.setExpirationTime(e)}
                              >
                                {<CrossIcons />}
                              </IconButton>
                            </Tooltip>
                          </InputAdornment>
                        )
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.label,
                          focused: classes.focused,
                          shrink: classes.shrink
                        }
                      }}
                    />
                  </MuiPickersUtilsProvider> */}
                 
                      <CustomDatePicker
                        format="dd/MM/yyyy"
                        error={false}
                        label={this.props.translate(
                          "userExpirationTime"
                        )}
                        minDate={new Date()} 
                        fullWidth={true}
                        value={
                          this.props.form &&
                          this.props.form.expirationTime
                            ? 
                                `${this.props.form.expirationTime}`
                            : null
                        }
                        onChange={this.props.handleChange('expirationTime')}
                      />
       </Grid>
       </Grid>
        <br />
      </div>
    )
    return (
      <div>
        <SimpleModalWrapped
          visable={true}
          isButtonVisable={true}
          title={this.props.translate('coupon')}
          formSubmit={this.props.formSubmit}
          addAttributes={this.addAttributes}
          modalControle={this.props.modalControle}
          btnclosetext={this.props.translate('sharedBack')}
          content={Content}
          buttonText={this.props.buttonText}
          isVisableBtn={this.props.isVisableCreateBtn}
          attributeChangesMessage={this.props.attributeChangesMessage}
        />

        {this.state.attm_isVisable ? (
          <Attributes
            onCloseModal={this.addAttributes}
            itemAttributes={this.props.itemAttributes}
            changeAttribute={this.props.changeAttribute}
            formSubmit={this.ud_submit}
            componentType={'coupon'}
            hasAccessOfUpdate={this.props.hasAccessOfUpdate}
            hasAccessOfDelete={this.props.hasAccessOfDelete}
            hasAccessOfCreate={this.props.hasAccessOfCreate}
          />
        ) : null}
      </div>
    )
  }
}
const AddGroupModal = withStyles(styles)
export default AddGroupModal(withTranslationWrapper(addGroupModal))

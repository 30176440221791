import React from 'react'
import { connect } from 'react-redux'

class DemoComponent extends React.PureComponent {
  closeAll = () => {
        // this.props.dispatch(Notifications.removeAll())
  }
  render () {
    const { notifications } = this.props

    // Optional styling
    return (
      <div>
        {notifications && notifications.length > 4 ? (
          <button className='clearAllNotifcations' onClick={this.closeAll}>
            Clear All Notifications
          </button>
        ) : null}
        {/* <Notifications notifications={notifications} max={5} /> */}
      </div>
    )
  }
}
export default connect(state => ({ notifications: state.notificationSystem }))(
  DemoComponent
)

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { getUsers } from './../../Actions/Users';
import Icon from '@mui/material/Icon';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSatelliteDish } from '@fortawesome/free-solid-svg-icons';
import { checkPrivileges } from '../../Helpers'
import Avatar from '@mui/material/Avatar';
import { notifyError, notifySuccess } from '../../Utils/CustomNotifcations'
import "./index.scss";

library.add(faSatelliteDish);

class Users extends Component {
	constructor(props) {
		super(props);
		this.state = {
			usesId: '',
			callPending: true,
			selecteUserId: '',
			pathName: ''
		}
	}

	fatchUsersforOwner = (id) => {
		if ((this.props.users && !this.props.users.length) || (this.props.pathName !== this.state.pathName)) {
			this.setState({
				callPending: false,
				pathName: this.props.pathName
			}, () => {
				fetch(`/api/users?userId=${id}`).then(response => {
					if (response.ok) {
						response.json().then(users => {
							if(users.status ==='success'){
								this.props.dispatch(getUsers(users));
								this.setState({
									usesId: this.props.logInUser.id,
									callPending: true
								})
							}
							else if(users?.statusCode === '440'){
								window.location.replace('/login')
							  }
							else if(users.statusCode){
								var err = users?.message.split(':')
								err[1] =err[1].replace(')', "")
								this.props.dispatch(
								  notifyError(err[1])
								)
							  }
						});
					}
				else{
					throw response
					}
				})
				 .catch(e => {
					this.props.dispatch(notifyError('somethingWentWrong'))
				  })
			})
		}
	}

	editItem = (e, data) => {
		this.setState({
			selecteUserId: data.id
		}, () => this.props.editItem(e, data))

	}
	showNextChild = (e, data) => {
		// this.setState({
		// 	selecteUserId: data.id
		// }, () => this.props.showNextChild(e, data))

	}
	render() {
		let data = ''
		if (this.props.users && this.props.users.length) {
			data = this.props.users.map(user =>
				<ListRow key={user.id} data={user} {...this.props}
					selecteItem={this.props.selecteItem}
					selectedItemId={this.state.selecteUserId}
					showNextChild={this.showNextChild}
					removedItem={this.props.removedItem}
					editItem={this.editItem}
					assignGroup={this.props.assignGroup}
					assignUnits={this.props.assignUnits}
					pathName={this.props.pathName}
					translate={this.props.translate}
				/>
			);
		}
		return (
			<div>
				{data.length ?
					<div className="individual-view">
						<ul className="list-view">{data}</ul>
					</div>
					: <li style={{ textAlign: 'center', listStyle: 'none', marginTop: 50 }}>{this.props.translate && this.props.translate('noUserFound')}</li>}
			</div>
		);
	}
}


export class ListRow extends Component {
	checkUserType = (Utype) => {
		let type = ''
		if (Utype === -1) {
			type = 'O'
		}
		else if (Utype === 3) {
			type = 'D'
		}
		else if (Utype === 2) {
			type = 'A'
		}
		else {
			type = 'U'
		}
		return type
	}
	render() {

		if (this.props.logInUser) {
			return (
				<li>
					<span className={"clearfix list-row " + (parseInt(this.props.selectedItemId) === this.props.data.id ? "active" : "")} >
						{this.props.data.userType !== 1 ?
							<label className="checkbox" onClick={(e) => this.props.showNextChild(e, this.props.data)}>
								<span className="unit-name" style={{ minWidth: 190, maxWidth: 220, marginLeft: 0, lineHeight: "24px" }}><span
									style={{ marginRight: '10px', display: 'inline-block', verticalAlign: 'middle' }}>
									<Avatar className={"avatar-" + this.checkUserType(this.props.data.userType)} style={{ width: 25, height: 25, fontSize: 12 }}>{this.checkUserType(this.props.data.userType)}</Avatar>
								</span> {this.props.data.name}</span>
							</label>
							:
							<label className="checkbox" >
								<span className="unit-name" style={{ minWidth: 190, maxWidth: 220, marginLeft: 0, lineHeight: "24px" }}><span
									style={{ marginRight: '10px', display: 'inline-block', verticalAlign: 'middle' }}>
									<Avatar className={"avatar-" + this.checkUserType(this.props.data.userType)} style={{ width: 25, height: 25, fontSize: 12 }}>{this.checkUserType(this.props.data.userType)}</Avatar>
								</span> {this.props.data.name}</span>
							</label>
						}
						<div className="pull-right">
							{this.props.data.userType !== 1 && checkPrivileges('user') &&
								<span className="action-item hovered" title={this.props.translate && this.props.translate("showChildUser")} onClick={(e) => this.props.showNextChild(e, this.props.data)}><Icon>account_circle</Icon>
								</span>}
							{this.props.pathName === 'users' &&
								<Fragment>
									{checkPrivileges('group') && <span className="action-item hovered" title={this.props.translate && this.props.translate("assignGroup")} onClick={(e) => this.props.assignGroup(e, this.props.data.id)}><Icon>group_work</Icon></span>}
									{/* {checkPrivileges('userDelete') && <span className="action-item hovered" title={this.props.translate && this.props.translate("delete")} onClick={(e) => this.props.removedItem(e, this.props.data)}><Icon>delete</Icon></span>} */}
									{checkPrivileges('user') && <span className="action-item hovered" title={this.props.translate && this.props.translate("showdetail")} onClick={(e) => this.props.editItem(e, this.props.data)}> <Icon>info</Icon></span>}
								</Fragment>}
							{this.props.pathName === 'units' &&
								<Fragment>
									{checkPrivileges('device') && <span className="action-item hovered" title={this.props.translate && this.props.translate("assignUnits")} onClick={(e) => this.props.assignUnits(e, this.props.data.id)}> <Icon>assignment</Icon></span>}
									{checkPrivileges('device') && <span className="action-item hovered" title={this.props.translate && this.props.translate("showUnits")} onClick={(e) => this.props.editItem(e, this.props.data)}> <Icon>info</Icon></span>}
								</Fragment>}
							{this.props.pathName === 'groups' &&
								checkPrivileges('group') && <span className="action-item hovered" title={this.props.translate && this.props.translate("showGroups")} onClick={(e) => this.props.editItem(e, this.props.data)}> <Icon>info</Icon></span>}
						</div>
					</span>
				</li>
			)
		} else {
			return ''
		}
	}
}

const mapState = state => {
	if (state.users) {
		var user = state.users.filter((item) => item.name.toLowerCase().indexOf(state.searchUser) !== -1);
	}
	return {
		users: user,
		logInUser: state.logInUsers,
	}
}

const mapStateToProps = connect(mapState);
export const UsersShortList = mapStateToProps(Users);







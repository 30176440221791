import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { notifyError, notifySuccess } from '../../Utils/CustomNotifcations'
 import { compose } from 'redux'
import TextField from './../common/TextField'
import { withStyles } from '@mui/styles'
import Grid from '@mui/material/Grid'
import Button from '../common/Button'
import { ServerInfo } from '../../Actions/serverSetting'
import withTranslationWrapper from '../../HOC/HocTranslate'

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },

  dense: {
    marginTop: 19
  },
  menu: {
    width: 200
  },
  button: {
    margin: theme.spacing(1)
  }
})

class HistoryDays extends Component {
  constructor () {
    super()
    this.state = {
        form:{},
        isVisibleBtn: false
    }
 
  }
  componentWillMount () {
    if(this.props.ServerSetting){
       this.setState({
        form: { ...this.props.ServerSetting }
      })
    }
  }
  
  formSubmit =()=> {
    if(this.state.form?.attributes){
    
    let { form } = this.state
      fetch(`api/accounts/${form.id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        ...form
      })
    })
      .then(response => {
        if (response.ok) {
          response.json().then(server => {
             if(server.status ==='success'){
              if (this.props.updateOtherServerSetting) {
                this.props.updateSelectedAccount(form)
              } else {
                this.props.dispatch(ServerInfo(form))
              }
              this.setState({isVisibleBtn:false})
              this.props.dispatch(
                notifySuccess('serverSettingsUpdated')
              )
            }
            else if(server?.statusCode === '440'){
              window.location.replace('/login')
            }
            else if(server.statusCode){
              var err = server?.message.split(':')
              err[1] =err[1].replace(')', "")
              this.props.dispatch(
                notifyError(err[1])
              )
            }
          })
          this.setState({
            isVisableAddBtn: false
          })
        } else {
          throw response
        }
      })
      .catch(e => {
        this.props.dispatch(
          notifyError('somthingWentWrongMessage')
        )
      })
   }
  } 

  handleChange = (value)=>{
    this.setState({
        form:{
            ...this.state.form,
            attributes:{
                ...this.state.form.attributes,
                historyDays:value
            }
        }
    }, ()=>{
        if(this.state.form?.attributes?.historyDays){
            this.setState({isVisibleBtn:true})
        }
    })
  }
  

  render () {
    const { form, loading } = this.state
      
      return (
      <>
        <Grid container spacing={2}>
          <Grid item md={this.props.updateOtherServerSetting ? 4 : 3}>
                <TextField
                    label={this.props.translate('historyDays')}
                    type='number'
                    placeholder=''
                    value={this.state.form?.attributes?.historyDays || ''}
                    onChange={(e)=>{this.handleChange(e.target.value)}}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                />
              </Grid>
              
        </Grid>
        <Grid container spacing={2}>
        <Fragment>
                <Button
                  variant='contained'
                  disabled={!this.state.isVisibleBtn}
                  onClick={() => this.formSubmit()}
                >
                  {' '}
                  {this.props.translate('sharedSave')}
                </Button>
              </Fragment>
              
        </Grid>
      </>)
     
  }
}

const enhance = compose(withStyles(styles), connect())
export default withTranslationWrapper(enhance(HistoryDays))

 
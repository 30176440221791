import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux';
import { createStore, applyMiddleware  } from 'redux'
//import Reducers from './Reducers';
import { SnackbarProvider } from 'notistack';
import CR from './Reducers/index2'
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { Slide, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { createTheme } from '@mui/material/styles';
import thunk from 'redux-thunk'
import { ThemeProvider } from '@mui/styles';


function logger({ getState }) {
  return next => action => {
    //console.log(action.type, action)

    // Call the next dispatch method in the middleware chain.
    const returnValue = next(action)

   // console.log(action.type+' -->  after :', getState())

    // This will likely be the action itself, unless
    // a middleware further in chain changed it.
    return returnValue
  }
}

//const store = createStore(Reducers, applyMiddleware(logger));
const store2 = createStore(CR,applyMiddleware(...[thunk]));
const theme = createTheme({
  spacing: 1, // Define spacing unit
});

//console.log(store2.getState())

ReactDOM.render((

  <Provider store={store2}>
  {/* <LocalizeProvider> */}
    <SnackbarProvider maxSnack={6} preventDuplicate={false} anchorOrigin={{vertical: 'top', horizontal: 'right', }}>
      <BrowserRouter>
      <div>
        <ThemeProvider theme={theme}>

          <App />
        </ThemeProvider>
          <ToastContainer
          position="top-right"
          autoClose={500}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          transition={Slide}
          theme="colored"
        />
        </div>
      </BrowserRouter>
    </SnackbarProvider>
    {/* </LocalizeProvider> */}
  </Provider>
), document.getElementById('root'));

registerServiceWorker();

import React, { Component } from 'react'
import { connect } from 'react-redux'
import withTranslationWrapper from '../../HOC/HocTranslate'
import CustomDialog from '../common/Dialog'
import Paper from '@mui/material/Paper'
// import { Tree, treeUtil } from 'react-d3-tree'
import Loader from '../../Layout/Loader'
import ViewUserDetails from './viewUserdetails'

class showUser extends Component {
  constructor (props) {
    super(props)
    this.state = {
      value: 0,
      loader: false,
      defaultGroupList: false,
      showUserDetails: false,
      selectedUserDetails: '',
      showTree:true,
      selectedUserAccountDetails: ''
    }
  }
  onClick = e => {
    if (e.ownid) {
      this.setState(
        {
          showTree:false,
          loader: true,
          showUserDetails: true
        },
        () => this.fetchUserData(e.ownid)
      )
    }
  }
  fetchUserData = id => {
    fetch(`/api/users?id=${id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }).then(response => {
      if (response.ok) {
        response.json().then(data => {
          console.log('data=', data)
          this.setState({
            selectedUserDetails: data,
            loader: false
          })
        })
        }
        else{
          throw response
        }
      })
    .catch(e => {})
  }

  UpdateSelectedItem = item => {
    this.setState({
      selectedUser: item
    })
  }

  onCloseC_Modal = () => {
    this.setState({
      loader: false,
      showUserDetails: false,
      showTree:true,
      selectedUserDetails: '',
      selectedUserAccountDetails: '',
    })
  }
  handleChange = (event, value) => {
    this.setState({ value })
  }
  render () {
  return (
    <div>
      {this.state.showUserDetails && (
        <CustomDialog
          visable={true}
          bodyPadding={10}
          hideDragIcon
          isVisableBtn
          noFullScreen
          background={'#f7f8f9'}
          maxWidth={520}
          viewUserDetailWidth={'60%'}
          themeColors={this.props.themecolors}
          title={
            this.state.loader
                ? 'loading...'
                : this.state.selectedUserDetails[0].name +
                  "'S " +
                  this.props.translate('userDetail')
            }
            onClose={this.onCloseC_Modal}
            // isButtonVisable={false}
            // draggable={true}
          >
            <div>
              {!this.state.loader ? (
                <>
                <Paper style={{ padding: 16, minHeight: 270 }} square={true}>
                    <div>
                      <ViewUserDetails
                        {...this.state}
                        {...this.props}
                        roleTypes={this.props.roles}
                        UpdateSelectedItem={this.UpdateSelectedItem}
                        translate={this.props.translate}
                      />
                    </div>
                </Paper>
              </>
              ) : ( <Loader  defaultPageLoader viewUserDetailHeight={'30%'} img={'50px'}/>
                
              )}
            </div>
          </CustomDialog>
        )}
        {/* {this.state.showTree && (<div id='treeWrapper' style={{ height: '1000px', marginLeft: '20' }}>
          <Tree
            data={this.props.listData[0]}
            shouldCollapseNeighborNodes={true}
            zoom={0.5}
            //nodeSvgShape={svgSquare}
            onClick={e => this.onClick(e)}
            pathFunc={'step'}
            orientation={'vertical'}
            collapsible={false}
          />
        </div>)} */}
      </div>
    )
  }
}

const mapState = state => ({
  themecolors: state.themecolors,
  logInUser: state.logInUsers,
  roles: state.roles,
  users:state.users,
})
const mapStateToProps = connect(mapState)
export const ViewUsers = mapStateToProps(withTranslationWrapper(showUser))

const svgSquare = {
  shapeProps: {
    width: 20,
    height: 20,
    x: -10,
    y: -10
  }
}

import React, { Component } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { connect } from 'react-redux';

let themecolors;
const mapStateToProps = (state, ownProps) => {
  themecolors = state.themecolors;
  return {
    themecolors: state.themecolors
  };
};

class CheckboxLabels extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: props.checked,
      indeterminate: props.indeterminate || false
    };
  }

  handleChange = name => event => {
    if (event.target.checked === true && this.props.canAssign) {
      this.setState({ checked: event.target.checked });
      this.props.onChange(event, name);
    } else if (event.target.checked === false && this.props.canRemove) {
      this.setState({ checked: event.target.checked });
      this.props.onChange(event, name);
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      checked: nextProps.checked,
      indeterminate: nextProps.indeterminate || false
    });
  }

  render() {
    const { checked, indeterminate } = this.state;
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={checked ? true : false}
            indeterminate={indeterminate}
            readOnly={this.props.readonly}
            onChange={this.handleChange(this.props.value || this.props.id)}
            sx={{
              color: themecolors[500],
              '&.Mui-checked': {
                color: themecolors[500]
              }
            }}
          />
        }
        label={this.props.label || ''}
      />
    );
  }
}

export default connect(mapStateToProps)(CheckboxLabels);

import React, { Component, Fragment } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
 import { compose } from 'redux'
import { withStyles } from '@mui/styles'
import Table from '../../common/TableMultiCheckbox'
import {
  checkPrivileges,
  PaginationConfig
} from '../../../Helpers'
import LoginAsUser from '../../../Helpers/loginAsUser'
import Loader from '../../../Layout/Loader'
import moment from 'moment'
import { Grid } from '@mui/material'
import TextField from '../../common/TextField'
import { notifyError, notifySuccess } from '../../../Utils/CustomNotifcations'


const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  dense: {
    marginTop: 19
  },
  menu: {
    width: 200
  },
  button: {
    margin: theme.spacing(1)
  }
})
let source,
  totalUserId = {
    userId: [],
    deviceId: ''
  }
class User extends Component {
  constructor () {
    super()
    this.state = {
      unitsRecieved: false,
      selectedUnitsList: '',
      accessUserList: '',
      pagination: { ...PaginationConfig, hasNext: '', pageSize: 5 },
      total: '',
      lastPage: '',
      isChecked: false,
      isIndeterminate: false,
      allSelectedIds: '',
      noRecords: false,
      assignedFilter:false,
      managedUserId: '',
      allUnits:[]
    }
    this.unitToResourcePermission = this.unitToResourcePermission.bind(this)
  }
  componentWillMount () {
    this.getMoreUnits()
  }
  componentWillUnmount () {
    // if (source) {
    //   source.cancel()
    // }
  }
  getMoreUnits = () => {
    let { pagination } = this.state;
    let { page, pageSize, itemSearch } = pagination;
    let count = 0,
      allUserId = 0;
    let res1 = this.props.units;
    let sellectedIds = [];
    source = axios.CancelToken.source();
  
    axios
      .get(
        `api/devices/get?userId=${this.props.logInUser.id}&all=true&page=${page}&limit=${pageSize}&search=${itemSearch}`,
        {
          cancelToken: source.token,
        }
      )
      .then(resp => {
        let response = resp.data;
        if (response.status === "success") {
          let lastPage = response.data.total / pageSize;
          let IsFloate = this.checkFloteNumber(lastPage);
          axios
            .get(`api/devices?groupId=${this.props?.form?.id}`)
            .then(res1 => {
              let res = res1.data;
              if (res.status === "success") {
                response.data.data.map(loginData => {
                  const linkedDevice = res.data.find(
                    selected => loginData.entity.id === selected.id
                  );
                  loginData.entity.check = !!linkedDevice;
                });
                res.data.map(userId => {
                  sellectedIds.push(userId.entityId);
                });
                this.setState({
                  allSelectedIds: sellectedIds,
                  isChecked:
                    sellectedIds.length === this.props.loginDeviceIds.length &&
                    this.props.loginDeviceIds.length,
                });
                let units = response.data.data.map(u => {
                  if (u.entity.created) {
                    return {
                      ...u,
                      entity: {
                        ...u.entity,
                        created: this.props.logInUser.twelveHourFormat
                          ? moment(u.entity.created).format("YYYY-MM-DD hh:mm A")
                          : moment(u.entity.created).format("YYYY-MM-DD HH:mm"),
                      },
                    };
                  } else {
                    return { ...u };
                  }
                });
  
                this.setState({
                  allUnits: units,
                  unitsRecieved: true,
                  pagination: {
                    ...this.state.pagination,
                    lastPage: IsFloate ? parseInt(lastPage + 1) : lastPage,
                    hasNext: response.data.hasNext,
                    total: response.data.total,
                  },
                });
              } else if (res?.statusCode === "440") {
                window.location.replace("/login");
              } else if (res?.statusCode) {
                var err = res?.message.split(":");
                err[1] = err[1].replace(")", "");
                this.props.dispatch(notifyError(this.props.translate(err[1])));
              }
            });
        } else if (response?.statusCode === "440") {
          window.location.replace("/login");
        } else if (response?.statusCode) {
          var err = response?.message.split(":");
          err[1] = err[1].replace(")", "");
          this.props.dispatch(notifyError(this.props.translate(err[1])));
        }
      })
      .catch(err => {
        if (axios.isCancel(err)) {
          console.log("Request canceled", err.message);
        } else {
          console.error(err);
        }
      });
  };

  checkFloteNumber (n) {
    return Number(n) === n && n % 1 !== 0
  }
  handleChangeRowsPerPage = value => {
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          pageSize: value
        }
      },
      () => {
        this.getMoreUnits()
      }
    )
  }
  handleChangePage = value => {
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          page: value
        }
      },
      () => this.getMoreUnits()
    )
  }
  SearchItem = searchValue => {
    // Cancel previous request only if it's not yet completed
    if (source) {
      source.cancel("Operation canceled due to new request.");
    }
  
    // Create a new cancel token
    source = axios.CancelToken.source();
  
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          page: 1,
          itemSearch: searchValue,
        },
      },
      () => this.getMoreUnits()
    );
  };

  allUnitsId = event => {
    let sellectedIds = []
    let check = event.target.checked
    let allUserId = []
    this.selectAllUser(check)
  }

  selectAllUser = event => {
    let array1 =
      this.props &&
      this.props.loginDeviceIds &&
      this.props.loginDeviceIds.filter(
        val => !this.state.allSelectedIds.includes(val)
      )
    let  obj = { groupId: this.props.form.id, deviceId: array1 }
    let option = 'DELETE'
    if (event) {
      option = 'POST'
    }

    fetch(`api/permissions/multiproperty`, {
      method: `${option}`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        ...obj
      })
    })
      .then(response => {
         response.json().then(res => {
          if(res.status ==='success'){
            if (option === 'POST') {
              this.setState({
                isChecked: true
              })
  
              this.props.dispatch(
                notifySuccess(this.props.translate('unitsIsAssignedSuccessfully'))
              )
            } else {
              this.setState({
                isChecked: false
              })
              this.props.dispatch(
                notifySuccess(('unitsIsUnAssignedSuccessfully'))
              )
            }
            this.getMoreUnits()
          }
          else if(res?.statusCode === '440'){
            window.location.replace('/login')
          }
          else if(res.statusCode){
            var err = res?.message.split(':')
            err[1] =err[1].replace(')', "")
            this.props.dispatch(
              notifyError(this.props.translate(err[1]))
            )
          }
      })
    })
    .catch(e => {
      this.props.dispatch(notifyError('somethingWentWrong'))
      totalUserId = {
        userId: [],
        deviceId: ''
      }
    })
  }
  unitToResourcePermission (event, item) {
    let sellectedIds = []
    let option = 'DELETE'
    let count = 0,
      allUserId = 0
    if (event) {
      option = 'POST'
    }
    let allData = [...this.state.selectedUnitsList]
    let obj = {
      groupId: this.props.form.id,
      deviceId: item.id
    }
    fetch(`/api/permissions`, {
      method: `${option}`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        ...obj
      })
    })
      .then(response => {
        response.json().then(res =>{
        if (res.status === "success") {
          this.getMoreUnits()
          if (option === 'POST') {
            this.props.dispatch(
              notifySuccess(this.props.translate('unitIsAssignedSuccessfully'))
            )
          } else {
            this.props.dispatch(
              notifySuccess(this.props.translate('unitIsUnAssignedSuccessfully'))
            )
          }
        }
        else if(res?.statusCode === '440'){
          window.location.replace('/login')
        }
         else if(res.statusCode){
                var err = res?.message.split(':')
                err[1] =err[1].replace(')', "")
                this.props.dispatch(
                  notifyError(this.props.translate(err[1]))
                )
              }
        else {
          throw response
        }
      })
    })
    .catch(e => {
      this.props.dispatch(notifyError('somethingWentWrong'))
    })
      // .catch(e => {
      //   if(e && e.text){
      //     e.text().then(err => {
      //       if (err.includes('You cannot unlink unit')) {
      //         this.props.dispatch(
      //           notifyError({
      //             message: this.props.translate('youCannotUnlinkUnit'),
      //             autoDismiss: 5,
      //             location: this.props.location
      //           })
      //         )
      //       }
      //     })
      //   }
      // })
  }
  loginAsUser = data => {
    if (data[0].logInAsUserState) {
      this.setState(
        {
          userDataForLogin: data[0],
          isUserLogin: false
        },
        () => {
          this.setState({
            isUserLogin: true
          })
        }
      )
    }
  }
  loginAsUserhandleChange = (id, value) => {
    let { selectedUnitsList } = this.state
    selectedUnitsList.entity.map(item => {
      if (item.id === id) {
        item.logInAsUserState = value
      }
      return null
    })
    this.setState({
      selectedUnitsList
    })
  }

  render () {
    const { classes,logInUser } = this.props
    return (
      <Fragment>
          <Grid container className="breadcrumb-row">
               <Grid item xs={12} sm={3}>
                   <h3 style={{margin: 0}}>{this.props.translate('units')}</h3>
                </Grid>
            

                    <Grid item xs={12} sm={9} style={{justifyContent: 'flex-end'}}>
                      <div style={{ marginRight: '20px', width: "25%" }}>
                       <TextField
                         fullWidth
                         label={this.props.translate('search')}
                        onChange={e => this.SearchItem(e.target.value)}
                      />
                      </div>
                  </Grid>
            
               </Grid>
        {this.state && this.state.unitsRecieved ? (<>
          <div styles={{ postion: 'relative' }}>
            <Table
              rows={
                this.state.allUnits.map(item => {
                  return item.entity
                }) || []
              }
              
              allUsersId={this.allUnitsId}
              selectItemParentId={692}
              pagination={this.state.pagination}
              handleChangeRowsPerPage={this.handleChangeRowsPerPage}
              handleChangePage={this.handleChangePage}
              loginAsUser={this.loginAsUser}
              loginAsUserhandleChange={this.loginAsUserhandleChange}
              isChecked={this.state.isChecked}
              isIndeterminate={this.state.isIndeterminate}
              rowsPerPage={10}
              isEditable={false}
              showCheckbox2={true}
              ServerSetting={this.props.ServerSetting}
              themecolors={this.props.themecolors}
              checkHandleChange={this.unitToResourcePermission}
              canAssign={checkPrivileges('userLinkDevice')}
              canRemove={checkPrivileges('userUnlinkDevice')}
              rowDefinition={[
                {
                  id: 'name',
                  numeric: false,
                  disablePadding: false,
                  label: this.props.translate('sharedName')
                },
                (logInUser.attributes?.viewIMEI || logInUser.userType===-1) &&{
                  id: 'uniqueId',
                  numeric: false,
                  disablePadding: false,
                  label: this.props.translate('driverUniqeId')
                },
                {
                  id: 'phone',
                  numeric: false,
                  disablePadding: false,
                  label: this.props.translate('sharedPhone')
                },
                (logInUser.attributes?.viewModel || logInUser.userType===-1) &&{
                  id: 'model',
                  numeric: false,
                  disablePadding: false,
                  label: this.props.translate('model')
                },
          
                {
                  id: 'created',
                  numeric: false,
                  disablePadding: false,
                  label: this.props.translate('createdAt')
                },
                {
                  id: 'status',
                  numeric: false,
                  disablePadding: false,
                  label: this.props.translate('status')
                }
              ]}
            />{' '}
          </div>
          </>
        ) :  
        !this.state.usersReceived && !this.state.noRecords ?
         (<div style={{ textAlign: 'center' }}>
              <Loader defaultStyle />
        </div>)
          : null
      }

        {!this.state.usersReceived && this.state.noRecords && (
              <div style={{ textAlign: 'center' }}>
                  <h4> {this.props.translate('noUnitsFound')}</h4>
              </div>
          )}

        {this.state.isUserLogin && (
          <LoginAsUser selectedUser={this.state.userDataForLogin} />
        )}
      </Fragment>
    )
  }
}

const enhance = compose(withStyles(styles), connect())

export default enhance(User)
const rowDefinitionR = translate => {
  if (localStorage.getItem('adminToken')) {
    return [
      {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: translate('sharedName')
      },
      {
        id: 'uniqueId',
        numeric: false,
        disablePadding: false,
        label: translate('driverUniqeId')
      },
      {
        id: 'phone',
        numeric: false,
        disablePadding: false,
        label: translate('sharedPhone')
      },
      {
        id: 'model',
        numeric: false,
        disablePadding: false,
        label: translate('model')
      },

      {
        id: 'created',
        numeric: false,
        disablePadding: false,
        label: translate('createdAt')
      },
      {
        id: 'status',
        numeric: false,
        disablePadding: false,
        label: translate('status')
      }
    ]
  } else {
    return [
      {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: translate('sharedName')
      },
      {
        id: 'uniqueId',
        numeric: false,
        disablePadding: false,
        label: translate('driverUniqeId')
      },
      {
        id: 'phone',
        numeric: false,
        disablePadding: false,
        label: translate('sharedPhone')
      },
      {
        id: 'model',
        numeric: false,
        disablePadding: false,
        label: translate('model')
      },
      {
        id: 'created',
        numeric: false,
        disablePadding: false,
        label: translate('createdAt')
      },
      {
        id: 'status',
        numeric: false,
        disablePadding: false,
        label: translate('status')
      }
    ]
  }
}

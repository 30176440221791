import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import Paper from '@mui/material/Paper'
import AppBar from '@mui/material/AppBar'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { connect } from 'react-redux'
import { checkPrivileges } from '../../../Helpers'
// import { withSnackbar } from 'notistack'
import AddResourceModal from '../addResouce'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import Style from 'style-it'
import Breadcrumb from '../../common/Breadcrumb'
import Button from '../../common/Button'
import Units from './Units'
import axios from 'axios'
import { notifyError } from '../../../Utils/CustomNotifcations'
import withTranslationWrapper from '../../../HOC/HocTranslate'

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    position: 'relative',
    padding: theme.spacing(1) * 2,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1) * 2
  },
  nested: {
    paddingLeft: theme.spacing(1) * 4
  }
})
class viewResourceDetail extends Component {
  constructor (props) {
    super(props)
    this.state = {
      value: 0,
      units: '',
      selectedDevice: '',
      suggestions: [],
      isRecived: false,
      attTapNotShow: false,
      loginDeviceIds: ''
    }
  }

  componentWillMount(){
    let allIds = []
    axios
    .get(`/api/devices/linked?userId=${this.props.logInUser.id}`)
    .then(response => {
      let res = response.data
      if(res.status==='success'){
        res.data.map(userId => {
          allIds.push(userId.entityId)
        })
        this.setState({
          loginDeviceIds: allIds
        })
      }
      else if(res?.statusCode === '440'){
        window.location.replace('/login')
      } 
      else if(res?.statusCode){
        var err = res?.message.split(':')
        err[1] =err[1].replace(')', "")
        this.props.dispatch(
          notifyError(this.props.translate(err[1]))
        )
      }
    })
    .catch(e => e)
  }
  

  handleChange = (event, val) => {
    if(val || val === 0){
        this.setState({ value:val })
    }
  }

  render () {
    const { classes, logInUser } = this.props
    const { value, isRecived, attTapNotShow, selectedDevice } = this.state
    const orientation = window.innerWidth <= 959 ? 'horizontal' : 'vertical'
    let tabName = 'Edit'
    if(value ===0){tabName=this.props.translate('sharedEdit')}
       else if(value===1){tabName=this.props.translate('units')}


    return (
      <div className={classes.root}>
          <Breadcrumb
              itemName={this.props.selectItemName}
              tabName={tabName}
              onClose={this.props.onClose}
              moduleName={this.props.translate('resourceDetail')}
              backLink={<Button size="small" onClick={this.props.onClose}>{this.props.translate('sharedBack')}</Button>}
         />
        <Style>{`
          .tab-vertial-wrapper > .custom-tabs {
            background: ${this.props.themecolors.backgroundColor};
          }
          .custom-tab-button2 .MuiTab-wrapper {
            align-items: normal;
            flex-direction: row;
            justify-content: unset;

          }
          .custom-tab-button2 {
            color: ${this.props.themecolors.textColor};
            text-align: left;
            text-transform: inherit;
            border-bottom: 1px solid rgba(0,0,0,0.05)
          }
          .custom-tab-button {
            min-height: 29px;
            border-radius: 6px 6px 0 0;
            margin: 0 2px;
            background: ${this.props.themecolors['600']};
            color: ${this.props.themecolors['themeInverse']};
          }
          .custom-tabs {
            min-height: 30px;
            margin: 10px 6px 0;
          }
   .Mui-selected  {
            color: ${this.props.themecolors["500"]}  !important;
          }
          .custom-tab-button-selected2 {
            color: ${this.props.themecolors['500']};
          }`}</Style>
        <div className="v-tabs">
        <AppBar elevation={0} position="static" style={{background: 'transparent'}} className="tab-vertial-wrapper">
          <Tabs
            value={value}
            onChange={this.handleChange}
            orientation={orientation}
            classes={{ root: 'custom-tabs' }}
            TabIndicatorProps={{
              style: { background: 'transparent' }
            }}
          >
            <Tab
               classes={{
                root: 'custom-tab-button2',
                selected: 'custom-tab-button-selected2'
              }}
                style={{alignItems:"flex-start", }}

              label={<div style={{ display: "flex", alignItems: "center", }}><KeyboardArrowRightIcon color='currentColor' />{this.props.translate('sharedEdit')}</div>}
            />
            <Tab
              classes={{
                root: 'custom-tab-button2',
                selected: 'custom-tab-button-selected2'
              }}
                style={{alignItems:"flex-start", }}

              label={<div style={{ display: "flex", alignItems: "center", }}><KeyboardArrowRightIcon color='currentColor' />{this.props.translate('units')}</div>}
            />
          </Tabs>
        <Paper style={{ padding: 16, minHeight: 270 , flex: 1}} square={true}>
          {value === 0 && (
            <Fragment>
               <AddResourceModal
                   group={this.props.groups.data}
                   selectedGroup={this.props.selectedGroup}
                   formSubmit={this.props.formSubmit}
                   changeAttribute={this.props.changeAttribute}
                   handleChange={this.props.handleChangeForm}
                   form={this.props.form}
                   buttonText={this.props.translate('update')}
                   isVisableGroupBtn={this.props.isVisableGroupBtn}
                   itemAttributes={this.props.itemAttributes}
                   attributeChangesMessage={this.state.attributeChangesMessage}
                   notVisableBackbtn={true}
                   hasAccessOfUpdate={checkPrivileges('groupUpdate')}
                   hasAccessOfDelete={checkPrivileges('groupDelete')}
                   hasAccessOfCreate={checkPrivileges('groupCreate')}
                />
            </Fragment>
          )}
          {value === 1  && (
            <Fragment>
              <Units {...this.props} {...this.state} />
            </Fragment>
          )}
        </Paper>
        </AppBar>
      </div>
      </div>
    )
  }
}

viewResourceDetail.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapState = state => {
  return {
    logInUser: state.logInUsers,
    themecolors: state.themecolors,
    devices: state.devices,
    users: state.users,
    groups: state.groups,
    accounts: state.accounts,
    roles: state.roles,
    ServerSetting: state.ServerSetting
  }
}
const mapStateToProps = connect(mapState)
export const ViewResourceDetail = mapStateToProps(
  withTranslationWrapper(withStyles(styles)(viewResourceDetail))
)



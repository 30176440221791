import React, { Component, Fragment } from 'react'
//import Access from './Components/Access'
import AccountUser from './Components/accountUses'
import AccountUnits from './Components/accountUnits'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import Paper from '@mui/material/Paper'
import AppBar from '@mui/material/AppBar'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { connect } from 'react-redux'
import { checkPrivileges } from '../../../Helpers'
import UpdateUserDetail from './Components/updateAccount'
import { Serversettings } from '../../ServerSettings/serverSettings'
import UpdateThemes from './Components/updateThemes'
import Style from 'style-it'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Breadcrumb from '../../common/Breadcrumb'
import Button from '../../common/Button'
import {WaslApi} from '../../ServerSettings/waslApi.js'
import withTranslationWrapper from '../../../HOC/HocTranslate.js'
const styles = theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    position: 'relative',
    padding: theme.spacing(1) * 2,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1) * 2
  },
  nested: {
    paddingLeft: theme.spacing(1) * 4
  }
})
class viewAccountDetail extends Component {
  constructor (props) {
    super(props)
    this.state = {
      value: 'edit',
      units: '',
      selectedAccount: ''
    }
  }

  componentWillMount () {
    if(this.props.AccountSettings){
      this.setState({
        selectedAccount: this.props.ServerSetting 
      })       
    }
    else {
      this.setState({
        selectedAccount: this.props.selectItem
      })
    }
  }
  UpdateSelectedItem = item => {
    this.setState({
      selectedAccount: item
    })
  }
  handleChange = (event, value) => {
    this.setState({
      value: this.props.logInUser.userType === 6 && value === 2 ? 3 : value
    })
  }

  render () {
    const { classes, logInUser } = this.props
    const { value } = this.state
    const orientation = window.innerWidth <= 959 ? 'horizontal' : 'vertical'
      let tabName = 'Edit';
      if(value==='settings'){tabName='Server Settings'}
      else if(value==='user'){tabName='Account User'}
      else if(value==='unit'){tabName='Account Units'}
      else if(value==='theme'){tabName='Theme Settings'}
      else if(value==='waslApi'){tabName='Wasl Api Key'}
    if (logInUser) {
      return (
        <div className={classes.root}>
            <Breadcrumb
              itemName={this.props.selectItemName}
              tabName={tabName}
              onClose={this.props.onClose}
              moduleName={this.props.translate('accountDetails')}
              backLink={!this.props.AccountSettings ? (<Button size="small" onClick={this.props.onClose}>{this.props.translate('sharedBack')}</Button>) : null}
            />
          <Style>{`
          .tab-vertial-wrapper > .custom-tabs {
            background: ${this.props.themecolors.backgroundColor};
          }
          .custom-tab-button2 .MuiTab-wrapper {
            align-items: normal;
            flex-direction: row;
            justify-content: unset;

          }
          .custom-tab-button2 {
            color: ${this.props.themecolors.textColor};
            text-align: left;
            text-transform: inherit;
            border-bottom: 1px solid rgba(0,0,0,0.05)
          }
          .custom-tab-button {
            min-height: 29px;
            border-radius: 6px 6px 0 0;
            margin: 0 2px;
            background: ${this.props.themecolors['600']};
            color: ${this.props.themecolors['themeInverse']};
          }
          .custom-tabs {
            min-height: 30px;
            margin: 10px 6px 0;
          }
         .Mui-selected  {  
            color: ${this.props.themecolors["500"]}  !important;
          }
          .custom-tab-button-selected2 {
            color: ${this.props.themecolors['500']};
          }`}</Style>
          
            <div className="v-tabs">
              <AppBar elevation={0} position="static" style={{background: 'transparent'}} className="tab-vertial-wrapper">
                <Tabs
                  value={value}
                  onChange={this.handleChange}
                  orientation={orientation}
                  classes={{ root: 'custom-tabs' }}
                  TabIndicatorProps={{
                    style: { background: 'transparent' }
                  }}
                >
                  <Tab
                    value="edit"
                    classes={{
                      root: 'custom-tab-button2',
                      selected: 'custom-tab-button-selected2'
                    }}
                  style={{alignItems:"flex-start", }}

                    label={<div style={{ display: "flex", alignItems: "center" }}><KeyboardArrowRightIcon color='currentColor' />{this.props.translate('sharedEdit')}</div>}
                  />
                  {logInUser && logInUser.userType === -1 ? (<Tab
                    value="settings"
                    classes={{
                      root: 'custom-tab-button2',
                      selected: 'custom-tab-button-selected2'
                    }}
                  style={{alignItems:"flex-start", }}

                    label={<div style={{ display: "flex", alignItems: "center" }}><KeyboardArrowRightIcon color='currentColor' />{this.props.translate('serverSettings')}</div>}
                  />) : null}
                  <Tab
                    value="user"
                    classes={{
                      root: 'custom-tab-button2',
                      selected: 'custom-tab-button-selected2'
                    }}
                  style={{alignItems:"flex-start", }}

                    label={<div style={{ display: "flex", alignItems: "center" }}><KeyboardArrowRightIcon color='currentColor' />{this.props.translate('accountUser')}</div>}
                  />
                  {this.props.logInUser.userType !== 6 && (
                    <Tab
                      value="unit"
                      classes={{
                        root: 'custom-tab-button2',  
                        selected: 'custom-tab-button-selected2'
                      }}
                  style={{alignItems:"flex-start", }}

                      label={<div style={{ display: "flex", alignItems: "center" }}><KeyboardArrowRightIcon color='currentColor' />{this.props.translate('accountUnits')}</div>}
                    />
                  )}
                {checkPrivileges('themeChange') && 
                 <Tab
                  value="theme"
                    classes={{
                      root: 'custom-tab-button2',
                      selected: 'custom-tab-button-selected2'
                    }}
                  style={{alignItems:"flex-start", }}

                    label={<div style={{ display: "flex", alignItems: "center" }}><KeyboardArrowRightIcon color='currentColor' />{this.props.translate('themeSettings')}</div>}
                  />}
                {checkPrivileges('accountUpdate') /* && checkPrivileges('waslUpdate') */ && 
                 <Tab
                    value="waslApi"
                    classes={{
                      root: 'custom-tab-button2',
                      selected: 'custom-tab-button-selected2'
                    }}
                  style={{alignItems:"flex-start", }}

                    label={<div style={{ display: "flex", alignItems: "center" }}><KeyboardArrowRightIcon color='currentColor' />{this.props.translate('waslApiKey')}</div>}
                  />}
                </Tabs>
                <Paper style={{ padding: 16, minHeight: 270, flex: 1 }} square={true}>
                  {value === 'edit' && checkPrivileges('userUpdate') && (
                    <Fragment>
                      <UpdateUserDetail
                        {...this.props}
                        {...this.state}
                        UpdateSelectedItem={this.UpdateSelectedItem}
                      />
                    </Fragment>
                  )}
                  {value === 'settings' && (
                    <Fragment>
                      <Serversettings
                        updateOtherServerSetting={true}
                        otherServerSetting={this.state.selectedAccount}
                        updateSelectedAccount={this.UpdateSelectedItem}
                        translate={this.props.translate}
                        getMoreAccounts={this.props.getMoreAccounts}
                      />
                    </Fragment>
                  )}
                  {value === 'user' && (
                    <Fragment>
                      <AccountUser {...this.props} {...this.state} />
                    </Fragment>
                  )}
                  {value === 'unit' && this.props.logInUser.userType !== 6 && (
                    <Fragment>
                      <AccountUnits {...this.props} {...this.state} />
                    </Fragment>
                  )}
                  {value === 'theme' && checkPrivileges('themeChange') && (
                    <Fragment>
                      <UpdateThemes
                        {...this.props}
                        {...this.state}
                        UpdateSelectedItem={this.UpdateSelectedItem}
                        hasAccessOfUpdate={checkPrivileges('accountUpdate')}
                      />
                    </Fragment>
                  )}
                  {value === 'waslApi' && /* checkPrivileges('wasl') && */ (
                    <Fragment>
                      <WaslApi
                        dispatch={this.props.dispatch}
                        location={this.props.location}
                        selectedItem={this.state.selectedAccount}
                        UpdateSelectedItem={this.UpdateSelectedItem}
                      />
                    </Fragment>
                  )}
                </Paper>
              </AppBar>
            </div>
        </div>
      )
    }
  }
}

viewAccountDetail.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapState = state => {
  return {
    logInUser: state.logInUsers,
    themecolors: state.themecolors,
    users: state.users,
    accounts: state.accounts,
    roles: state.roles,
    ServerSetting: state.ServerSetting
  }
}
const mapStateToProps = connect(mapState)
export const ViewAccountDetail = mapStateToProps(
  withTranslationWrapper(withStyles(styles)(viewAccountDetail))
)

import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import withTranslationWrapper from '../../HOC/HocTranslate'
import { withStyles } from '@mui/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import Checkbox from './CheckboxPermissions'
import Tooltip from '@mui/material/Tooltip'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
// import { lighten } from '@mui/styles/colorManipulator'
import { alpha } from '@mui/system';

import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import MenuItem from './MenuItem'
import styled from 'styled-components'
import NextPageIcon from '@mui/icons-material/NavigateNext'
import PrevPageIcon from '@mui/icons-material/NavigateBefore'
import FirstPageIcon from '@mui/icons-material/FirstPage'
import LastPageIcon from '@mui/icons-material/LastPage'
import { userLogInTypes } from './userConstant'
import TextField from './TextField'
import CircularProgress from '@mui/material/CircularProgress';
import Style from 'style-it'
import RestoreIcon from '@mui/icons-material/Restore'
import RemoveIcon from '@mui/icons-material/Remove';
import DoneIcon from '@mui/icons-material/Done';

function desc (a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}
function stableSort (array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map(el => el[0])
}

function getSorting (order, orderBy) {
  return order === 'desc'
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy)
}

function Renderhtml (props) {
  return <div dangerouslySetInnerHTML={{ __html: props.value }}></div>
}
class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property)
  }

  render () {
    const { order, orderBy, classes, isEditable } = this.props

    return (
      <TableHead>
        <TableRow className={classes.tableRow}>
          {this.props.showCheckbox && (
            <TableCell
              key={'access'}
              style={{ borderColor: '#fff', padding: '0 10px' }}
            >
              {this.props.allUsersId ? (
                <Checkbox
                  checked={this.props.isChecked}
                  readonly={true}
                  indeterminate={this.props.isIndeterminate}
                  onChange={this.props.allUsersId}
                  canAssign={this.props.canAssign}
                  canRemove={this.props.canRemove}
                />
              ) : (
                'Access'
              )}
            </TableCell>
          )}
          {this.props.showCheckbox2 && (
            <TableCell
              key={'access'}
              style={{ borderColor: '#fff', padding: '0 10px' }}
            >
              {this.props.allUsersId ? (
                <Checkbox
                  checked={this.props.isChecked}
                  readonly={true}
                  indeterminate={this.props.isIndeterminate}
                  onChange={this.props.allUsersId}
                  canAssign={this.props.canAssign}
                  canRemove={this.props.canRemove}
                />
              ) : (
                'Access'
              )}
            </TableCell>
          )}
            {this.props.showGroupAccess && (
              <TableCell
                 style={{ borderColor: '#fff', padding: '0 10px' }}
              >
                Group Access
              </TableCell>
          )}
          {this.props.rowDefinition.map(
            row => (
              row && (
              <TableCell
                key={row.id}
                align={row.numeric ? 'right' : 'left'}
                padding={row.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === row.id ? order : false}
                style={{ borderColor: '#fff', padding: '0 10px' }}
              >
                <Tooltip
                  title='Sort'
                  placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
              )
            ),
            this
          )}
          {isEditable && (
            <TableCell
              key={-5}
              width={60}
              style={{ padding: '0 10px', borderColor: '#fff' }}
            />
          )}
        </TableRow>
      </TableHead>
    )
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
}

const toolbarStyles = theme => ({
  root: {
    minHeight: '44px',
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    zIndex: 1
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          backgroundColor:  alpha(theme.palette.secondary.light, 0.85)
        }
      : {
          backgroundColor: theme.palette.secondary.dark
        },
  spacer: {
    flex: '1 1 auto'
  },
  actions: {
    color: theme.palette.text.secondary
  },
  title: {
    fontFamily: 'inherit',
    flex: '0 0 auto',
    fontSize: 18,
    fontWeight: 500
  }
})

let EnhancedTableToolbar = props => {
  const {
    numSelected,
    classes,
    className,
    themecolors,
    column,
    selected,
    actionInHead,
    style
  } = props
  const ThemeIconButton = styled(IconButton)`
    background-color: ${props => themecolors['500']};
    border-radius: 0;
    color: ${props => themecolors['themeInverse']};
    &:hover {
      cursor: pointer;
      background-color: ${props => themecolors['600']};
    }
  `

  return (
    <Toolbar
      className={
        classNames(classes.root, {
          [classes.highlight]: numSelected > 0
        }) +
        ' ' +
        className
      }
      style={style}
    >
      <div>
        {
          <Typography className={classes.title} id='tableTitle' color='inherit'>
            {props.title}
          </Typography>
        }
      </div>
      <div className={classes.spacer} />
      {actionInHead && (
        <div className={classes.actions}>
          {numSelected > 0 ? (
            <Tooltip title='Delete'>
              <ThemeIconButton
                aria-label='Delete'
                color='inherit'
                onClick={props.onDelete}
              >
                <DeleteIcon fontSize='small' />
              </ThemeIconButton>
            </Tooltip>
          ) : null}
          {numSelected === 1 ? (
            <Tooltip title='Edit'>
              <ThemeIconButton
                aria-label='Edit'
                onClick={props.onEdit}
                color='inherit'
              >
                <EditIcon fontSize='small' />
              </ThemeIconButton>
            </Tooltip>
          ) : null}
        </div>
      )}
    </Toolbar>
  )
}

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired
}

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar)

const styles = theme => ({
  root: {
    width: '100%',
    paddingTop: 44,
    boxShadow: 'none',
    position: 'relative',
    border: `1px solid rgba(0,0,0,0.12)`
  },
  table: {
    minWidth: '100%'
  },
  tableWrapper: {
    overflow: 'auto'
  },
  tableRow: {
    height: 36,
    '&:hover .actionButton': {
      display: 'inline-block'
    },
    '& .actionButton': {
      cursor: 'pointer',
      marginLeft: 5,
      display: 'none'
    }
  },
  toolbar: {
    root: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      height: '44px',
      minHeight: 'inherit'
    },
    toolbar: {
      height: '44px',
      minHeight: 'inherit'
    }
  }
})

class EnhancedTable extends React.Component {
  constructor (props) {
    super(props)
    this.onEdit = this.onEdit.bind(this)
    this.onDelete = this.onDelete.bind(this)
    this.checkHandleChange = this.checkHandleChange.bind(this)
    this.makeSearch = this.makeSearch.bind(this)
    this.loginAsUser = this.loginAsUser.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.state = {
      order: 'asc',
      orderBy: 'calories',
      selected: [],
      data: this.props.rows,
      page: this.props.page || 0,
      rowsPerPage: this.props.pageSize || 5,
      total: this.props.total || 0,
      lastPage: this.props.lastPage || 0,
      searchValue: ''
    }
  }

  UNSAFE_componentWillReceiveProps (n) {
    let newSelected = []
    n &&
      n.rows.map(r => {
        if (r && r.check) {
          newSelected.push(r.id)
        }
        return null
      })

    this.setState({ selected: newSelected })
  }
  componentWillMount () {
    let newSelected = []
    this.props.rows.map(r => {
      if (r.check) {
        newSelected.push(r.id)
      }
      return null
    })

    this.setState({ selected: newSelected })
  }
  makeSearch (event) {
    this.props.SearchItem(event.target.value)
  }
  handleRequestSort = (event, property) => {
    const orderBy = property
    let order = 'desc'

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc'
    }

    this.setState({ order, orderBy })
  }

  handleSelectAllClick = event => {
    if (event.target.checked) {
      this.setState(state => ({ selected: state.data.map(n => n.id) }))
      return
    }
    this.setState({ selected: [] })
  }

  checkHandleChange = (event, item) => {
    this.props.checkHandleChange(event, item)
  }

  handleClick = (event, item, preState) => {
    item.loading = true
    const { selected } = this.state
    const selectedIndex = selected.indexOf(item.id)

    let newSelected = []

    if (selectedIndex === -1 && this.props.canAssign) {
      // add in array
      newSelected = newSelected.concat(selected, item.id)
    } else if (selectedIndex === 0 && this.props.canRemove) {
      //remove if exists
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1 && this.props.canRemove) {
      //remove if exists
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0 && this.props.canRemove) {
      //remove if exists

      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }

    if (this.props.canAssign || this.props.canRemove) {
      this.props.checkHandleChange(!preState, item)

      this.setState({ selected: newSelected })
    }
  }
  handleChangePage = action => {
    let { pagination } = this.props
    if (pagination) {
      if (action === 'first') {
        if (pagination.page > 1) {
          this.props.handleChangePage(1)
        }
      } else if (action === 'prev') {
        if (pagination.page && pagination.page > 1) {
          this.props.handleChangePage(pagination.page - 1)
        }
      } else if (action === 'next') {
        if (
          pagination.hasNext &&
          pagination.page &&
          pagination.lastPage &&
          pagination.lastPage > pagination.page
        ) {
          this.props.handleChangePage(pagination.page + 1)
        }
      } else if (action === 'last') {
        if (
          pagination.hasNext &&
          pagination.lastPage &&
          pagination.lastPage > pagination.page
        ) {
          this.props.handleChangePage(pagination.lastPage)
        }
      }
    }
  }

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value })
    this.props.handleChangeRowsPerPage(event.target.value)
  }

  isSelected = id => this.state.selected.indexOf(id) !== -1

  onEdit () {
    this.props.onEdit(this.state.selected)
  }

  onDelete () {
    this.props.onDelete(this.state.selected)
    this.setState({ selected: [] })
  }

  onClose () {
    this.props.onClose()
  }

  loginAsUser (event, selected) {
    event.stopPropagation()
    this.props.loginAsUser(selected)
  }
  handleChange = (event, name) => {
    const { target } = event
    if (target) {
      this.props.loginAsUserhandleChange(name, event.target.value)
    }
  }
  stopthandleClick = e => {
    e.stopPropagation()
  }

  sortByCol = (prop, arr) => {
    prop = prop.split('.')
    var len = prop.length

    arr.sort((a, b) => {
      var i = 0
      while (i < len) {
        a = a[prop[i]]
        b = b[prop[i]]
        i++
      }
      if (a > b) {
        return -1
      }
      return 1
    })
    return arr
  }

  render () {
    const { classes } = this.props
    const { order, orderBy, selected, page, rowsPerPage } = this.state
    const { rows: data = [], isCursoPointer, pagination } = this.props
    const emptyRows = data.length < 5 ? 5 - data.length : 0

    let dataRows = this.sortByCol('check', [...data])

    return (
      <Paper
        className={classes.root}
        style={{ paddingTop: this.props.title ? '44px' : 0 }}
      >
        {this.props.title && (
          <EnhancedTableToolbar
            actionInHead={this.props.actionInHead}
            column={this.props.showInHead}
            selected={this.state.selected}
            insideDialog={this.props.insideDialog}
            themecolors={this.props.themecolors}
            numSelected={selected.length}
            title={this.props.title}
            onEdit={this.onEdit}
            onDelete={this.onDelete}
            onClose={this.onClose}
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              left: 0,
              background: this.props.themecolors['500'],
              color: this.props.themecolors['themeInverse'],
              paddingRight: this.props.insideDialog ? '44px' : 'def',
              paddingLeft: this.props.insideDialog ? '44px' : 'def'
            }}
          />
        )}
        <div className={classes.tableWrapper}>
          {this.props.searchable && (
            <div style={{ padding: '10px 10px 0', textAlign: 'right' }}>
              <TextField
                fullWidth
                // label={'Search...'}
                label={this.props.translate('search')}
                onChange={this.makeSearch}
              />
            </div>
          )}
          <Table
            className={classes.table + ' custom-table ' + this.props.className}
            aria-labelledby='tableTitle'
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              isEditable={this.props.isEditable}
              loginAsUser={this.loginAsUser}
              handleChange={this.handleChange}
              rowCount={dataRows.length}
              showCheckbox={this.props.showCheckbox}
              showCheckbox2={this.props.showCheckbox2}
              showGroupAccess={this.props.showGroupAccess}
              isChecked={this.props.isChecked}
              isIndeterminate={this.props.isIndeterminate}
              allUsersId={this.props.allUsersId}
              canAssign={this.props.canAssign}
              canRemove={this.props.canRemove}
              rowDefinition={
                this.props.rowDefinition || [
                  {
                    id: 'name',
                    numeric: false,
                    disablePadding: true,
                    label: 'Name'
                  }
                ]
              }
              classes={classes}
            />

            <TableBody>
              {stableSort(dataRows, getSorting(order, orderBy)).map(n => {
                const isSelected = this.isSelected(n.id)
                return (
                  <TableRow
                    hover
                    role='checkbox'
                    aria-checked={isSelected}
                    tabIndex={-1}
                    key={n.id}
                    //selected={isSelected}
                    className={
                      classes.tableRow + ' ' + (isCursoPointer && 'pointer')
                    }
                  >
                    {this.props.showCheckbox &&
                      (n.id === this.props.selectItemParentId &&
                      n.id &&
                      this.props.selectItemParentId ? (
                        <TableCell
                          padding='checkbox'
                          width='20'
                          style={{ padding: 0, textAlign: 'center' }}
                        >
                          {/* <FiberManualRecordIcon
                            style={{
                              marginLeft: -25,
                              color: 'rgb(0, 193, 121)'
                            }}
                          /> */}
                          <Checkbox
                            checked={n.check}
                            readonly={n.readonly}
                            indeterminate={n.indeterminate}
                            onChange={event =>
                              this.handleClick(event, n, isSelected)
                            }
                            canAssign={this.props.canAssign}
                            canRemove={this.props.canRemove}
                          />
                        </TableCell>
                      ) : (
                        <TableCell
                          padding='checkbox'
                          width='20'
                          style={{ padding: 0, textAlign: 'center' }}
                        >
                          <Checkbox
                            checked={n.check}
                            readonly={n.readonly}
                            indeterminate={n.indeterminate}
                            onChange={event =>
                              this.handleClick(event, n, isSelected)
                            }
                            canAssign={this.props.canAssign}
                            canRemove={this.props.canRemove}
                          />
                        </TableCell>
                      ))}
                    {this.props.showCheckbox2 &&
                      (n.parentId === this.props.selectItemParentId &&
                      n.id &&
                      this.props.selectItemParentId ? (
                        <TableCell
                          padding='checkbox'
                          width='20'
                          style={{ padding: 0, textAlign: 'center' }}
                        >
                          <FiberManualRecordIcon
                            style={{
                              marginLeft: -25,
                              color: 'rgb(0, 193, 121)'
                            }}
                          />
                        </TableCell>
                      ) : (
                        <TableCell
                          padding='checkbox'
                          width='20'
                          style={{ padding: 0, textAlign: 'center' }}
                        >
                          {n.loading ?
                          <CircularProgress 
                            size={20} 
                            color='inherit'
                            style={{marginLeft:-30}}
                          />:
                          <Checkbox
                            checked={n.check}
                            readonly={n.readonly}
                            indeterminate={
                              n && n.indeterminate
                              // n.check === false ? n.indeterminate : false
                            }
                            onChange={event =>
                              this.handleClick(event, n, isSelected)
                            }
                            canAssign={this.props.canAssign}
                            canRemove={this.props.canRemove}
                          />}
                        </TableCell>
                      ))}
                      {this.props.showGroupAccess && (
                          <TableCell
                            padding='checkbox'
                            width='20'
                            style={{ padding: 0, textAlign: 'center' }}
                          >
                            {n.groupAccess?
                              <DoneIcon/>: n.groupAccess ===0 ? '': <RemoveIcon/>}
                          </TableCell>
                          )}
                    {this.props.rowDefinition.map(r => {
                      if(r){
                      if (
                        r.id === 'loginAsUser' &&
                        (!localStorage.getItem('adminToken') ||
                          localStorage.getItem('adminToken') === '')
                      ) {
                        return (
                          <TableCell
                            key={r.id}
                            component='td'
                            scope='row'
                            style={{
                              fontSize: 8,
                              display: 'flex',
                              alignItems: 'center',
                              padding: '0 10px',
                              width: 182
                            }}
                          >
                            <TextField
                              key={n.id + n.name}
                              select
                              label={''}
                              value={n.logInAsUserState || ''}
                              onChange={e => this.handleChange(e, n.id)}
                              onClick={e => this.stopthandleClick(e)}
                              SelectProps={{
                                MenuProps: {
                                  className: classes.menu
                                }
                              }}
                              style={{
                                fontWeight: 300,
                                width: n.logInAsUserState ? 85 : 44,  // Conditional width based on value
                                maxWidth: 85
                              }}
                              margin='dense'
                              fullWidth
                            >
                              {userLogInTypes(
                                this.props.ServerSetting.attributes,
                                '',
                                n.userType
                              ).map(option => (
                                <MenuItem key={option.id} value={option.id}>
                                  {option.name}
                                </MenuItem>
                              ))}
                            </TextField>
                            <a
                              href='javascript:void(0)'
                              onClick={e => this.loginAsUser(e, [n])}
                            >
                              <ExitToAppIcon className='' fontSize='small' />
                            </a>
                          </TableCell>
                        )
                      }
                      if (r.id === 'restore') {
                        return (
                          <TableCell
                            key={r.id}
                            component='td'
                            scope='row'
                            style={{
                              padding: '0 10px',
                              whiteSpace: 'nowrap',
                              color: 'inherit'
                            }}
                          >
                            {' '}
                            {localStorage.getItem('adminToken') ? null : (
                              <div
                                style={{
                                  display: 'flex',
                                  flex: 1,
                                  alignItems: 'center'
                                }}
                              >
                                <a
                                  href={null}
                                  onClick={e => this.loginAsUser(e, [n])}
                                  style={{
                                    marginLeft: 10,
                                    marginTop: 3,
                                    display: 'inline-block'
                                  }}
                                >
                                  <RestoreIcon className='' fontSize='small' />
                                </a>
                              </div>
                            )}
                          </TableCell>
                        )
                        } else {
                          return (
                            <TableCell
                              key={r.id}
                              component='td'
                              scope='row'
                              style={{ padding: '0 10px', whiteSpace: 'nowrap' , overflow: 'hidden', maxWidth:'100px'}}
                            >
                              {r.translate ? (
                                this.props.translate(r.prefix + n[r.id])
                              ) : r.id !== 'expression' ? (
                                n[r.id]
                              ) : (
                                <Renderhtml value={n[r.id]} />
                              )}
                            </TableCell>
                          )
                        }
                      }
                    }
                    )}
                    {this.props.isEditable && (
                      <TableCell
                        component='th'
                        scope='row'
                        style={{
                          padding: '0 10px',
                          whiteSpace: 'nowrap',
                          minWidth: 60
                        }}
                      >
                        {this.props.canUpdate && (
                          <Tooltip title='Edit'>
                            <EditIcon
                              className='actionButton'
                              fontSize='small'
                              onClick={e => this.props.onEdit([n])}
                            />
                          </Tooltip>
                        )}
                        {this.props.canDelete && (
                          <Tooltip title='Delete'>
                            <DeleteIcon
                              className='actionButton'
                              fontSize='small'
                              onClick={e => this.props.onDelete([n])}
                            />
                          </Tooltip>
                        )}
                         {this.props.hasAccessOfDelete  && (
                          <Tooltip title={ 'Delete'}>
                              <a href={null} onClick={e => this.props.onDelete([n])}
                                style={{
                                marginLeft: this.props.restore ? 5 : '',
                                display: 'inline-block'
                              }}>
                                <DeleteIcon
                                  className= { 'actionButton'}
                                  fontSize={ 'small'}
                                />
                              </a>
                        </Tooltip>
                        )}
                      </TableCell>
                    )}
                    
                  </TableRow>
                )
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 36 * emptyRows }}>
                  <TableCell colSpan={6} style={{ padding: '0 10px' }} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        {
          <div
            style={{
              padding: '0 20px',
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <span style={{ marginRight: 10 }} className='hidden-responsive'>
              {this.props.translate('rowsPerPage')}
            </span>
            <span style={{ marginRight: 10, minWidth: 60 }}>
              <TextField
                id='rowsPerPage'
                select
                style={{width: 60}}
                value={(pagination && pagination.pageSize) || 5}
                onChange={e => this.handleChangeRowsPerPage(e)}
                margin='dense'
              >
                {rowsPerPageOptions.map(option => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </span>
            <span style={{ flex: 1 }}></span>

            <span style={{ marginRight: 5, whiteSpace: 'nowrap' }}>
              {this.props.translate('page')}{' '}
              <strong>{pagination && pagination.page}</strong>{' '}
              <span className='visible-responsive'>
                {' '}
                / {pagination && pagination.lastPage} -{' '}
                {pagination && pagination.total}{' '}
              </span>
            </span>
            <span style={{ marginRight: 10 }} className='hidden-responsive'>
              {this.props.translate('outOf')}{' '}
              <strong>{pagination && pagination.lastPage}</strong>
            </span>
            <span style={{ marginRight: 10 }} className='hidden-responsive'>
              {this.props.translate('totalRecords')}
              {'  '}
              <strong>{pagination && pagination.total}</strong>
            </span>
            <span style={{ marginRight: 10, cursor: 'pointer'  }}>
              <FirstPageIcon
                fontSize='small'
                onClick={e => this.handleChangePage('first')}
              />
            </span>
            <span style={{ marginRight: 10 ,cursor: 'pointer' }}>
              <PrevPageIcon
                fontSize='small'
                onClick={e => this.handleChangePage('prev')}
              />
            </span>
            <span style={{ marginRight: 10, cursor: 'pointer'}}>
              <NextPageIcon
                fontSize='small'
                onClick={e => this.handleChangePage('next')}
              />
            </span>
            <span style={{ marginRight: 10, cursor: 'pointer'}}>
              <LastPageIcon
                fontSize='small'
                onClick={e => this.handleChangePage('last')}
              />
            </span>
          </div>
        }
      </Paper>
    )
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withTranslationWrapper(withStyles(styles)(EnhancedTable))
const rowsPerPageOptions = [5, 10, 15, 20, 25, 50, 100, 500]

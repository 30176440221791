import React, { Component } from 'react'
import clsx from 'clsx'
import { makeStyles, useTheme } from '@mui/styles'
import Drawer from '@mui/material/Drawer'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import List from '@mui/material/List'
import CssBaseline from '@mui/material/CssBaseline'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { checkPrivileges, setCookie } from '../../Helpers'
import Style from 'style-it'
import { NavLink } from 'react-router-dom'
import { Tooltip, Icon } from '@mui/material'
import withTranslationWrapper from '../../HOC/HocTranslate'
import { withStyles, createStyles } from '@mui/styles'
import { ReactComponent as accountsIcon } from './../../assets/nav/accounts.svg'
import { ReactComponent as usersIcon } from './../../assets/nav/users.svg'
import { ReactComponent as unitsIcon } from './../../assets/nav/units.svg'
import { ReactComponent as groupsIcon } from './../../assets/nav/groups.svg'
import { ReactComponent as serverSettingsIcon } from './../../assets/nav/server-settings.svg'
import { ReactComponent as sysLogsIcon } from './../../assets/nav/sys-logs.svg'
import { ReactComponent as roleManageIcon } from './../../assets/nav/role-manage.svg'
import { ReactComponent as tempGenIcon } from './../../assets/nav/tem-gen.svg'
import { ReactComponent as trashIcon } from './../../assets/nav/trash.svg'
import { ReactComponent as couponIcon } from './../../assets/nav/voucher.svg'
import { ReactComponent as accountsSettings } from './../../assets/nav/accountsSettings.svg'
import { ReactComponent as actionLog } from './../../assets/nav/actionLog.svg'
import { ReactComponent as billingIcon } from './../../assets/nav/billings.svg'
import './index.scss'
import Scrollbar from 'react-scrollbars-custom'
const drawerWidth = 195

const useStyles = theme => ({
  root: {
    display: 'flex'
  },
  appBar: {
    backgroundColor: 'transparent',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    boxShadow: 'none'
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
    // transition: '2.3s ease'
  },
  menuButton: {
    // marginRight: 36
    // marginLeft: -25
  },
  hide: {
    display: 'none'
  },
  drawer: {
    flexShrink: 0,
    whiteSpace: 'nowrap',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerOpen: {
    backgroundColor: '#fff',
    width: drawerWidth,
    // top: 47,
    bottom: 0,
    height: 'auto',
    transition: '0.3s ease',
    marginTop: localStorage.getItem('adminToken') || localStorage.getItem('userToken') ? 0 : 47,
  },
  drawerClose: {
    // top: 47,
    bottom: 0,
    height: 'auto',
    overflowX: 'hidden',
    width: 50,
    transition: '0.3s ease',
    marginTop: localStorage.getItem('adminToken') || localStorage.getItem('userToken') ? 0 : 47,
    // width: theme.spacing(7) + 1,
    // [theme.breakpoints.up('sm')]: {
    //   width: theme.spacing(9) + 1
    // }
  },

  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    backgroundColor: 'transparent'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  }
})



class MainSideBar extends Component {
  constructor (props) {
    super(props)
    this.state = {
      open: true,
      navigation: {
        // accounts: {
        //   checked:
        //     checkPrivileges('account') &&
        //     props.userInfo.userType !== 3 && props.userInfo.userType !== 2,
        //   href: '/accounts',
        //   icon: accountsIcon,
        //   title: 'accounts'
        // },
        users: {
          checked: checkPrivileges('user'),
          href: '/users',
          icon: usersIcon,
          title: 'users'
        },
        units: {
          // checked: checkPrivileges('device') && props.userInfo.userType !== 6,
          checked: checkPrivileges('device'),
          href: '/units',
          icon: unitsIcon,
          title: 'units'
        },
        billing: {
          // checked: checkPrivileges('device') && props.userInfo.userType !== 6,
          checked: checkPrivileges('invoice'),
          href: '/billings',
          icon: billingIcon,
          title: 'billing'
        },
        groups: {
          checked: checkPrivileges('group') && props.userInfo.userType !== 6,
          href: '/groups',
          icon: groupsIcon,
          title: 'resources'
        },
        serverSettings: {
          checked: checkPrivileges('account') && props.userInfo.userType === -1,
          href: '/serverSettings',
          icon: serverSettingsIcon,
          title: 'serverSettings'
        },
        systemLogs: {
          checked: props.userInfo.userType === -1,
          href: '/systemLogs',
          icon: sysLogsIcon,
          title: 'systemLogs'
        },
        actionLogs: {
          checked: true,
          href: '/actionLogs',
          icon: actionLog,
          title: 'actionLogs'
        },
        roleManagement: {
          // checked: checkPrivileges('role') && props.userInfo.userType === -1,
          checked: checkPrivileges('role') ,
          href: '/roleManagement',
          icon: roleManageIcon,
          title: 'roleManagement'
        },
        templateGenerator: {
          checked: checkPrivileges('rptmpl') && props.userInfo.userType !== 6,
          href: '/templateGenerator',
          icon: tempGenIcon,
          title: 'templateGenerator'
        },
        trash: {
          checked: true,
          href: '/trash',
          icon: trashIcon,
          title: 'trash'
        },
        coupon: {
          checked: props.userInfo.userType === -1,
          href: '/coupon',
          icon: couponIcon,
          title: 'coupon'
        },
        accountsSettings: {                               
          checked:
            checkPrivileges('account') &&
            props.userInfo.userType === -1,
          href: '/accountSettings',
          icon: accountsSettings,
          title: 'accountSettings'
        },
        webHooks: {                               
          checked:
            props.userInfo.userType === -1,
          href: '/webHooks',
          icon: serverSettingsIcon,
          title: 'webHooks'
        }
      }
    }
  }

  render () {
    const { classes } = this.props
    
    return <div className={classes.root+" main-navigation"}>
      
      <Drawer
        variant='permanent'
        classes={{
          paper: clsx({
            [classes.drawerOpen]: this.props.sidebarToggle,
            [classes.drawerClose]: !this.props.sidebarToggle
          })
        }}
      >
        <Scrollbar>
      <List>
        {Object.keys(this.state.navigation).map((keyName, i) => {
          if (this.state && this.state.navigation && this.state.navigation[keyName] && this.state.navigation[keyName].checked) {
            let item = this.state.navigation[keyName]
            const { icon: ItemIcon } = item;

          return(
            <Tooltip
              key={i}
              title={this.props.translate(
                this.state.navigation[keyName].title
              )}
            >
              <li key={i}>
                  <ListItem button to={this.state.navigation[keyName].href} component={NavLink} key={keyName} className='listItem'>
                    <ListItemIcon style={{ Width: 35, color: 'inherit' }}>
                      <ItemIcon fill='currentColor' width={20} height={20} style={{marginLeft : -2}}/>
                      <ListItemText
                        style={{ marginTop: 0, marginBottom: 0, paddingLeft: 18 }}
                        primary={this.props.translate(
                          this.state.navigation[keyName].title
                        )}
                      />
                    </ListItemIcon>
                  </ListItem>
                </li>
            </Tooltip>
          )
          } 
          
        })}
      </List>
    </Scrollbar>
    </Drawer>
  </div>
  }
}
export default withTranslationWrapper(withStyles(useStyles)(MainSideBar))

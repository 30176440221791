import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
 import { compose } from 'redux'
import TextField from './../common/TextField'
import { withStyles } from '@mui/styles'
import MenuItem from '@mui/material/MenuItem'
import Grid from '@mui/material/Grid'
import Button from '../common/Button'
import isEqual from 'react-fast-compare'
import { logInUserInfo } from './../../Actions/Users'
import { timeZonesTypes } from './../Attributes/timezone'
import SingleSelect from '../common/SingleSelect'
import { checkPrivileges } from '../../Helpers'
import { notifyError, notifySuccess } from '../../Utils/CustomNotifcations'
import {
  checkUnitsWithStandardType,
  measurementStandard,
  currencies
} from '../Users/userDataTypes'
import withTranslationWrapper from '../../HOC/HocTranslate'

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  dense: {
    marginTop: 19
  },
  menu: {
    width: 200
  },
  button: {
    margin: theme.spacing(1)
  }
})
const defaulrValues = {
  measurementStandard: '',
  'web.liveRouteLength': '',
  'web.selectZoom': '',
  'web.maxZoom': '',
  'ui.hidePositionAttributes': '',
  distanceUnit: '',
  speedUnit: '',
  volumeUnit: '',
  timezone: ''
}

const   languages=[
  { name: "English", code: "en" },
  { name: "French", code: "fr" },
  { name: "Urdu", code: "ur" },
  { name: "Arabic", code: "ar" },
  { name: "Albanian", code: "sq" },
  { name: "Burmese", code: "my" },
  { name: "Chinese", code: "zh" },
  { name: "Central Khmer", code: "km" },
  { name: "German", code: "de" },
  { name: "Gujarati", code: "gu" },
  { name: "Hebrew", code: "he" },
  { name: "Hindi", code: "hi" },
  { name: "Hungarian", code: "hu" },
  { name: "Indonesian", code: "id" },
  { name: "Italian", code: "it" },
  { name: "Japanese", code: "ja" },
  { name: "Korean", code: "ko" },
  { name: "Mongolian", code: "mn" },
  { name: "Nepali", code: "ne" },
  { name: "Persian", code: "fa" },
  { name: "Portuguese", code: "pt" },
  { name: "Russian", code: "ru" },
  { name: "Serbian", code: "sr" },
  { name: "Spanish", code: "es" },
  { name: "Telugu", code: "te" },
  { name: "Thai", code: "th" },
  { name: "Turkish", code: "tr" },
  { name: "Amharic", code: "am" },
  { name: "Kurdish", code: "ku" },
]
class UserDefault extends Component {
  constructor () {
    super()
    this.state = {
      isVisableAddBtn: false,
      form: { attributes: { ...defaulrValues } }
    }
    this.formSubmit = this.formSubmit.bind(this)
    this.formReset = this.formReset.bind(this)
  }
  componentWillMount () {
    this.formReset()
  }
  formReset () {
    this.setState({
      form: {
        ...this.state.form,
        attributes: {
          ...this.props.logInUser.attributes
        }
      },
      isVisableAddBtn: false
    })
  }
  formSubmit () {
    let { logInUser } = this.props
    logInUser.attributes = { ...this.state.form.attributes }
    fetch(`api/users/${logInUser.id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        ...logInUser
      })
    })
      .then(response => {
          response.json().then(res => {
            if(res.status ==='success'){
              let user = res.data
              this.props.dispatch(logInUserInfo(user))
              this.props.setActiveLanguage(user.attributes.lang || 'en')
              this.props.dispatch(
                notifySuccess('userDefaultInfoIsUpdated')
              )
            }
            else if(res?.statusCode === '440'){
              window.location.replace('/login')
            }
            else if(res.statusCode){
              var err = res?.message.split(':')
              err[1] =err[1].replace(')', "")
              this.props.dispatch(
                notifyError(this.props.translate(err[1]))
              )
            }
            else {
              throw response
            }
          })
          this.setState({
            isVisableAddBtn: false
          })
      
      })
      .catch(e => {
        this.props.dispatch(notifyError('somethingWentWrong'))
      })
  }
  changeAttribute2 = (name, event) => {
    let value =
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value
    if (!value) {
      if (this.state.form.attributes[name]) {
        delete this.state.form.attributes[name]
      }
      this.setState(
        {
          form: {
            ...this.state.form,
            attributes: {
              ...this.state.form.attributes
            }
          }
        },
        () => this.checkRequiredFields()
      )
    } else {
      if (name === 'measurementStandard') {
        let data = checkUnitsWithStandardType(value)
        this.setState(
          {
            form: {
              ...this.state.form,
              attributes: {
                ...this.state.form.attributes,
                [name]:
                  event.target.type === 'number' ? parseInt(value) : value,
                distanceUnit: data[0].distanceUnit.key,
                speedUnit: data[0].speedUnit.key,
                volumeUnit: data[0].volumeUnit.key
              }
            }
          },
          () => this.checkRequiredFields()
        )
      } else {
        this.setState(
          {
            form: {
              ...this.state.form,
              attributes: {
                ...this.state.form.attributes,
                [name]: event.target.type === 'number' ? parseInt(value) : value
              }
            }
          },
          () => this.checkRequiredFields()
        )
      }
    }
  }

  timezone_handleChange = (name, value) => {
    if (!value || value.value === 'None') {
      if (this.state.form.attributes[name]) {
        delete this.state.form.attributes[name]
      }
      this.setState(
        {
          form: {
            ...this.state.form,
            attributes: {
              ...this.state.form.attributes
            }
          }
        },
        () => this.checkRequiredFields()
      )
    } else {
      this.setState(
        {
          form: {
            ...this.state.form,
            attributes: {
              ...this.state.form.attributes,
              [name]: value.value
            }
          }
        },
        () => this.checkRequiredFields()
      )
    }
  }
  checkRequiredFields = () => {
    let value = !isEqual(
      this.state.form.attributes,
      this.props.logInUser.attributes
    )
    this.setState({
      isVisableAddBtn: value
    })
  }
  async searchFromZoneList (inputValue) {
    if (!inputValue) {
      return []
    }

    let result = timeZonesTypes.filter(i =>
      i.key.toLowerCase().includes(inputValue.toLowerCase())
    )
    return result.map(item => {
      return { id: item.key, key: item.key, label: item.key, value: item.key }
    })
  }
  render () {
    let defaultZones = []
    timeZonesTypes.map((item, i) => {
      if (i <= 9) {
        defaultZones.push({
          id: item.key,
          key: item.key,
          label: item.key,
          value: item.key
        })
      }
    })
    const { classes, logInUser,  setActiveLanguage } = this.props
    const { form } = this.state
    return (
      <div>
        <Grid container spacing={2}>
          {/* <Grid item xs={12} md={3} sm={6}>
          <TextField
            id='web.liveRouteLength'
            label={this.props.translate('attributeWebLiveRouteLength')}
            placeholder=''
            type='number'
            value={
              (form.attributes &&
                form.attributes['web.liveRouteLength']) ||
              ''
            }
            onChange={e =>
              this.changeAttribute2('web.liveRouteLength', e)
            }
            variant='outlined'
            margin='dense'
            fullWidth
          />
        </Grid> */}
          {/* <Grid item xs={12} md={3} sm={6}>
            <TextField
              id='web.maxZoom'
              type='number'
              label={this.props.translate('attributeWebMaxZoom')}
              placeholder=''
              value={(form.attributes && form.attributes['web.maxZoom']) || ''}
              onChange={e => this.changeAttribute2('web.maxZoom', e)}
              variant='outlined'
              margin='dense'
              fullWidth
            />
          </Grid> */}
          {/* <Grid item xs={12} md={3} sm={6}>
            <TextField
              id='web.selectZoom'
              type='number'
              label={this.props.translate('attributeWebSelectZoom')}
              placeholder=''
              value={
                (form.attributes && form.attributes['web.selectZoom']) || ''
              }
              onChange={e => this.changeAttribute2('web.selectZoom', e)}
              variant='outlined'
              margin='dense'
              fullWidth
            />
          </Grid> */}
          <Grid item xs={12} md={3} sm={6}>
            <SingleSelect
              array={[]}
              async
              selectName='timezone'
              isClearable
              defaultOptions={defaultZones}
              loadOptions={this.searchFromZoneList}
              label={this.props.translate('sharedTimezone')}
              value={
                form.attributes && form.attributes['timezone']
                  ? {
                      key: form.attributes['timezone'] || '',
                      id: form.attributes['timezone'] || '',
                      label: form.attributes['timezone'] || ''
                    }
                  : ''
              }
              handleChange={this.timezone_handleChange}
              canAssign={true}
              styles={{
                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              }}
            />
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <TextField
              id='measurementStandardo'
              select
              label={this.props.translate('measurementStandard')}
              value={
                (form.attributes && form.attributes.measurementStandard) || ''
              }
              onChange={e => this.changeAttribute2('measurementStandard', e)}
              SelectProps={{
                MenuProps: {
                  className: classes.menu
                }
              }}
              margin='dense'
              fullWidth
            >
              {measurementStandard.map(option => (
                <MenuItem key={option.key} value={option.key}>
                  {this.props.translate(option.value)}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <TextField
              id='currencyLabel'
              select
              label={this.props.translate('currencies')}
              value={
                (form.attributes && form.attributes.currencyLabel) || ''
              }
              onChange={e => this.changeAttribute2('currencyLabel', e)}
              SelectProps={{
                MenuProps: {
                  className: classes.menu
                }
              }}
              margin='dense'
              fullWidth
            >
              
              {currencies.map(option => (
                <MenuItem key={option.key} value={option.value}>
                  {this.props.translate(option.key)}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {/* language */}
          <Grid item xs={12} md={3} sm={6}>
            <TextField
              id='language'
              select
              label={this.props.translate('languages')}
              value={(form.attributes && form.attributes['lang']) || ''}
              onChange={e => this.changeAttribute2('lang', e)}
              SelectProps={{
                MenuProps: {
                  className: classes.menu
                }
              }}
              margin='dense'
              fullWidth
            >
              <MenuItem value=''>
                <em>{this.props.translate('none')}</em>
              </MenuItem>
              {languages.map(language => (
                <MenuItem key={language.key} value={language.code}>
                  {this.props.translate(language.name)}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {/* {form.attributes && form.attributes.measurementStandard &&
          <Grid item xs={12} md={3} sm={6}>
            <TextField
              id='distanceUnit'
              select
              label={this.props.translate('settingsDistanceUnit')}
              value={
                (form.attributes &&
                  form.attributes.distanceUnit) ||
                ''
              }
              onChange={e =>
                this.changeAttribute2('distanceUnit', e)
              }
              SelectProps={{
                MenuProps: {
                  className: classes.menu
                }
              }}
              margin='dense'
              fullWidth
            >
              {checkUnitsWithStandardType(
                form.attributes.measurementStandard, 'distanceUnit').map(option => (
                  <MenuItem key={option.key} value={option.key}>
                    {this.props.translate(option.name)}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>}
        {form.attributes && form.attributes.measurementStandard &&
          <Grid item xs={12} md={3} sm={6}>
            <TextField
              id='speedUnit'
              select
              label={this.props.translate('settingsSpeedUnit')}
              value={
                (form.attributes &&
                  form.attributes.speedUnit) ||
                ''
              }
              onChange={e => this.changeAttribute2('speedUnit', e)}
              SelectProps={{
                MenuProps: {
                  className: classes.menu
                }
              }}
              margin='dense'
              fullWidth
            >
              {checkUnitsWithStandardType(
                form.attributes.measurementStandard, 'speedUnit').map(option => (
                  <MenuItem key={option.key} value={option.key}>
                    {this.props.translate(option.name)}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>}
        {form.attributes && form.attributes.measurementStandard &&
          <Grid item xs={12} md={3} sm={6}>
            <TextField
              id='volumeUnit'
              select
              label={this.props.translate('settingsVolumeUnit')}
              value={
                (form.attributes &&
                  form.attributes.volumeUnit) ||
                ''
              }
              onChange={e => this.changeAttribute2('volumeUnit', e)}
              SelectProps={{
                MenuProps: {
                  className: classes.menu
                }
              }}
              margin='dense'
              fullWidth
            >
              {checkUnitsWithStandardType(
                form.attributes.measurementStandard, 'volumeUnit').map(option => (
                  <MenuItem key={option.key} value={option.key}>
                    {this.props.translate(option.name)}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>} */}
        </Grid>
        <br />
        {checkPrivileges('userUpdate') && (
          <Fragment>
            <Button
              variant='contained'
              onClick={this.formReset}
              style={{ marginLeft: 8, marginRight: 8 }}
            >
              {' '}
              {this.props.translate('resetButton')}
            </Button>
            <Button
              variant='contained'
              disabled={!this.state.isVisableAddBtn}
              onClick={() => this.formSubmit()}
            >
              {' '}
              {this.props.translate('sharedSave')}
            </Button>
          </Fragment>
        )}
      </div>
    )
  }
}

// const enhance = compose(withStyles(styles), connect())
// export default enhance(UserDefault)

export default withTranslationWrapper(withStyles(styles)(UserDefault))

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import PeopleIcon from '@mui/icons-material/People'
import { connect } from 'react-redux'
import withTranslationWrapper from '../../HOC/HocTranslate'
// import { withSnackbar } from 'notistack'
import AllInclusiveIcon from '@mui/icons-material/AllInclusive'
import { makeStyles } from '@mui/styles'
import CustomDialog from '../../Components/common/Dialog'
import { notifyError, notifySuccess } from '../../Utils/CustomNotifcations'

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    position: 'relative',
    padding: theme.spacing(1) * 2,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1) * 2
  },
  nested: {
    paddingLeft: theme.spacing(1) * 4
  }
})

const useStyles = makeStyles({
  root: {
    minWidth: 220,
    background: '#045266'
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  },
  h5: {
    padding: '25px'
  },
  body2: {
    padding: '25px'
  }
})
class viewStatistics extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isDeviceCountRecived: false,
      isUserCountRecived: false,
      selectedUserLimits: '',
      selectedUnitsLimits: '',
      devicesLoad:true,
      usersLoad:true
    }
  }

  componentWillMount () {
    this.showStatistics(this.props.selectItem)
  }
  componentWillReceiveProps (nextProps) {
    this.showStatistics(nextProps.selectItem)
  }
  showStatistics = selectItem => {
    //count call for devices
    if (selectItem) {
      fetch(`/api/devices/count?userId=${selectItem.id}`).then(response => {
        if (response.ok) {
          response.json().then(res => {
            if(res.status ==='success'){   
              let limits= res.data
              this.setState({
                selectedUnitsLimits: limits,
                isDeviceCountRecived: true,
                devicesLoad:false
              })
            }
            else if(res?.statusCode === '440'){
              window.location.replace('/login')
            }
            else if(res.statusCode){
              var err = res?.message.split(':')
              err[1] =err[1].replace(')', "")
              this.props.dispatch(
                notifyError(err[1])
              )
            }
          })
        }
        else{
          throw response
        }
      })
      .catch(e => {
        this.props.dispatch(notifyError('somethingWentWrong'))
      })
      //count call for users
      if (selectItem.userType !== 1) {
        fetch(`/api/users/count?userId=${selectItem.id}`).then(response => {
          if (response.ok) {
            response.json().then(res => {
              if(res.status ==='success'){
                let limits = res.data
                this.setState({
                  selectedUserLimits: limits,
                  isUserCountRecived: true,
                  usersLoad:false
                })
              }
              else if(res?.statusCode === '440'){
                window.location.replace('/login')
              }
              else if(res.statusCode){
                var err = res?.message.split(':')
                err[1] =err[1].replace(')', "")
                this.props.dispatch(
                  notifyError(this.props.translate(err[1])
                )
                )
              }
            })
          }
          else{
            throw response
          }
        }).catch(e => {
          this.props.dispatch(notifyError('somethingWentWrong'))
        })
      } else {
        this.setState({
          isUserCountRecived: true,
        })
      }
    }
  }
  prepareData = () => {
    let row = {
      name: this.props.selectItem.name,
      userCount: [
        this.state.selectedUserLimits.totalCreatedUser + '/',
        this.state.selectedUserLimits.totalUserLimit === -1 ? (
          <AllInclusiveIcon style={{ verticalAlign: 'middle', fontSize: 14 }} />
        ) : (
          this.state.selectedUserLimits.totalUserLimit
        )
      ],
      devicesCount: [
        this.state.selectedUnitsLimits.totalChildDeviceCreated + '/',
        this.state.selectedUnitsLimits.totalUnitLimit === -1 ? (
          <AllInclusiveIcon style={{ verticalAlign: 'middle', fontSize: 14 }} />
        ) : (
          this.state.selectedUnitsLimits.totalUnitLimit
        )
      ]
    }
    return [row]
  }

  render () {
    let {selectedUserLimits , selectedUnitsLimits} = this.state
    return (
      <div>
        <CustomDialog
          title={this.props.translate('statisticsOf')}
          selectedUser = {this.props.selectItem.email}
          themecolors={this.props.themecolors}
          visable={true}
          onClose={this.props.onClose}
          bodyPadding={10}
          hideDragIcon
          isVisableBtn
          noFullScreen
          background={'#f7f8f9'}
          maxWidth={520}
        > 
          <div style={{ background:'white', padding:15, width:620}} >
            <p><strong>{this.props.translate('device')}</strong></p>
                <div >
                   <p style={{fontSize:11}}> {this.props.translate('childLimit')}</p>
                </div>
                <div className="StatBox2">
                      <span style={{background: '#00a185'}} ><i>{this.props.translate('childLimit')}{'  '}</i> <b >{!this.state.devicesLoad ?selectedUnitsLimits.reserveLimit: <>...</> } </b> </span>
                      <span style={{background: '#227fbb'}}> <i>{this.props.translate('childRemainigLimit')}{'  '}</i><b >  {!this.state.devicesLoad ?Math.abs(selectedUnitsLimits.reserveLimit - selectedUnitsLimits.childUnitCount) : <>...</> } </b> </span>
                  </div>

                {/* <div className="StatBox2">
                      <span style={{background: '#00a185'}} ><i>{this.props.translate('totalOwnCreatedUnits')}{'  '}</i> <b >{!this.state.devicesLoad ?selectedUnitsLimits.unitCount: <>...</> } </b> </span>
                      <span style={{background: '#227fbb'}}> <i>{this.props.translate('totalChildUnitsCreated')}{'  '}</i><b >  {!this.state.devicesLoad ?selectedUnitsLimits.childUnitCount: <>...</> } </b> </span>
                      <span style={{background: '#1fce6d'}}> <i>{this.props.translate('usersUnitsTotal')}{'  '}</i><b >  {!this.state.devicesLoad ?selectedUnitsLimits.totalCount: <>...</> }  </b> </span>
                  </div> */}
                  <div>
                   <p style={{fontSize:11}}>{this.props.translate('Created')}</p>
                </div>
                <div className="StatBox2" >
                      <span style={{background: '#00a185'}}><i>{this.props.translate('selfCreated')}{'  '}</i> <b>{!this.state.devicesLoad ?selectedUnitsLimits.unitCount: <>...</> } </b> </span>
                      <span style={{background: '#227fbb'}}> <i>{this.props.translate('childCreated')}{'  '}</i><b>{!this.state.devicesLoad ?selectedUnitsLimits.childUnitCount: <>...</> } </b> </span>
                      <span style={{background: '#1fce6d'}}> <i>{this.props.translate('totalCreated')}{'  '}</i><b >  {!this.state.devicesLoad ?selectedUnitsLimits.totalCount: <>...</> }  </b> </span>
                  
                </div>
                <div>
                    <p style={{fontSize:11}}>{this.props.translate('totalLimit')}</p>
                </div>
                  <div className="StatBox2">
                      <span style={{background: '#2b3e51'}}> <i>{this.props.translate('totalSelfLimit')}{'  '} </i><b>{!this.state.devicesLoad ?selectedUnitsLimits.limit: <>...</> } </b> </span>
                      <span style={{background: '#d55400'}}> <i> {this.props.translate('totalUnitLimit')}{'  '} </i><b>{!this.state.devicesLoad ?selectedUnitsLimits.reserveLimit: <>...</> }</b> </span>
                      <span style={{background: '#1fce6d'}}> <i> {this.props.translate('totalRemaining')}{'  '} </i><b>{!this.state.devicesLoad ?selectedUnitsLimits.remaningLimit: <>...</> }</b> </span>
                </div>
            </div>

          <div style={{background:'white', padding:15, marginTop:'20px'}}>
              <p><strong>{this.props.translate('users')}</strong></p>
              <div style={{marginTop:4}}>
                   <p style={{fontSize:11}}> {this.props.translate('childLimit')}</p>
                </div>
              <div className="StatBox2">
                  <span style={{background: '#00a185'}}><i>{this.props.translate('childLimit')}{'  '}</i> <b>{!this.state.usersLoad  ? selectedUserLimits.reserveLimit : <>...</>} </b> </span>
                  {/* <span style={{background: '#227fbb'}}> <i>{this.props.translate('grandChildCount')}{'  '}</i><b>{!this.state.usersLoad  ? selectedUserLimits.grandChildCount : <>...</> } </b> </span>
                  <span style={{background: '#227fbb'}}> <i>{this.props.translate('usersAccountTotal')}{'  '}</i><b>{!this.state.usersLoad  ? selectedUserLimits.totalCount : <>...</> } </b> </span> */}
              </div>
              <div>
                   <p style={{fontSize:11}}>{this.props.translate('Created')}</p>
                </div> 

                <div className="StatBox2">
                      <span style={{background: '#2b3e51'}}> <i>{this.props.translate('created')}{'  '}</i> <b > {!this.state.usersLoad ? selectedUserLimits.childCount : <>...</>} </b> </span>
                      <span style={{background: '#d55400'}}> <i>{this.props.translate('grandChildCount')}{'  '}</i> <b >  {!this.state.usersLoad ? selectedUserLimits.grandChildCount: <>...</>}</b> </span>
                      <span style={{background: '#1fce6d'}}> <i>{this.props.translate('totalCreated')}{'  '}</i> <b >  {!this.state.usersLoad ? selectedUserLimits.totalCount: <>...</>}</b> </span>
                  
                </div>
                <div>
                   <p style={{fontSize:11}}>{this.props.translate('totalLimit')}</p>
                </div>
                <div className="StatBox2">
                      <span style={{background: '#2b3e51'}}> <i>{this.props.translate('limit')}{'  '}</i> <b > {!this.state.usersLoad ? selectedUserLimits.limit : <>...</>} </b> </span>
                      <span style={{background: '#d55400'}}> <i>{this.props.translate('reserveLimit')}{'  '}</i> <b >  {!this.state.usersLoad ? selectedUserLimits.reserveLimit+selectedUserLimits.childCount: <>...</>}</b> </span>
                      <span style={{background: '#1fce6d'}}> <i>{this.props.translate('remaining')}{'  '}</i> <b >  {!this.state.usersLoad ? selectedUserLimits.remaningLimit: <>...</>}</b> </span>
                  
                </div>
            </div>

            
        </CustomDialog>
      </div>
    )
  }
}

viewStatistics.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapState = state => {
  return {
    logInUser: state.logInUsers,
    themecolors: state.themecolors
  }
}
const mapStateToProps = connect(mapState)
export const ViewStatistics = mapStateToProps(
  withTranslationWrapper(withStyles(styles)(viewStatistics))
)

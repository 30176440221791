import React, { Component } from 'react'
import { logout } from './../../Actions/Users'
import { setCookie, getCookie } from '../../Helpers'
import { connect } from 'react-redux'
import Loader from './../../Layout/Loader'
import withRoutes from '../../HOC/HocRoutes'
class logoutPage extends Component {
  constructor (props) {
    super(props)
    this.state = { loading: false }
  }

  componentWillMount () {
    this.setState({
      loading: true
    })
    if (getCookie('JSESSIONID')) {
      fetch('/api/session', {
        method: 'DELETE'
      }).then(res => {
        res.json().then(response=>{
          if (response.status === 'success') {
            this.props.navigate('/login')
            localStorage.setItem('privileges', '')
            localStorage.setItem('userInfo', '')
            localStorage.setItem('adminToken', '')
            this.props.dispatch(logout())
            setCookie('JSESSIONID', 0)
            this.setState({
              loading: false
            })
          } 
          else if(response.statusCode) {
            this.props.navigate('/login')
            localStorage.setItem('privileges', '')
            localStorage.setItem('userInfo', '')
            localStorage.setItem('adminToken', '')
            this.props.dispatch(logout())
            setCookie('JSESSIONID', 0)
            this.setState({
              loading: false
            })
            }
        })
        }).catch(e => {
          this.props.navigate('/login')
          localStorage.setItem('privileges', '')
          localStorage.setItem('userInfo', '')
          localStorage.setItem('adminToken', '')
          this.props.dispatch(logout())
          setCookie('JSESSIONID', 0)
          this.setState({
            loading: false
          })
        })
    } else {
      this.setState({
        loading: false
      })
      this.props.navigate('/login')
      localStorage.setItem('privileges', '')
      localStorage.setItem('userInfo', '')
      localStorage.setItem('adminToken', '')
    }
  }
  render () {
    if (this.state.loading) {
      return <Loader />
    } else {
      return null
    }
  }
}
const mapState = state => {
  return {}
}
const mapStateToProps = connect(mapState)

export const LogOut = mapStateToProps(withRoutes(logoutPage))

import React, { Component } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import withTranslationWrapper from '../../HOC/HocTranslate'
import Table from '../common/tableWithBackEndPagination'
import { Grid } from '@mui/material'
import TextField from '../common/TextField'
import Button from '../common/Button'
import Moment from 'moment'
import AddItem from './addItems'
import { notifyError, notifySuccess } from '../../Utils/CustomNotifcations'
import ConfirmDialoag from '../common/ConfirmDialoag'
import {
  checkPrivileges,
  PaginationConfig,
} from '../../Helpers'
import isEqual from 'react-fast-compare'
import Loader from '../../Layout/Loader'
import { Api } from '../../Helpers'
const formDefaultState = {
    id: '',
    client_name: '',
    client_url: '',
    forward_hook: '',
    hook: '',
    hook_postfix: '',
    hook_prefix: '',
    method: '',
    headers:{},
    io:{},
    value:'',
  }
const DataFormat = (data, account_id) => {
    return {
      id: data && data.id ? data.id : '',
      hook : data && data.hook ? data.hook : '',
      forward_hook: data&&data.forward_hook ? data.forward_hook: '',
      hook_prefix: data&&data.hook_prefix ? data.hook_prefix: '',
      hook_postfix: data&&data.hook_postfix ? data.hook_postfix: '',
      account_id: account_id&&account_id ? account_id: '',
      client_name: data&&data.client_name ? data.client_name: '',
      client_url: data&&data.client_url ? data.client_url: '',
      method: data&&data.method ? data.method: '',
      io: data&&data.io ? data.io: {},
      headers: data&&data.headers ? data.headers: {},
    } 
}
const DataFormatDelete = (account_id,id) => {
  return {
    account_id : account_id ? String(account_id) : '',
    id: id ? id : '',
  } 
}

class webHook extends Component {
    constructor (props) {
    super(props)
        this.state = {
          addItem: false,
          itemSearch:'',
          tableData:[],
          defaultItemsList:false,
          defaultEntityList: [],
          defaultEntityFields: [],
          onDeleteConfirmation: false,
          selectedHook:{},
          editOption:false,
          isVisableCreateBtn:false,
          isVisableEditBtn:false,
          selectedEntityType:'',
          error:false,
          loader:true,
          form: {
            ...formDefaultState
          },    
          scale:[{x:0, y:0}],
          headers:[{x:0, y:0}],
        }
        this.modalControle = this.modalControle.bind(this)
}
    componentWillMount () {
      this.getHooks()
      this.fetchEntityList()
    }
      getHooks =()=>{
           let id = this.props.logInUser.accountId
        if(id){
            axios
            .get(`${Api}getUserId?account_id=${id}`)
            .then(response => {})
            .catch(e => {
                console.log('e =', e)
            })

            axios.get(`${Api}getHooks?account_id=${id}`)
            .then(response =>{
                this.setState({
                    tableData:response.data,
                    defaultItemsList:true,
                    loader:false,
                    editOption:false,
                    addItem:false
                })
            })
            .catch(e => {
                console.log('e =', e)
            })
        }
        else {
            console.log(' no props received =')
        }
    
      }

      addItems = ()=> {
            this.setState({
                addItem: true,
                selectedHook:null,
                defaultItemsList:false,
                loader:false,
            }, ()=>{this.fetchEntityList()});
        }
        SearchItem = (e) =>{
            this.setState({itemSearch: e });
        }

        modalControle () {
            this.setState({
                addItem: false,
                editOption:false,
                defaultItemsList:true,
                loader:false,
                selectedHook:[],
                isVisableEditBtn:false,
                isVisableCreateBtn:false,
                form: {...formDefaultState},
                scale:[{x:0, y:0}],
                headers:[{x:0, y:0}]
            })
        }

        handleChange = name => event => { 
            var urlRegex = '^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_\+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?'
            const { target } = event
           if(name === 'client_url'){
                if (event.target.value.match(urlRegex)) {this.setState({ error: false })}
                else {this.setState({ error:true })}
                this.setState({ 
                  form: {
                  ...this.state.form,
                  [name]: event.target.value
                  }},
                  () => this.checkRequiredFields()
                )
                
              }
              else {
                this.setState({
                        form: {
                        ...this.state.form,
                        [name]: event.target.value
                        } 
                    }, ()=>this.checkRequiredFields())
              }
              
          }

        method_handleChange = (name, value) => {

              if(name ==='method'){
               this.setState({
                        form: {
                        ...this.state.form,
                        [name]: value.value
                        } 
                    })
              }
              else if(name === 'selectedEntity'){
                this.setState({
                  selectedEntityType: value.label
                });
                    if (name === 'selectedEntity') {
                        this.fetchEntityFields(value&&value.id)
                    }
              }
              else {return null}
          }
          
          
        handleChart2 = (name, value, index, type) => {
          let check = 'y'
          let rows = []
          if(type === 'scale'){
          rows = [...this.state.scale]
          }
          else {
            rows = [...this.state.headers]
          }
          let keys = []
          if(type === 'scale'){
            if(rows[index][check] === 0 ){
              rows[index][check] = value
            }
          }
          rows[index][name] = value
          rows.map(i => {
            keys.push(i.x)
            return null
          })
          let sorted_arr = keys.slice().sort()
          let results = []
          for (let i = 0; i < sorted_arr.length - 1; i++) {
            if (sorted_arr[i + 1] === sorted_arr[i]) {
              results.push(sorted_arr[i])
            }
          }
          let a = []
          keys.map((item, index) => {
            results.map(w => {
              if (item === w) {
                a.push(index)
              }
              return null
            })
            return null
          })
          a.map(val => {
            rows[val] = { ...rows[val] }
            return null
          })

          if(type === 'scale'){
          this.setState({ scale:rows, error: a.length > 0 ? true : false })
            this.setObject(this.state.scale, 'scale')
          }
          else {
          this.setState({ headers: rows, error: a.length > 0 ? true : false })
            this.setObject(this.state.headers, 'headers')
          }
        }

          deleteRow = (row, index, type) => {
            if(type === 'scale'){
              let rows = [...this.state.scale]
              rows.splice(index, 1)
              this.setState({ scale:rows }, () => {
              this.state.scale.map((row, index) => {
                this.handleChart2('x', row['x'], index, 'scale')
                this.handleChart2('y', row['y'], index, 'scale')
                return null
              })
            })
            }
            else{
              let rows = [...this.state.headers]
              rows.splice(index, 1)
              this.setState({ headers:rows }, () => {
              this.state.headers.map((row, index) => {
                this.handleChart2('x', row['x'], index, 'headers')
                this.handleChart2('y', row['y'], index, 'headers')
                return null
              })
            })
            }
          }

          addMore = (type) => {
            let rows = []
            if (type === 'scale'){
            rows = [...this.state.scale]
            }
            else {
              rows =[...this.state.headers]
            }
            if (rows.length) {
              let last = { ...rows[rows.length - 1] }
              rows.push({ x: 0, y: 0 })
            } else {
              let last = { ...rows[rows.length - 1] }
              rows.push(last)
            }
            if (type === 'scale'){
            this.setState({ scale:rows })
            }
            else {
              this.setState({ headers:rows })
            } 
          }


        ag_submit = () => {
              const obj = DataFormat(this.state.form, this.props.logInUser&&this.props.logInUser.accountId)
               axios.post(`${Api}addHook`,{...obj})
              .then(response => {
                  if(response.data.status){
                      this.props.dispatch(
                      notifySuccess('Hook Created')
                     )
                     this.getHooks()
                  
                  }
                })
              .catch(e => {console.log('e =', e)})
          }
          
          ug_submit = () => {
              const obj = DataFormat(this.state.form, this.props.logInUser&&this.props.logInUser.accountId)
              axios.put(`${Api}updateHook`,{...obj })
              .then(response => {
                if(response.data.status){
                  this.props.dispatch(
                    notifySuccess('Hook Updated')
                    )
                    this.getHooks()
                  }
                })
                .catch(e => {console.log('e =', e)})
                this.modalControle()
            
          }

          deleteHook = () => {
            if(this.state && this.state.selectedHook){
           
            let obj = DataFormatDelete(this.props.logInUser.accountId, this.state.selectedHook[0].id)
            // console.log('obj =', obj)

                axios.delete(`${Api}deleteHook`,{data:obj})  
                .then(response => {
                  // console.log('reponse =', response)
                  if(response.data.status === 200){
                    this.props.dispatch(
                      notifySuccess('Hook deleted')
                     )
                  }
                       this.getHooks()
                  })
                .catch(e => {console.log('e =', e)})

  
                  this.setState({
                    onDeleteConfirmation: false,
                  })
              }
              else{
                console.log('no selected =')
              }
            }

          checkRequiredFields () { 
            let changed = false
            if(this.state.editOption){ 
              changed = !isEqual(this.state.form, this.state.selectedHook)
            }
            let { hook, client_url, method } = this.state.form
              if(hook && client_url && method){
                if(!this.state.error && this.state.form.client_url){
                  if (changed) {
                    this.setState({
                      isVisableEditBtn: true,
                    })
                  } 
                  else{
                    this.setState({
                      isVisableCreateBtn:true,
                      isVisableEditBtn:false
                    })
                  }
                }
              }
              else {
                this.setState({
                  isVisableCreateBtn:false,
                  isVisableEditBtn:false
                })
              }             
         }

          fetchEntityList = id => {
            fetch(`${Api}getEntityList`, {
              method: 'GET',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
              }
            }).then(response => {
              if (response.ok) {
                response.json().then(userlist => {
                    let result =
                      userlist.data &&
                      userlist.data.map(item => ({
                        ...item,
                        label: item.entity_type || item.entity_type,
                        value: item.id
                      }))
                    this.setState({
                      defaultEntityList: result
                    })
                  })
              } else {
                this.props.dispatch(
                  notifyError('somthingWentWrongMessage2')
                )
                  throw response
                }
              }).catch(e => {})
          }
          
          fetchEntityFields = id => {
              if(id){
                fetch(`${Api}getEntityFields?entity_type_id=${id}`, {
                    method: 'GET',
                    headers: {
                      Accept: 'application/json',
                      'Content-Type': 'application/json'
                    }
                  }).then(response => {
                    if (response.ok) {
                      response.json().then(userlist => {
                          let result =
                            userlist &&
                            userlist.map(item => ({
                              ...item,
                              label: item.label || '',
                              value: item.value
                            }))
                          this.setState({
                              defaultEntityFields: result
                          })
                        })
                    } else {
                      this.props.dispatch(
                        notifyError('somthingWentWrongMessage2')
                      )
                        throw response
                      }
                    }).catch(e => {})
              }
            }

          intialObject = (form) =>{
            let io = JSON.parse(form.io)
            let headers = JSON.parse(form.headers)
            let resIO = this.objectFlip(io)
            let resIO2 = this.objectFlip(io)
            
            return {...form, io:resIO2, headers}
          }

           objectFlip=(obj)=> {
            return Object.entries(obj).reduce((ret, entry) => {
              const [ key, value ] = entry;
              ret[ value ] = key.trim();
              return ret;
            }, {});
          }

          setObject = (val, name) =>{
            let rows = {}
            if (val && val.length) {
                val.map(item => {
                      let key = item.x,
                          value = item.y
                          if(key && value){
                            rows = { ...rows, [key]: value }
                          }
                    })
                  }
            if(name === 'headers'){
              let form = {...this.state.form, headers:rows}
              this.setState({form},()=>{this.checkRequiredFields()})
            }
            if(name === 'scale'){
              let form = {...this.state.form, io:rows}
              this.setState({form},()=>{this.checkRequiredFields()})
            }
          }

      removeHook  = (selected) =>  {
          this.setState({
                onDeleteConfirmation: true,
                selectedHook: selected,
              })
        }

      onCancel = () => {
          this.setState({
            onDeleteConfirmation: false,
          })
      }

      hookSelection = (item) => {
           this.setState({
            selectedHook: this.intialObject(item),
            editOption:true,
            defaultItemsList:false,
            form:this.intialObject(item)
           }, ()=>{this.fetchEntityList()});
      }

      handleChangeRowsPerPage = value => {
        this.setState(
          {
            //tableData: [],
            pageSize: value
            //defaultUserList: false
          },
          () => this.getHooks()
        )
      }

      handleChangePage = value => {
        this.setState(
          {
            //tableData: [],
            page: value
            //defaultUserList: false
          },
          () => this.getHooks()
        )
      }

      setHeaders = (data) =>{
        this.setState({
          headers:data
        })
      }

      setScale = (data) =>{
        this.setState({
          scale:data
        })
      }
    render() {
        return (
    <div>
      {this.state.defaultItemsList ? (<>     
        {(<>
          <Grid container className="breadcrumb-row">
              <Grid item xs={12} sm={3}>
                <h3 style={{margin: 0}}>{this.props.translate('webHooks')}</h3>
              </Grid>
               <Grid item xs={12} sm={9} style={{justifyContent: 'flex-end'}}>
                <div style={{width: "25%", marginRight: 20}}>
                  <TextField
                    fullWidth
                    label={this.props.translate('search')}
                    value={this.state.itemSearch}
                    onChange={e => this.SearchItem(e.target.value)}
                    />
                </div>
              <Button
                  disabled={false}
                  aria-label='Create'
                  size='small'
                  color='inherit'
                  onClick={this.addItems}
                  id='createButton'
                  >{this.props.translate('create')}</Button>
              </Grid>
            </Grid>
            </>)} 

                <Table
                    rows={this.state.tableData || []}
                    rowDefinition={rowDefinitionR(this.props.translate)}
                    onDelete={this.removeHook}
                    isCursoPointer
                    onClick={this.hookSelection}
                    hasAccessOfDelete= {true}
                    handleChangePage={this.handleChangePage}
                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                    isEditable
                  /></>
                    ) : ( this.state.loader ? 
                       <Loader /> : ''
                    )}

            {this.state.addItem && (<>
                <AddItem 
                    {...this.state}
                    {...this.props}
                    formSubmit={this.ag_submit}
                    changeAttribute={this.changeAttribute}
                    handleChange={this.handleChange.bind(this)}
                    form={this.state.form}
                    modalControle={this.modalControle}
                    buttonText={this.props.translate('sharedCreate')}
                    isVisableBtn={this.state.isVisableCreateBtn}
                    itemAttributes={this.state.itemAttributes}
                    method_handleChange={this.method_handleChange}
                    setObject={this.setObject}
                    addMore={this.addMore}
                    handleChart2={this.handleChart2}
                    deleteRow={this.deleteRow}
                    headers={this.state.headers}
                    scale={this.state.scale}
                    setScale={this.setScale}
                    setHeader={this.setHeaders}
                    add
                />
            </>)}    
               {this.state.editOption && (<>
                <AddItem 
                    {...this.state}
                    {...this.props}
                    formSubmit={this.ug_submit}
                    changeAttribute={this.changeAttribute}
                    handleChange={this.handleChange.bind(this)}
                    modalControle={this.modalControle}
                    buttonText={this.props.translate('update')}
                    isVisableBtn={this.state.isVisableEditBtn}
                    itemAttributes={this.state.itemAttributes}
                    method_handleChange={this.method_handleChange}
                    setObject={this.setObject}
                    addMore={this.addMore}
                    handleChart2={this.handleChart2}
                    deleteRow={this.deleteRow}
                    headers={this.state.headers}
                    scale={this.state.scale}
                    setScale={this.setScale}
                    setHeader={this.setHeaders}
                    edit
                />
            </>)}

              {this.state.onDeleteConfirmation && (
                <ConfirmDialoag
                  onCancel={this.onCancel}
                  onOk={this.deleteHook}
                  title={this.props.translate('areYouWantToDelete')}
                  children={this.state.selectedHook.client_url}
                />
              )}
        </div>
      )
    }
}
const mapState = state => {
    return {
      logInUser: state.logInUsers,
    }
  }
  const mapStateToProps = connect(mapState)
  export const WebHooks = mapStateToProps(withTranslationWrapper(webHook))

  const rowDefinitionR = translate => {
    if (localStorage.getItem('adminToken')) {
      return [
        {
          id: 'client_name',
          numeric: false,
          disablePadding: false,
          label: translate('title')
        },
        {
          id: 'client_url',
          numeric: false,
          disablePadding: false,
          label: translate('URL')
        },
        {
          id: 'forward_hook',
          numeric: false,
          disablePadding: false,
          label: translate('forwardhook')
        },
        {
          id: 'hook',
          numeric: false,
          disablePadding: false,
          label: translate('hook')
        },
        {
          id: 'hook_postfix',
          numeric: false,
          disablePadding: false,
          label: translate('hook_postfix')
        },
        {
          id: 'hook_prefix',
          numeric: false,
          disablePadding: false,
          label: translate('hook_prefix')
        },
        {
          id: 'method',
          numeric: false,
          disablePadding: false,
          label: translate('method')
        }
      ]
    } else {
      return [
        {
            id: 'client_name',
            numeric: false,
            disablePadding: false,
            label: translate('title')
          },
          {
            id: 'client_url',
            numeric: false,
            disablePadding: false,
            label: translate('URL')
          },
          {
            id: 'forward_hook',
            numeric: false,
            disablePadding: false,
            label: translate('forwardhook')
          },
          {
            id: 'hook',
            numeric: false,
            disablePadding: false,
            label: translate('hook')
          },
          {
            id: 'hook_postfix',
            numeric: false,
            disablePadding: false,
            label: translate('hook_postfix')
          },
          {
            id: 'hook_prefix',
            numeric: false,
            disablePadding: false,
            label: translate('hook_prefix')
          },
          {
            id: 'method',
            numeric: false,
            disablePadding: false,
            label: translate('method')
          }
      ]
    }
  }